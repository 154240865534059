<template>
  <div :class="{'gray-out': isUploading}">
    <div class="share-content" :class="{chooser: chooser}">
      <img alt="EFuneral" src="../../assets/logo.png">
      <br/>
      <h3></h3>
    
      <h5>Video Preview</h5>
      <template>
        <video v-if="itemToPlay.previewDataLink" style="height: 480px; width: 720px;" ref="videoPlayer" :src="S3_URL + itemToPlay.previewDataLink" controls></video>
        <br />
        <br />
        <a v-if="itemToPlay.userContentDataLink" target="_blank" :href="S3_URL + itemToPlay.userContentDataLink" class="btn btn-default">Open Completed Video</a>
      </template>
      <!-- <video v-if="itemToPlay.userContentCreationTypeId !== 3" style="height: 270px; width: 480px;" ref="videoPlayer" :src="S3_URL + itemToPlay.userContentDataLink" controls></video> -->
      <!-- <br />
      <br />
      <a v-if="itemToPlay.userContentDataLink" :download="itemToPlay.name + 'mp4'" :href="S3_URL + itemToPlay.userContentDataLink" class="btn btn-default">Download Completed Video</a> -->
    </div>
  </div>
</template>
<script>
import api from '@/api'
import config from '@/config'
export default {
  data () {
    return {
      itemToPlay: {},
    }
  },
  components: {
    // PhotoEditor //,
    // VideoCreator
  },
  computed: {
    API_URL () {
      return config.API_URL
    },
    S3_URL () {
      return config.S3_URL
    },
  },
  mounted () {
    api.video.getForUserPublic(this.$route.params.guid, this.$route.params.id).then(result => {
      this.itemToPlay = result.data
    })
  }
}
</script>
<style lang="css" scoped>

.content-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 2px;
  border: 2px solid transparent;
  margin: 5px;
  cursor: pointer;
}
.content-item.video .thumb-icon,
.content-item.audio .thumb-icon{
  border: 1px solid lightgray;
  width: 210px;
  height: 147px;
  padding: 2px;
  text-align: center;
  background-color: #fff;
}
.content-item.video .thumb-icon .video-icon,
.content-item.audio .thumb-icon .audio-icon {
  margin-top: 34px;
}
.content-item.selected {
  border: 2px solid #007bff;
  border-radius: 3px;
}
.content-item:hover .command-icon{
  display: block;
}

.delete-icon {
   top: 44px;
}
.download-icon {
  top: 78px;
}
.play-icon {
  top: 112px;
}
.play-video-modal {
  text-align: center;
}
.file-name {
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  height: 35px;
  white-space: nowrap;
  font-size: 10px;
  margin-top: -20px;
  margin-left: 5px;
}
.upload-button {
  border-radius: 50%;
  /* color: #ffffff; */
  font-size: 34px;
  /* background-color: #007bff; */
  width: 64px;
  height: 64px;
  padding-top: 9px;
}
.uploader-example {
  display: inline-block;
  height: 100px;
  width: 100px;
  padding: 10px;
  margin-top: 28px;
  margin-left: 32px;
}
.gray-out {
  opacity: .5;
}
.video-badge {
  position: absolute;
  left: 12px;
  top: 12px;
}
.video-progress-spinner {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}
.share-content {
  text-align: center;
}
.play-video-modal >>>.sweet-modal {
  max-width: 790px;
}
</style>