<template>
    <div>
        <h2>{{paymentPlan.name}}</h2>
        <hr>                                    
        <form>
            <div class="form-row mb-3">
               <div class="col-md-4 mb-3">
                    <label for="price">Price (€):</label>
                    <input disabled="true" type="text" class="form-control" id="price" placeholder="Enter Conpany Name" :value="paymentPlan.price" >
                </div>
                <!-- <div class="col-md-4 mb-3">
                    <label for="location">Single Funeral Price (€):</label>
                    <input disabled="true" type="text" class="form-control" id="location" placeholder="Enter Conpany Name" :value="paymentPlan.priceOfSingleEvent">
                </div> -->
                <div class="col-md-4 mb-3">
                    <label for="location">Number Of Funerals:</label>
                    <input disabled="true" type="text" class="form-control" id="location" placeholder="Number of Funerals" :value="paymentPlan.typeOfPlan == 4 ? '∞' : paymentPlan.numberOfEvents">
                </div>
                <div class="col-md-4 mb-3">
                    <label for="location">Videos Per Funeral:</label>
                    <input disabled="true" type="text" class="form-control" id="location" placeholder="Videos Per Funeral" :value="paymentPlan.videosPerEvent">
                </div>
            </div>
            <div class="form-row mb-3">
                <div class="col-md-4 mb-3">
                    <label for="type">Type Of Plan: </label>
                    <input disabled="true" type="text" class="form-control" id="type" placeholder="Type of Plan" :value="typeOfPlan">
                </div>
                <div class="col-md-4 mb-3">
                    <label for="trial">Trial Period: </label>
                    <input disabled="true" type="text" class="form-control" id="trial" placeholder="Trial Period" :value="trialPeriod">
                </div>
                <div class="col-md-4 mb-3">
                    <label for="music">Music Library: </label>
                    <input disabled="true" type="text" class="form-control" id="music" placeholder="Enter Conpany Name" :value="externalMusic">
                </div>
             </div>
        </form>
    </div>
</template>

<script>
export default {
    computed:{
        paymentPlan(){
            return this.$store.getters.getSinglePaymentPlan
        },
        typeOfPlan(){
           switch(this.paymentPlan.typeOfPlan){
               case 1: 
               return "Monthly"
               case 2: 
               return "Yearly"
               case 3:
               return "One Time"            
               default:
               return "Undefined"
           }
        },
        externalMusic(){
            return this.paymentPlan.withAddOn? "Yes": "No"
        },
        trialPeriod(){
            if(this.paymentPlan.trialPeriodProvided){
                return this.paymentPlan.numberOfTialPeriodDays+" Days";
            }
            return "None"
        },
    }
}
</script>

<style>
.infoModal h4{
    border: 1px solid gray;
}
</style>