<template>
  <div>
    <MainNav></MainNav>
    <div class="wrapper">
        <button v-if="getSelectedFolderId === -1" :disabled="isUploading" @click="openAddFolder" class="btn btn-light main-button" type="button" ><i class="fa fa-folder"></i> {{'Create folder' | translate}}</button>
        <i v-if="getSelectedFolderId === -1" class="fa fa-chevron-right arrow"></i>
       <uploader
       :class="{'invisible': deadlineExpired}"
       ref="uploader" :disabled="isUploading"
       :options="getOptions"
       :key="getOptions.target"
       @file-added="onFileAdded"
       @upload-start="onUploadStart"
       @file-error="onFileError"
       @complete="onComplete"
       class="uploader-example">
        <uploader-unsupport></uploader-unsupport>
        <uploader-btn :class="{disabled: isUploading}" :disabled="isUploading" class="btn btn-light upload-button">
          <i class="fa fa-upload"></i>
          Upload Files
        </uploader-btn> 
      </uploader>
      <span v-if="[1].indexOf(user.userTypeId) === -1 && !deadlineExpired" class="upload-command-buttons">
        <i class="fa fa-chevron-right arrow"></i>
        <button :disabled="isUploading" class="btn btn-light main-button" type="button" @click="openCoverPhoto()"><i class="fa fa-image"></i> {{'Portrait Photo' | translate}}</button>
        <!-- <input type="file" multiple />
          <button class="btn btn-light main-button" type="button"><i class="fa fa-upload"></i> {{'Upload' | translate}}</button> -->
        
          
        <i class="fa fa-chevron-right arrow"></i>
        <button :disabled="isUploading" class="btn btn-light main-button" type="button" @click="openVideoCreator()"><i class="fa fa-image"></i> {{'Create Slideshow' | translate}}</button>
        <!-- <div :disabled="isUploading" class="dropdown create-button">
          <button :disabled="isUploading" class="btn btn-light main-button dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-plus"></i> {{'Create Slideshow' | translate}}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" @click.prevent="openVideoCreator()" href="#">{{'Video' | translate}}</a>
            <a class="dropdown-item" href="#">{{'Tree of Life' | translate}}</a>
          </div>
        </div> -->
      </span>
      <div class="spinner-border" v-if="isUploading" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="filter-buttons-container">
        <ContentFilter></ContentFilter>
      </div>
      <div class="command-buttons-container">
        <button title="Select All" @click="selectAll()" class="btn btn-light" type="button"><i class="fa fa-check"></i></button>
        <button title="Select None" @click="deselctAll()" class="btn btn-light" type="button"><i class="fa fa-ban"></i></button>
        <button v-if="[1].indexOf(user.userTypeId) === -1 && !deadlineExpired" :disabled="!selctedContent.length" @click="deleteSelected()" class="btn btn-light" type="button"><i class="fa fa-trash"></i> Delete</button>
        <button v-if="!selctedContent.length" :disabled="!selctedContent.length" class="btn btn-light" type="button"><i class="fa fa-download"></i> Download</button>
        <a v-if="selctedContent.length" :href="API_URL + 'api/download-selected-user-content/' + selectedIdsString" class="btn btn-light"><i class="fa fa-download"></i> Download</a>
      </div>
      <div class="content-list-content">
        <ContentList :open-video-creator="openVideoCreator"></ContentList>
      </div>
    </div>
    <VideoCreator ref="videoCreator"></VideoCreator>
    <sweet-modal width="100%"  ref="coverPhotoModal" title="Portrait Photo">
      <CoverPhoto ref="coverPhotoInstance" :modal-ref="$refs.coverPhotoModal" :item="eventInfo" :close="closeCoverPhoto"></CoverPhoto>
    </sweet-modal>
    <sweet-modal ref="confirmDelete" icon="warning">
      You are about to delete {{noOfItemsToDelete}} file(s). Are you sure?
      <div>
        <button class="btn btn-light modal-button" @click="$refs.confirmDelete.close()" type="button">Cancel</button>
        <button class="btn btn-danger modal-button" @click="confirmDelete()" type="button">Delete</button>
      </div>
    </sweet-modal>
    <sweet-modal ref="deleteError" icon="info">
      Content can't be deleted since it is a part of a video.
      <div>
        <button class="btn btn-light modal-button" @click="$refs.deleteError.close()" type="button">Ok</button>
      </div>
    </sweet-modal>
    <sweet-modal ref="uploadError" icon="error">
      The file "{{errorFileName}}" seems to be corrupted and can't be uploaded. Please check the file format and try again.
      <div>
        <button class="btn btn-light modal-button" @click="$refs.uploadError.close()" type="button">Ok</button>
      </div>
    </sweet-modal>
    <sweet-modal ref="uploadErrorModal" icon="error">
      <div>
        <h2 class="modal-title">Upload Canceled</h2>
      </div>
      <div class="modal-body">
        <h3>{{errorUploadMessage}}</h3>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-lg" data-dismiss="modal" @click="closeUploadErrorModal">OK</button>
      </div>      
    </sweet-modal>
    <sweet-modal :blocking="true" title="Create new folder" ref="AddFolderModal" v-on:close="onAddFolderDialogClosed">
      <AddFolderForm @closeAddFolderForm="closeAddFolderForm" @addFolder="addFolder"/>
    </sweet-modal>
    <video ref="videoContentList" id="videoHidden" style="visibility: hidden; width:20px;height:20px"/>

  </div>
</template>

<script>
import moment from 'moment'
import api from '@/api'
import config from "@/config";
import util from '@/util'
import MainNav from '@/components/common/MainNav'
import ContentList from '@/components/common/ContentList'
import ContentFilter from '@/components/common/ContentFilter'
import VideoCreator from '@/components/video/VideoCreator'
import CoverPhoto from '@/components/common/CoverPhoto'
import AddFolderForm from '@/components/folder/AddFolderForm'
export default {
  props: {
    chooser: Boolean
  },
  components: {
    MainNav,
    // PhotoEditor,
    VideoCreator,
    ContentList,
    ContentFilter,
    CoverPhoto,
    AddFolderForm
  },
   data() {
    return {
      errorUploadMessage: "",
      uploadFlag:false,
      noOfItemsToDelete: 0,
      coverPhoto: {
        title: '',
        nameOfTheDeceased: '',
        bornDied: '',
        coverPhotoContentId: -1
      },
      attrs: {
        accept: '.jpg,.png,.jpeg,.jpe,.mp4,.gif,.mov,.mp3'
      },
      uploading: false,
      errorFileName: ''
    }
  },
  computed: {
    getOptions() {
       return {
        target: `${config.API_URL}api/add-user-content${util.getUserContextUrlParam()}${this.$store.getters.getOnlySelectedFolderId}`,
        testChunks: false,
        chunkSize: 1000000000,
        permanentErrors : [400, 404, 415, 500, 501],
        allowDuplicateUploads: true,
        headers: {
          Authorization: `Bearer ${util.getSessionData().access_token}`
        },
        successStatuses: [200,201, 202, 204]
      }
    },
    user () {
      return util.getSessionInfo()
    },
    deadlineExpired () {
      return [1,2].indexOf(this.user.userTypeId) !== -1 && this.user.eventInfo && this.user.eventInfo.eventDate && moment(this.user.eventInfo.eventDate) < moment(new Date())
    },
    selectedIdsString () {
      return this.$store.getters.selectedUserContent.filter(item => {
        return item.userContentDataLink
      }).map(item => {
        return item.userContentId
      }).join('-')
    },
    API_URL () {
      return config.API_URL
    },
    isUploading () {
      return this.uploading
    },
    eventInfo () {
      return this.$store.getters.eventInfo && this.$store.getters.eventInfo.eventId ? this.$store.getters.eventInfo : util.getSessionInfo().eventInfo
    },
    selctedContent () {
      return this.$store.getters.selectedUserContent
    },
    getSelectedFolderId () {
      return this.$store.getters.getSelectedFolderId
    }
  },
  async mounted () {
    await this.$store.dispatch('getUserEvent', util.getUserContext())
    await this.$store.commit('resetSelectedFolderId')
    await this.$store.dispatch('getFolderList', this.$store.getters.eventInfo.eventId)
  },
  created () {
    this.$store.dispatch('getUserContentList')
  },
  methods: {
    onFileAdded(file) {
      var url = URL.createObjectURL(file.file);
      document.getElementById('videoHidden').setAttribute('src',url);
      var myVideo = document.getElementById("videoHidden");
      myVideo.load();
    },
    onUploadStart() {
      if(this.uploadFlag===false){
        this.$refs.uploader.uploader.pause();
      }
      this.uploading=true;
      var myVideo = document.getElementById("videoHidden");
      myVideo.load();

      myVideo.onloadedmetadata=()=>{
        if(myVideo.duration>=1200){
          this.$refs.uploader.uploader.cancel();
          this.uploading=false;
          this.uploadFlag=false;
          this.errorUploadMessage='This video is too long, please upload smaller video!';
          this.openUploadErrorModal();
          return
        }
      }
      if(this.uploadFlag===false){
        this.uploadFlag=true;
        this.$refs.uploader.uploader.resume()
      }
    },
    onFileError(_rootFile, _file, message) {
      this.uploading = false
      this.errorFileName = _rootFile && _file.name
      this.$refs.uploadError.open()

      let messageObj = JSON.parse(message);
      this.errorUploadMessage=messageObj.Message;
      this.$refs.uploader.uploader.cancel();
      this.uploadFlag=false;
      this.openUploadErrorModal();
    },
     onComplete() {
      this.uploadFlag=false;
      this.uploading = false
      this.$store.dispatch('getUserContentList')
      this.$store.commit('selectNoneUserContentItems')
    },
    openUploadErrorModal(){
      this.$refs.uploadErrorModal.open();
    },
    closeUploadErrorModal(){
      this.errorUploadMessage='';
      this.$refs.uploadErrorModal.close();
    },
    selectAll () {
      this.$store.commit('selectAllUserContentItemsNoFilter', this.$store.getters.getSelectedFolderId)
    },
    deselctAll () {
      this.$store.commit('selectNoneUserContentItems')
    },
    confirmDelete () {
      this.$refs.confirmDelete.close()
      let ids = this.$store.getters.selectedUserContent.map(item => {
        return item.userContentId
      })
      api.userContent.deleteSelectedUserContent(ids).then((result) => {
        // eslint-disable-next-line
        if(result.data) {
          this.errorMessage = result.data
          this.$refs.deleteError.open()
        }
        this.$store.commit('selectNoneUserContentItems')
        this.$store.dispatch('getUserContentList')
      })
    },
    deleteSelected () {
      this.noOfItemsToDelete = this.$store.getters.selectedUserContent.length
      if (!this.noOfItemsToDelete) {
        return
      } else {
        this.$refs.confirmDelete.open()
      }
    },
    openVideoCreator (item) {
      // this.$store.commit('resetSelectedVideo')
      // this.$refs.videoCreator.resetWizard()
      this.$store.commit('selectNoneUserContentItems')
      this.$refs.videoCreator.open(item)
    },
    openCoverPhoto () {
      this.$refs.coverPhotoInstance.updateInfo(this.eventInfo)
      this.$refs.coverPhotoModal.open()
      this.$refs.coverPhotoInstance.change()
    },
    closeCoverPhoto () {
      this.$refs.coverPhotoModal.close()
    },
    openAddFolder(){
        this.$refs.AddFolderModal.open();
    },
    closeAddFolderForm(){
       this.$refs.AddFolderModal.close();
    },
    addFolder(formData){
        this.isLoading=true;
        formData.eventId = this.$store.getters.eventInfo.eventId
        api.folder.postFolder(formData).then(()=>{
        this.isLoading=false;
        this.$refs.AddFolderModal.close()

    })
    },
    onAddFolderDialogClosed()
    {
      this.$store.dispatch('getFolderList', this.$store.getters.eventInfo.eventId)
    }
  }
  /*,
  methods: {
    login() {
        api.user.login({ 
          username: this.username,
          password: this.password
        })
        .then(res => {
          // eslint-disable-next-line
          sessionStorage.username = this.username
          // eslint-disable-next-line
          sessionStorage.timezone = res.data.gmt
          // eslint-disable-next-line
          sessionStorage.portfolioSize = res.data.portfolio_size
          // eslint-disable-next-line
          console.log(res.data.access_token);
          if (res.status === 200) {
            Cookie.set(config.TOKEN_KEY, res.data.access_token);
            this.$router.push("/");
          }
        })
        .catch(error => {
          this.errors = error.response.data;
          // eslint-disable-next-line
          console.log("test");
          // eslint-disable-next-line
          console.log(error.response.data.username);
        });
      return false;
    }
  }*/
};
</script>

<style scoped>
.wrapper {
  /*width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
  /* padding: 25px;
  padding-top: 32px; */
  margin: 12px;
  /* border: 1px solid lightgray;
  border-radius: 3px; */
  background: transparent;
}
.nav-tabs {
  justify-content: center;
}

.error-height {
  height: 16px;
}
.form-cover-photo {
  margin: auto;
  width: 360px;
  background-color: #fff;
}
.create-button{
  display: inline-block;
}
.upload-button {
  display: inline-block;
  font-weight: 400;
  /* color: #212529; */
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* background-color: transparent; */
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #212529;
  background-color: #f8f9fa;
  border-color: #CCC;
  width: 160px;
  margin-right: 12px;
  margin-bottom: 12px;
}
#hidden{
  display: none;
}
.uploader-example {
  display: inline-block;
}
.filter-buttons-container {
  float: right;
}
.command-buttons-container {
  float: right;
  margin-right: 12px;
}
.command-buttons-container button, 
.command-buttons-container a{
  margin-right: 24px;
}
.thumb-icon {
  margin: 5px;
  border: 1px solid #bbb;
  height: 160px;
  width: 220px;
  text-align: center;
  padding-top: 38px;
}
.video-icon {
  vertical-align: middle;
  text-align: center;
}
.arrow {
  margin-right: 10px;
}
.content-list-content {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 140px);
}
.invisible {
  visibility: hidden;
}
</style>
