<template>
  <div>
    <sweet-modal :blocking="true" class="wizard-paymentplan-window" ref="modal" width="100%" hide-close-button>
      <form-wizard ref="wizard" class="wizard" color="#007bff" title="" subtitle="">
            <tab-content title="Payment Cards" icon="fa fa-credit-card">
              <PaymentMethodList />
            </tab-content>
            <tab-content title="Payment Plans" icon="fa fa-cc-stripe">
              <PaymentPlanDetailsAndActivate/>
            </tab-content>
      <template slot="footer" slot-scope="props">
       <div class="wizard-footer-left">
           <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</wizard-button>
        </div>
        <div class="wizard-footer-right">
          <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle">Next</wizard-button>
          <wizard-button v-if="props.isLastStep && activateAndSubscribe" @click.native="subscribe()" class="wizard-footer-right finish-button" :style="props.fillButtonStyle">  {{props.isLastStep && activateAndSubscribe ? 'Activate plan and subscribe' : 'Next'}}</wizard-button>
          <wizard-button v-if="props.isLastStep && activateOneTime" @click.native="payOneTime()" class="wizard-footer-right finish-button" :style="props.fillButtonStyle">  {{props.isLastStep && activateOneTime ? 'Activate one time' : 'Next'}}</wizard-button>
        </div>
      </template>      
      </form-wizard>
    </sweet-modal>
  </div>
</template>

<script>
import api from '@/api'
import util from '@/util'
import PaymentMethodList from "@/components/paymentMethods/PaymentMethodsList"
import PaymentPlanDetailsAndActivate from '@/components/paymentPlan/PaymentPlanDetailsAndActivate'
export default {
  data() {
    return {
      customer : {},
      filter: {
        page: 1,
        pageSize: 1000,
        isHidden: false,
        isDeleted: false,
      }
    }
  },
  methods: {
    subscribe(){
      sessionStorage.getItem('subscribe', 'paymentErrorPage')
      return util.subscribe();
      },
    payOneTime() {
      api.customerPaymentPlan.activateOneTime().then(()=>{
        this.getCurrentCustomer()
        this.$router.push({ name: 'Events' })
      })
    },
    resetWizard () {
      this.$refs.wizard.reset()
    },
    open () {
      this.resetWizard()
      this.$refs.modal.open()
    },
     getCurrentCustomer(){
      this.$store.dispatch('getAllPaymentPlans',this.filter)
      api.customers.getCurrentCustomer().then(response=>{
        this.customer=response.data;
        this.$store.dispatch('getSinglePaymentPlan',this.customer.paymentPlanId);
      })
    },
    isEmptyObj(object) {
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
            return false;
          }
      }
    return true;
    }
  },
  components: {
     PaymentMethodList,  
     PaymentPlanDetailsAndActivate
  },
   created(){
      if(!this.isEmptyObj(this.user)) {
        this.$store.dispatch('getCurrentCustomer');
        this.getCurrentCustomer();
      }
    },
   computed: {
    plan() {
      return this.$store.getters.getSinglePaymentPlan
    },
    activateAndSubscribe(){
      if(this.plan.typeOfPlan!==3 && this.currentCustomer.startDatePaymentPlan===null){    
        return true
      }
      return false
      },
      activateOneTime(){
        if(this.plan.typeOfPlan===3 && this.currentCustomer.startDatePaymentPlan===null){
          return true;
        }
        return false
      },
    currentCustomer() {
      return this.$store.getters.getCurrentCustomer
    },
    user() {
      return util.getSessionInfo()
    }
 },
}
</script>

<style lang="css" scoped>
.finish-button {
  background-color:#43A047 !important;
  border-color: #43A047 !important;
  padding: 8px !important
}

.finish-button:hover {
  background-color:#246e27 !important;
  border-color: #246e27 !important;
}
.wizard-paymentplan-window >>> sweet-modal {
  text-align: left;
}

/* .wizard-paymentplan-window >>>.vue-form-wizard.md .wizard-icon-circle {
    width: 55px;
    height: 55px;
    font-size: 22px;
}
.wizard-paymentplan-window >>>.vue-form-wizard .wizard-header {
  padding-top: 0;
}
.wizard-paymentplan-window >>>.vue-form-wizard .wizard-nav-pills > li > a{
  color: gray;
} */


</style>