<template>
  <div>
    
    <div class="container">
      <div class="row">
        <div class="col-sm-3">
           <div class="form-label-group">
            <select id="isPreview" v-model="filter.isPreview" class="form-control" placeholder="Video Size">
              <option value="true">Preview</option>
              <option value="false">Full Size</option>
            </select>
            <label for="isPreview">{{'Video Size' | translate}}</label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-label-group">
            <select id="videoType" v-model="filter.videoType" class="form-control" placeholder="Video Type">
              <option value="AE">Tree Of Life</option>
              <option value="REGULAR">Regular</option>
            </select>
            <label for="videoType">{{'Video Type' | translate}}</label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-label-group">
            <select id="user" v-model="filter.userId" class="form-control" placeholder="User">
              <option key="null" value="null">--- all ---</option> 
              <option v-for="user in users" :key="user.userId" :value="user.userId">{{user.fullName}}</option>
            </select>
            <label for="user">{{'User' | translate}}</label>
          </div>
        </div>
        <div class="col-sm-3">
           <div class="form-label-group">
            <select id="company" v-model="filter.customerId" class="form-control" placeholder="Company">   
              <option key="null" value="null">--- all ---</option>
              <option v-for="d in customers" :key="d.customerId" :value="d.customerId">{{d.customerName}}</option>         
            </select>
            <label for="company">{{'Company' | translate}}</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
            <div class="form-label-group">
              <v-date-picker v-model="filter.dateFrom"
                :popover="{ placement: 'top', visibility: 'click' }" 
                
                class="date-picker"
                 :placeholder="'Date From' | translate"
                />
              <!-- <input :class="{'is-invalid': $v.item.subtitle.$error}" v-model="item.eventDate" type="text" id="infoFuneralDate" class="form-control" :placeholder="'Funeral Date *' | translate" required> -->
              <label for="DateFrom">{{'Date From' | translate}}</label>
            </div>
        </div>        
         <div class="col-sm-3">
            <div class="form-label-group">
              <v-date-picker v-model="filter.dateTo"
                :popover="{ placement: 'top', visibility: 'click' }" 
                
                class="date-picker"
                 :placeholder="'Date To' | translate"
                />
              <!-- <input :class="{'is-invalid': $v.item.subtitle.$error}" v-model="item.eventDate" type="text" id="infoFuneralDate" class="form-control" :placeholder="'Funeral Date *' | translate" required> -->
              <label for="DateTo">{{'Date To' | translate}}</label>
            </div>
        </div>
        <div class="col-sm-3"></div> 
        <div class="col-sm-3 buttons">
          <button @click="clearFilter()" type="button" class="command btn btn-default">Clear</button>
          <button @click="getReportData()" type="button" class="command btn btn-primary">Load</button>
        </div>
      </div>
    </div>
      <table id="dtBasicExample" class="data-table table table-bordered sortable" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th @click="sort('fullName')" class="th-sm">User
              <i v-if="sortField === 'fullName'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('customerId')" class="th-sm">Company
              <i v-if="sortField === 'customerId'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('userTypeName')" class="th-sm">Role
              <i v-if="sortField === 'userTypeName'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('videoId')" class="th-sm">Video ID
              <i v-if="sortField === 'videoId'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('isPreview')" class="th-sm">Type
              <i v-if="sortField === 'isPreview'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('videoType')" class="th-sm">Video Type
              <i v-if="sortField === 'videoType'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('userStartDateTime')" class="th-sm">Start
              <i v-if="sortField === 'userStartDateTime'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('endDateTime')" class="th-sm">End
              <i v-if="sortField === 'endDateTime'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('generationDuration')" class="th-sm">Generation Duration (mm:ss)
              <i v-if="sortField === 'generationDuration'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, $index) in list" >
          <tr :key="$index+1">
            <td>{{item.fullName}}</td>
            <td>{{item.customerName}}</td>   
            <td>{{item.userTypeName}}</td>   
            <td>{{item.videoId}}</td>        
            <td>{{item.isPreview == 1 ? 'Preview' : 'Full'}}</td>       
            <td>{{item.videoType == 'REGULAR' ? 'Regular' : 'Tree of Life'}}</td>       
            <td>{{item.userStartDateTime !== null ? item.userStartDateTime : item.startDateTime | formatDateTime}}</td>       
            <td>{{item.endDateTime | formatDateTime}}</td>       
            <td>{{item.generationDuration | formatSsToHhmmss}}</td>
          </tr>
          </template>
        </tbody>
      </table>
    </div>
</template>
<script>
import api from '@/api'
export default {
  // props: ['modalRef'],
  data () {
    return {
      // devices: [],
      filter: this.getDefaultFilter(),
      users: [],
      customers: [],
      list: [],
      order: '',
      sortField: ''
    }
  },
  components: {},
  computed: { 
    rptGenDetailSort () {
      return localStorage.rptGenDetailSort
    },
    rptGenDetailOrder () {
      return localStorage.rptGenDetailOrder
    },
  },
  methods: {
    sort (field) {
      localStorage.rptGenDetailSort = field
      localStorage.rptGenDetailOrder = localStorage.rptGenDetailOrder === 'desc' ? 'asc' : 'desc'
      this.order = localStorage.rptGenDetailOrder
      this.sortField = localStorage.rptGenDetailSort
       this.getReportData()
    },
    clearFilter () {
      this.filter = this.getDefaultFilter()
    },
    getDefaultFilter () {
      return {            
        isPreview: 'false',
        videoType: 'REGULAR',
        userId: null,
        customerId: null,
        dateFrom: null,
        dateTo: null,
        order: 'desc',
        sort: 'endDateTime',
        page: 1,
        pageSize: 10000
      }
    },
    getReportData () {
      this.filter.order = localStorage.rptGenDetailOrder || 'desc'
      this.filter.sort = localStorage.rptGenDetailSort || 'endDateTime'
      if (this.filter.userId === "null"){
        this.filter.userId = null
      }
      if (this.filter.customerId === "null"){
        this.filter.customerId = null
      }
      api.reports.getVideoGenerationDetailedList(this.filter).then(result => {
        let data = result.data.data.map(item => {
          return item
        })
        this.list = data
      })
    }        
  },
  mounted () {
    api.users.getUserList().then(result => {
      this.users = result.data;
    })
    let filter = {
        page: 1,
        pageSize: 1000
    }
    api.customers.getCustomerList({ params: filter }).then(result => {
      this.customers = result.data.data
    })
  }
}
</script>
<style scoped>
.data-table {
  margin-top: 30px;
}
.wrapper {
  width: 70%;
  margin: auto;
  min-width: 1024px;
}
.buttons {
  text-align: right;
}
.buttons button {
    margin-top: 20px;
    margin-left: 20px;
}
.container {
  margin-top: 16px;
}
.sortable th {
  cursor: pointer;
}
</style>