import api from '@/api'
import util from '@/util'
import config from "@/config";
export default {
  getUserContentList (context) {
    return api.userContent.getUserContentList().then(response => {
      context.commit('setUserContentList', response.data)
    })
  },
  getFolderList (context, eventId) {
    return api.folder.getFoldersForEvent(eventId).then(response => {
    context.commit('setFolderList', response.data)
    })
  },
  getThemesList (context) {
    return api.userContent.getThemesList().then(response => {
      context.commit('setThemesList', response.data)
    })
  },
  getStockMusic (context) {
    return api.userContent.getStockMusic().then(response => {
      context.commit('setStockMusic', response.data)
    })
  },
  getThemeStockSlides (context) {
    return api.userContent.getThemeStockSlides().then(response => {
      context.commit('setThemeStockSlides', response.data)
    })
  },
  getThemeSlides (context) {
    return api.userContent.getThemeSlides().then(response => {
      context.commit('setThemeSlides', response.data)
    })
  },
  getVideo (context, videoId) {
    return api.video.get(videoId).then(response => {
      context.commit('setSelectedVideo', response.data)
      context.commit('setSelectedTheme', response.data.theme)
    })
  },
  updateExportStatus (context, exportId) {
    return api.video.getVideoExport(exportId).then(result => {
      context.commit('setExportStatus', result.data)
    })
  },
  addVideoSlideAndGet (context, obj) {
    let slides = [...obj.slides, obj.slide]
    return api.video.addVideoSlideAndGet(slides).then(result => {
      context.commit('updateSelectedVideoSlides', result.data)
    })
  },
  getEventList (context, uid) {
    uid = uid || util.getSessionInfo().userId
    let filter = {
      order: localStorage.eventOrder || 'desc',
      sort: localStorage.eventSort || 'eventDate',
      page: 1,
      pageSize: 10000
    }
    return api.event.getEventListFiltered(uid, { params: filter }).then(response => {
      let data = response.data.data.map(item => {
        item.eventDate = item.eventDate ? new Date(item.eventDate) : null
        return item
      })
      context.commit('setEventList', data)
    })
  },
  getUserEvent (context, uid) {
    uid = uid || util.getUserContext()
    return api.event.getUserEvent(uid).then(response => {
      context.commit('setEventInfo', response.data)
    })
  },
  getUserList (context) {
    let filter = {
      order: localStorage.userOrder || 'desc',
      sort: localStorage.userSort || 'customerName',
      page: 1,
      pageSize: 10000
    }
    return api.users.getUserListFiltered({ params: filter }).then(response => {
      let data = response.data.data.map(item => {
        return item
      })
      context.commit('setUserList', data)
    })
  },
  getCustomerList(context) {
    let filter = {
        page: 1,
        pageSize: 1000
    }
    return api.customers.getCustomerList({ params: filter }).then(response => {
        context.commit('setCustomerList', response.data.data);
    })
  },
  getPaymentPlan(context) {
    let filter = {
      isHidden: false,
      isDeleted: false,
    }
    return api.paymentPlan.getPaymentPlan({ params: filter }).then(response => {
        context.commit('paymentPlan', response.data);
				
    })
  },
  getDeviceList (context) {
    return api.device.getDeviceList().then(response => {
      context.commit('setDeviceList', response.data)
    })
  },
  getCustomerWithDefaultPaymentMethod(context) {
    api.paymentMethods.getCustomerWithDefaultPaymentMethod().then(result => {
      context.commit('setCustomerWithDefaultPaymentMethod', result.data);
    })
  },
  getPaymentMethodsList(context) {
    return api.paymentMethods.getPaymentMethodsList().then(response => {
      context.commit('setPaymentMethodsList', response.data.paymentMethods);
    })

  },
	getCouponsList(context) {
		return api.coupons.getCouponsList().then(response => {
			context.commit('setCouponsList', response.data.coupons);
		})
	},
	deletePaymentMethod(context,paymentMethodId) {
		return api.paymentMethods.deletePaymentMethod(paymentMethodId).then(response => {
			if(response.data) {
				context.dispatch('getPaymentMethodsList');
			}
		});
	},
	makeDefault(context, postSendData) {
		api.paymentMethods.makeDefault(postSendData).then((response) => {
			if(response.data != null) {
			context.dispatch('getCustomerWithDefaultPaymentMethod');
			context.dispatch('getPaymentMethodsList');
			}
		})
	},
	updateCustomer(context, postSendData) {
		api.customers.updateCustomer(postSendData).then((response) => {
			if(response.data != null) {
				context.dispatch('getCustomerList');
			}
		})
	},
  setError (context, message) {
    context.commit('setError', message)
  },
  setInfoMsg (context, message) {
    context.commit('setInfoMsg', message)
  },
  getAllPaymentPlans(context,filter){
    return api.paymentPlan.getAllPlans({ params: filter }).then(response=>{
      context.commit('setPaymentPlans',response.data)			
    })
  },
  getPaymentPlansForCustomers(context, filter) {
    return api.paymentPlan.getAllPlans({ params: filter }).then(response=>{
      context.commit('setPaymentPlansForCustomers',response.data)			
    })
  },
  getSinglePaymentPlan(context,planId){
    return api.paymentPlan.getSinglePlan(planId).then(response=>{
      context.commit('setPaymentPlan',response.data)
    })
  },
	getCurrentCustomer(context) {
		api.customers.getCurrentCustomer().then(response=> {
			this.customer=response.data;
			context.dispatch('getCustomerWithDefaultPaymentMethod')
			context.commit('setCustomer', response.data);
		})
	},
	getCurrentUser(context, currentUser) {
		context.commit('setCurrentUser', currentUser);
	},
  addLoading(context,payload){
    context.commit('setLoading', payload);
  },
  getExternalVideo(context,videoId){
    return api.video.getExternalSlide(videoId).then(response => {
      context.commit('setSelectedVideo', response.data)
      context.commit('setSelectedTheme', response.data.theme)
    })
  },
  addExternalMusic(context,search){
    context.commit('setLoading', true);
    return api.userContent.getExternalMusic(search.search,search.pageNumber).then(async response=>{
      context.commit('setExternalMusic', response.data);
      context.commit('setLoading', false);
    })
    .catch(() => {
      context.commit('setLoading', false);
    })

  },
  getAllInvoices(context,filter){
    context.commit('setInvoiceLoading',true)
    return api.invoice.getAllInvoiceList({ params: filter }).then(response=>{
      context.commit('setInvoiceList',response.data);
      context.commit('setInvoiceLoading',false)
    })
  },
  getCustomerInvoice(context,filter){
    context.commit('setInvoiceLoading',true)
    return api.invoice.getCustomerInvoice({ params: filter }).then(response=>{
      context.commit('setInvoiceList',response.data)
      context.commit('setInvoiceLoading',false)
    })
  },

  async getExternalRecordUrl(context,payload) {
    context.commit('setUrlLoading', true);
    const res=await fetch(`${config.API_URL}api/music/get-preview?TagID=${payload}`, {
        method: 'GET',
        mode:'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${util.getSessionData().access_token}`,           
        }
     })

    var urlWithQuotes = await res.text()
    var url = urlWithQuotes.replace(/['"]+/g, '')
    context.commit('setExternalRecordUrl', url);
    context.commit('setUrlLoading', false);
  },

}


