<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 mx-auto mt-5">
                <div class="payment">
                    <div class="payment_header">
                    <div class="check"><i class="fa fa-exclamation"  aria-hidden="true"></i></div>
                    </div>
                    <div class="content">
                    <h1>Payment error!</h1>
                    <h5 class="m-3">We are sorry. Your payment was not successful, please try again or contact company.</h5>
                      <router-link v-if="this.redirectEvents && this.redirect" class="a" to="events">Go Back</router-link>
                      <router-link v-if="!this.redirectEvents && this.redirect" class="a" to="settings">Go Back</router-link>
                      <router-link v-if="!this.redirect" class="a" to="login">Go Back</router-link>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import util from '@/util'
export default {
  computed: {
    redirect() {
      return util.getRedirection()
    },
    redirectEvents() {
      return util.getRedirectionToEvents();
    }   
  }
  
}
</script>


<style scoped>

    body
    {
        background:#f2f2f2;
    }

    .payment
	{
		border:1px solid #f2f2f2;
		height:280px;
        border-radius:20px;
        background:#fff;
	}
   .payment_header
   {
	background:rgb(218, 10, 10);
	padding:20px;
    border-radius:20px 20px 0px 0px; 
   }
   
   .check
   {
	margin:0px auto;
	width:50px;
	height:50px;
	border-radius:100%;
	background:#fff;
	text-align:center;
   }
   
   .check i
   {
	vertical-align:middle;
	line-height:50px;
	font-size:30px;
   }

    .content 
    {
        text-align:center;
    }

    .content  h1
    {
        font-size:25px;
        padding-top:25px;
    }

    .a
    {
        width:200px;
        height:35px;
        color:#fff;
        border-radius:30px;
        padding:5px 10px;
        background:rgb(218, 10, 10);
        transition:all ease-in-out 0.3s;
    }

    .content a:hover
    {
        text-decoration:none;
        background:#000;
    }
</style>