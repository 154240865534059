<template>
    <div class="tab-pane" id="billing">
        <br/>
        <h6>PAYMENT SETTINGS</h6>
        <hr>
        <form>
          <div class="form-group">
            <label for="location">Company</label>
            <input disabled="true" type="text" class="form-control" id="location" placeholder="Enter Conpany Name" :value="seasonInfo.customerName" >
          </div>
          <div class="form-group">
            <label for="location">Payment Plan </label>
            <input disabled="true" type="text" class="form-control" id="location" placeholder="Enter Conpany Name" :value="plan.name" >
          </div>
              <div class="form-row mt-4" v-if="hasPaymentPlan && !isLoading">
                <div class="col-md-7 mb-0">
                  <label class="d-block">Current Payment Plan</label>
                    <div class="card mb-3">
                      <div class="row no-gutters">
                        <div class="col-md-4 title-field" :style="planColor" @click="openInfoModal">
                            <h3 class="card-title">{{plan.name}}</h3>
                          </div>
                          <div class="col-md-8">
                            <div class="card-body">
                              <p class="card-text">Price: <b>{{plan.price}}€</b>
                              <br>
                              Videos per funeral: <b>{{plan.videosPerEvent}}</b>
                              <br>
                              Number of funerals: <b>{{plan.typeOfPlan==4 ? "∞" : plan.numberOfEvents}}</b>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4" style="margin-top:15px;">
                    
                          <div style="margin-top:20px">
                            <div class="row ">
                        
                      <button style="margin-left:100px;" v-if="(deactivateAndUnsubscribe || deactivateOneTime) && hasAnActivatePaymentPlan" class="command btn btn-primary" type="button" @click="openPaymentPlanDialog()"> Choose different</button>
                            <div  style="text-align:center;margin-top:10px;">
                              <button style="margin-left:100px;margin-top:20px;" v-if="activateAndSubscribe"  class="command btn btn-success" type="button" @click="subscribe()">Activate</button>
                              <button style="margin-left:100px;margin-top:20px;" v-if="activateOneTime" class="command btn btn-success" type="button" @click="payOneTime()">Activate</button>
                              <button style="margin-left:100px;" v-if="deactivateAndUnsubscribe && hasAnActivatePaymentPlan" class="command btn btn-danger" type="button" @click="openUnsubscribeModal()">Cancel subscription</button>
                              <button style="margin-left:100px;" v-if="deactivateOneTime && hasAnActivatePaymentPlan" class="command btn btn-danger" type="button" @click="stopOneTime()">Deactivate</button>
                       
                          </div>
                         
                        </div>
                      </div>
                       </div> 
                    <div class="form-group" v-if="displayDiscount">
                        <label for="location">You have {{this.discount}} discount</label>
                    </div>
              </div>
                  <div class="text-center" v-if="isLoading">
                      <div class="spinner-border" style="width: 5rem; height: 5rem;" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div class="form-group mt-3" v-if="activateOneTime || activateAndSubscribe">
                      <label>Choose payment plan</label>
                    <select class="custom-select" @change="onChange($event)" required >
                        <!-- <option value="0">Payment Plans</option> -->
                        <option 
                        v-for="(item,$index) in paymentPlans" 
                        :value="item.paymentPlanId" 
                        :key="$index"
                        :selected="item.paymentPlanId === customer.paymentPlanId"
                        >{{item.name}}
                        </option>
                    </select>
                  </div>
                  <div class="form-group" v-if="plan.typeOfPlan!=3 && (deactivateOneTime || deactivateAndUnsubscribe)">
                    <label for="expiration">Number Of Funerals Spent</label>
                    <input disabled="true" type="text" class="form-control" id="expiration" :value="customer.currentEventCountPaymentPlan" >
                  </div>
                  <div class="form-group mt-3" v-if="deactivateOneTime || deactivateAndUnsubscribe">
                    <label for="start">Payment Plan Start Date</label>
                    <input disabled="true" type="text" class="form-control" id="start" :value="getDateFormat(customer.startDatePaymentPlan)" >
                  </div>
                  <div class="form-group" v-if="plan.typeOfPlan!=3 && (deactivateOneTime || deactivateAndUnsubscribe)">
                    <label for="expiration">Payment Plan End Date</label>
                    <input disabled="true" type="text" class="form-control" id="expiration" :value="getDateFormat(customer.expirationDatePaymentPlan)" >
                  </div>
              </form>
         
                <sweet-modal ref="planInfoModal" class="infoModal" title="Payment Plan Details">
                  <!-- <InfoDialog :modal-ref="$refs.planInfoModal"/> -->
                  <PaymentPlanInfo @closePlanInfoModal="closePlanInfoModal"/> 
                </sweet-modal>
                <sweet-modal ref="unsubscribeModal" icon="warning">
                    Are you sure you want to cancel your subscription?
                <div>
                  <button class="btn btn-light modal-button" @click="$refs.unsubscribeModal.close()" type="button">No</button>
                  <button class="btn btn-danger modal-button" @click="unsubscribe()" type="button">Yes</button>
                </div>
                  <!-- <CancelSubscriptionModal @closeUnsubscribeModal="closeUnsubscribeModal" @unsubscribe="unsubscribe"/> -->
                </sweet-modal>
                <sweet-modal ref="paymentPlanDialog" class="wizard-paymentplan-window" title="Choose different payment plan" v-if="this.switchUpdated">
                    <SwitchPaymentPlanModal @closePaymentPlanDialog="closePaymentPlanDialog"/> 
                </sweet-modal>
                 <sweet-modal ref="updatedPaymentPlanDialog" class="wizard-paymentplan-window" title="Choose different payment plan" v-if="!this.switchUpdated">
                    <UpdatedSwitchPaymentPlanModal @changeState="changeState" @closeUpdateSwitchDialog="closeUpdateSwitchDialog" :updatedPaymentPlan="updatedPaymentPlan"/> 
                </sweet-modal>

    </div>
</template>

<script>
//import InfoDialog from '@/components/paymentPlan/paymentPlanForms/InfoDialog'
import PaymentPlanInfo from '@/components/paymentPlan/paymentPlanForms/PaymentPlanInfo'
import SwitchPaymentPlanModal from '@/components/customers/settings/SwitchPaymentPlanModal'
import UpdatedSwitchPaymentPlanModal from '@/components/customers/settings/UpdatedSwitchPaymentPlanModal'
import util from '@/util'
import api from '@/api'
//import CancelSubscriptionModal from "./CancelSubscriptionModal";

export default {
    components:{
       // InfoDialog,
       PaymentPlanInfo,
        SwitchPaymentPlanModal,
        UpdatedSwitchPaymentPlanModal
        //CancelSubscriptionModal
    },
    computed:{
        displayDiscount(){
          return this.discount !== null ? true: false
        },
        displayPlanName(){
          return this.customer.paymentPlanId!==null? `${this.plan.name}`:'You do not have payment plan, please select one below'
        },
        activateAndSubscribe(){
          if(this.plan.typeOfPlan!==3 && this.customer.startDatePaymentPlan===null){
            return true
          }
          return false
        },
        activateOneTime(){
          if(this.plan.typeOfPlan===3 && this.customer.startDatePaymentPlan===null){
            return true;
          }
          return false
        },
        deactivateAndUnsubscribe(){
          if(this.plan.typeOfPlan!==3 && this.customer.startDatePaymentPlan!==null){
            return true;
          }
          return false
        },
        hasAnActivatePaymentPlan() {
          if(this.customer.startDatePaymentPlan !== null && this.user.userTypeId !== 3){
            return true;
          }
          return false
        },
        deactivateOneTime(){
          if(this.plan.typeOfPlan==3 && this.customer.startDatePaymentPlan!==null){
            return true;
          }
          return false;
        },
        hasPaymentPlan(){
          return this.customer.paymentPlanId!==null?true:false
        },

        seasonInfo(){
            return util.getSessionInfo()
        },
        plan(){
          return this.$store.getters.getSinglePaymentPlan
        },
        paymentPlans(){
          return this.$store.getters.getAllPaymnetPlans.data
        },
        user() {
          return util.getSessionInfo()
        },
        planColor(){
        return {backgroundColor: `${this.plan.displayColor}`};
      },
    },
    data(){
        return{
            customer:{},
            switchUpdated: false,
            discount:null,
            isLoading:false,
            updatedPaymentPlan:{}
        }
    },

    created(){
      this.switchUpdated = true;
      sessionStorage.removeItem('subscribe');
      sessionStorage.removeItem('events');
      this.getCurrentCustomer();
        
    },
    methods:{
      changeState() {
         this.switchUpdated = true;
         this.getCurrentCustomer();
      },
        closeUpdateSwitchDialog() {
          this.$refs.updatedPaymentPlanDialog.close()
        },
       closePlanInfoModal() {
         this.updatedPaymentPlan = null;
          this.$refs.planInfoModal.close();
        },
      closePaymentPlanDialog() {
         this.$refs.paymentPlanDialog.close()
      },
      openPaymentPlanDialog() {
        if(this.switchUpdated) {
          this.$refs.paymentPlanDialog.open()
        }else {
          this.$refs.updatedPaymentPlanDialog.open()
        }
      },
        openInfoModal(){
          this.$refs.planInfoModal.open()
        },
        getDateFormat(value){
            var date = new Date(value)
            return date.toUTCString()
        },
        getCurrentCustomer(){
            api.customers.getCurrentCustomer().then(response=>{
            this.customer=response.data;

            this.$store.dispatch('getSinglePaymentPlan',this.customer.paymentPlanId);
            api.paymentPlan.getSinglePlan(this.customer.paymentPlanId).then(response => {
                this.updatedPaymentPlan = response.data;
                 sessionStorage.setItem('current', this.updatedPaymentPlan)
            })
            this.$store.dispatch('getCurrentCustomer')
            this.getDiscount();
            this.getAllPaymentPlans();
            this.isLoading=false;
        })
        },
        getDiscount() {
          if(this.customer.stripeCouponId != null) {
              api.coupons.getCouponById(this.customer.stripeCouponId).then(response => {
                this.discount = response.data.coupon.percent_off!=null? response.data.coupon.percent_off+"%": (response.data.coupon.amount_off/100) +response.data.coupon.currency; 
            })
          }
        },
        getAllPaymentPlans(){
          let filter = {
            page: 1,
            pageSize: 1000,
            isHidden: false,
            isDeleted: false,
          };
          this.$store.dispatch('getAllPaymentPlans',filter)
        },
        setSelectColor(item){
          return `backgroundColor:${item.displayColor}`
        },
        onChange(event){
          if(event.target.value!=0){
           
            this.isLoading=true;
            this.customer.paymentPlanId=event.target.value;
            api.customers.updateCustomer(this.customer).then(()=>{
            
              this.getCurrentCustomer()
               
            })
          }
        },
        payOneTime(){
          api.customerPaymentPlan.activateOneTime().then(()=>{
            this.switchUpdated = false;
            this.getCurrentCustomer()  
          })
        },
        stopOneTime(){
          api.customerPaymentPlan.deactivateOneTime().then(()=>{
            this.updatedPaymentPlan = null
            this.getCurrentCustomer()
          })
        },
        openUnsubscribeModal() {
          this.$refs.unsubscribeModal.open()
        },
        closeUnsubscribeModal() {
          this.$refs.unsubscribeModal.close()
        },
        unsubscribe(){
          api.customerPaymentPlan.deactivateAndUnsubscribe().then(()=>{
            this.getCurrentCustomer()
            this.$refs.unsubscribeModal.close()
          })
         
        },
        subscribe(){
          sessionStorage.setItem('subscribe', 'errorPage');
          sessionStorage.setItem('events', 'false');
          return util.subscribe();
        }
    }
    
}
</script>


<style scoped>
.command {
   width: 170px;
   text-align:center;
}
body{
    margin-top:20px;
    color: #1a202c;
    text-align: left;
    background-color: #e2e8f0;    
}
.main-body {
    padding: 15px;
}

.nav-link {
    color: #4a5568;
}
/* .card {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
} */

.card {
    border-radius: 0%;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    /* border-radius: .25rem; */
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
/* .shadow-none {
    box-shadow: none!important;
} */

/* .card{
    border-radius: 7%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */
.card-title{
  color: white;
  font-size: 100%;

}
.title-field{
overflow: hidden;
word-break: break-all;
justify-content: center;
text-align: center;
display: flex;
align-items: center;
/* border-radius: 10%;
box-shadow: 6px 6px 5px grey; */
}

.title-field:hover{
 cursor: pointer;
 /* border: 2px solid rgb(0, 174, 255); */
}

.confirm{
  margin-left: 20%;
  margin-top: 20%;
}
/* #cancelButton {
  padding:10px;
} */




</style>