<template>
  <span>
    <a class="lang-link" href="#" :class="{active: lang === 'en'}" @click.prevent="setLang('en')">EN</a>
    <a class="lang-link" href="#" :class="{active: lang === 'nl'}" @click.prevent="setLang('nl')">NL</a>
    <a class="lang-link" href="#" :class="{active: lang === 'fr'}" @click.prevent="setLang('fr')">FR</a>
    <a class="lang-link" href="#" :class="{active: lang === 'de'}" @click.prevent="setLang('de')">DE</a>
  </span>
</template>
<script>
import Vue from 'vue'
export default {
  data() {
    return {
      lang: ''
    }
  },
  created () {
    this.lang = localStorage.lang
  },
  methods: {
    setLang (lang) {
      document.documentElement.lang = lang
      localStorage.lang = lang
      this.lang = lang
      Vue.i18n.set(lang)
    }
  }
}
</script>
<style scoped>

.lang-link {
  color: gray;
  margin: 10px;
  font-size: 14px;
}
.lang-link.active{
  font-weight: bold;
  color: #007bff;
}
</style>