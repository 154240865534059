import Vue from 'vue'
import util from '@/util'
import config from "@/config";

export default {
  users: {
    login (username, password) {
      return Vue.http.post('token', {
        'grant_type': 'password',
        'username': username,
        'password': password
      }, { emulateJSON: true })
    },
    requestPasswordReset (obj) {
      return Vue.http.post('api/request-password-reset', obj)
    },
    resetPassword (obj) {
      return Vue.http.post('api/reset-password', obj)
    },
    getUserInfo () {
      return Vue.http.get('api/get-user-info')
    },
    getAllNotifications () {
      return Vue.http.get(`api/get-all-notifications/${util.getSessionInfo().userId}/false`)
    },
    setNotificationRead (id) {
      return Vue.http.post(`api/set-notification-read/${id}`, {})
    },
    setUserState (id, state) {
      return Vue.http.put(`api/set-user-state/${id}/${state}`, {})
    },
    getUserList () {
      return Vue.http.get(`api/get-users`)
    },
    getDistributorList () {
      return Vue.http.get(`api/get-distributors`)
    },
    getManagersList () {
      return Vue.http.get(`api/get-managers`)
    },
    addUser (user) {
      return Vue.http.post(`api/add-user`, user)
    },
    updateUser (user) {
      return Vue.http.put(`api/update-user`, user)
    },
    sendInvite (user) {
      return Vue.http.post(`api/send-inv-general`, user)
    },
    finishRegistration (regInfo) {
      return Vue.http.post(`api/finish-registration`, regInfo)
    },
    getUserListFiltered (filter) {
      return Vue.http.get(`api/get-users/list`, filter)
    },
    getDistributorsForCompany() {
      return Vue.http.get(`api/users/get-distributors-for-company`)
    }
    // ,
    // register (email, pwd, usr, name) {
    //   return Vue.http.post('users', {
    //     email,
    //     pwd,
    //     usr,
    //     name
    //   })
    // }
  },
  userContent: {
    getContentTypes () {
      return Vue.http.get('api/get-user-content-types')
    },
    getUserContent (id) {
      return Vue.http.get(`api/get-user-content/${id}`)
    },
    updateUserContent (id, content) {
      return Vue.http.put(`api/update-user-content/${id}`, content)
    },
    deleteUserContent (id) {
      return Vue.http.delete(`api/delete-user-content/${id}`)
    },
    deleteSelectedUserContent (ids) {
      return Vue.http.post(`api/delete-selected-user-content`, ids)
    },
    getUserContentList () {
      let uid = util.getUserContext() || util.getSessionInfo().userId
      return Vue.http.get(`api/get-user-content-list/${uid}`)
    },
    getThemesList () {
      return Vue.http.get('api/get-themes')
    },
    getStockMusic () {
      return Vue.http.get('api/get-stock-audio')
    },
    getThemeStockSlides () {
      return Vue.http.get('api/get-theme-stock-slides')
    },
    getThemeSlides () {
      return Vue.http.get('api/get-theme-slides')
    },
    getUserShareableContentList (guid) {
      return Vue.http.get(`api/get-user-content-list-guid/${guid}`)
    },
    getExternalMusic(search,pageNumber){
      return Vue.http.get(`api/music/search?search=${search}&pageNumber=${pageNumber}`)
    },
    getExternalSlide(id){
      return Vue.http.get(`api/music/download/${id}`)
    },
    getBlobUrlForPreview(tagID){
      return Vue.http.get(`api/music/get-preview?TagID=${tagID}`)
    },
  },
  video: {
    get (id, getSlides, getSlideContent) {
      getSlides = getSlides || true
      getSlideContent = getSlideContent || false
      return Vue.http.get(`api/get-video/${id}/${getSlides}/${getSlideContent}`)
    },
    getForUserPublic (uid, id) {
      return Vue.http.get(`api/get-video-for-user-public/${uid}/${id}`)
    },
    getUserVideos (id) {
      return Vue.http.get(`api/get-user-videos/${id}`)
    },
    getNextVideoNumber () {
      let uid = util.getUserContext() || util.getSessionInfo().userId
      return Vue.http.get(`api/get-video-next-number/${uid}`)
    },
    add (video) {
      let uid = util.getUserContext() || util.getSessionInfo().userId
      return Vue.http.post(`api/add-video/${uid}`, video)
    },
    update (video) {
      return Vue.http.put(`api/update-video`, video)
    },
    generatePreview (video) {
      return Vue.http.post(`api/generate-video-preview`, video)
    },
    generateRegularVideo (payload) {
      return Vue.http.post(`api/generate-regular-video`, payload)
    },
    generateVideo (id, isPreview) {
      return Vue.http.get(`api/generate-video/${id}/${isPreview}`)
    },
    getTolLink (id, isPreview) {
      return Vue.http.get(`api/get-tol-link/${id}/${isPreview}`)
    },
    getVideoExport (id) {
      return Vue.http.get(`api/get-video-export/${id}`)
    },
    saveVideoExport (obj) {
      return Vue.http.post(`api/save-video-export`, obj)
    },
    getTextSlidePreview (slideData) {
      return Vue.http.post(`api/get-text-slide-preview`, slideData)
    },
    saveTextSlide (slideData) {
      let uid = util.getUserContext() || util.getSessionInfo().userId
      return Vue.http.post(`api/save-text-slide/${uid}`, slideData)
    },
    getCoverPhotoPreview (coverPhotoData) {
      return Vue.http.post(`api/get-cover-photo-preview`, coverPhotoData)
    },
    addSlide (slideData) {
      return Vue.http.post(`api/add-slide`, slideData)
    },
    addVideoSlideAndGet (slideData) {
      return Vue.http.post(`api/upsert-video-slide-and-get`, slideData)
    },
    addSlides (slideData) {
      return Vue.http.post(`api/add-slides`, slideData)
    },
    removeSlide (id) {
      return Vue.http.delete(`api/delete-slide/${id}`)
    },
    reorderSlides (slides) {
      return Vue.http.post(`api/reorder-slides`, slides)
    },
    saveCoverPhoto (coverPhoto, currentFolderId) {
      let uid = util.getUserContext() || util.getSessionInfo().userId
      if (currentFolderId == -1)
      {
        return Vue.http.post(`api/save-cover-photo/${uid}`, coverPhoto)
      }
      return Vue.http.post(`api/save-cover-photo/${uid}/${currentFolderId}`, coverPhoto)
    },
    rename (obj) {
      return Vue.http.put(`api/update-file-name`, obj)
    }
  },
  device: {
    add (device) {
      return Vue.http.post(`api/add-device`, device)
    },
    update (device) {
      return Vue.http.put(`api/update-device`, device)
    },
    getDeviceList () {
      return Vue.http.get(`api/get-devices`)
    },
    getDeviceCommands (id) {
      return Vue.http.get(`api/get-device-commands/${id}`)
    },
    delete(id) {
      return Vue.http.delete(`api/delete-device/${id}`)
    }
  },
  event: {
    getEventList (uid) {
      return Vue.http.get(`api/get-events/${uid}`)
    },
    getEventListFiltered (uid, filter) {
      return Vue.http.get(`api/get-events/${uid}/list`, filter)
    },
    getUserEvent (uid) {
      return Vue.http.get(`api/get-user-event/${uid}`)
    },
    addEvent (event) {
      return Vue.http.post(`api/add-user-instant`, event)
    },
    updateEvent (event) {
      return Vue.http.put(`api/update-event-extended`, event)
    },
    deleteEvent(uid){
      return Vue.http.delete(`api/delete-event/${uid}`)
    },
    payEventWithSubscription(value){
      return Vue.http.post(`api/payment/pay-event-from-subscription?eventId=${value}`)
    }
  },
  presentation: {
    upsertPresentation (presentation) {
      return Vue.http.post(`api/upsert-presentation`, presentation)
    },
    upsertPresentationSlide (slide) {
      return Vue.http.post(`api/upsert-presentation-slide`, slide)
    },
    deletePresentationSlide (id) {
      return Vue.http.delete(`api/delete-presentation-slide/${id}`)
    },
    getPresentationForEvent (eventId) {
      return Vue.http.get(`api/get-presentation-for-event/${eventId}`)
    },
    getSlidesForPresentation (id) {
      return Vue.http.get(`api/get-slides-for-presentation/${id}`)
    },
    exportPresentation (presentationId) {
      return Vue.http.get(`api/export-presentation/${presentationId}`)
    },
    downloadPresentation (presentationId) {
      return Vue.http.get(`api/download-zip-presentation/${presentationId}`)
    }
  },
  reports: {
    getVideoGenrationSummary (filter) {
      return Vue.http.post(`api/video-gen-summary`, filter)
    },
    getVideoGenerationDetailed (filter) {
      return Vue.http.post(`api/video-gen-detail`, filter)
    },
    getUserSessionDetailed (filter) {
      return Vue.http.post(`api/user-session-detail`, filter)
    },
    getVideoGenerationSummaryList (filter) {
      return Vue.http.post(`api/video-gen-summary/list`, filter)
    },
    getVideoGenerationDetailedList (filter) {
      return Vue.http.post(`api/video-gen-detail/list`, filter)
    },
    getUserSessionDetailedList (filter) {
      return Vue.http.post(`api/user-session-detail/list`, filter)
    }
  },
  customers: {
	getCustomerList (filter) {
		return Vue.http.get(`api/customers`, filter)
	},
	updateCustomer(data) {
		return Vue.http.put(`api/customers`, data)
	},
  getCurrentCustomer(){
    let cid = util.getSessionInfo().customerId;
    return Vue.http.get(`api/customers/${cid}`,)
  }
  },
  paymentPlan:{
    getAllPlans (filter){
      return Vue.http.get('api/payment-plan',filter)
    },
    getBetterPaymentPlan() {
      return Vue.http.get('api/payment-plan/better')
    },
    getSinglePlan(planId){
      return Vue.http.get(`api/payment-plan/${planId}`)
    },
    deleteSinglePlan(planId){
      return Vue.http.delete(`api/payment-plan/${planId}`)
    }
  },
  registration: {
    registerCustomerAndUser(customer) {
      return Vue.http.post(`api/register-user-and-customer`, customer)
    },
  },  
  invoice: {
    getAllInvoiceList(filter) {
      return Vue.http.get(`api/invoice-methods/get-all`, filter)
    },
    getCustomerInvoice(filter){
      let uid = util.getUserContextUrlParam()
      return Vue.http.get(`api/invoice-methods/get-all-user/${uid}`, filter)
    },
  },
	paymentMethods: {
		getCustomerWithDefaultPaymentMethod() {
			return Vue.http.get(`api/payment-methods/user-default`)
		},
		getPaymentMethodsList() {
			return Vue.http.get(`api/payment-methods`) 
		},
		deletePaymentMethod(paymentMethodId) {
			return Vue.http.delete(`api/payment-methods/${paymentMethodId}`) 
		},
		makeDefault(postSendData) {
			return Vue.http.post(`api/payment-methods/customer-default`, postSendData)
		},
		createSetupIntent() {
			return Vue.http.post(`api/payment-methods/setup-intent`)
		},
	}, 
  coupons : {
    getCouponsList() {
      return Vue.http.get(`api/coupons`) 
    }, 
		getCouponById(stripeCouponId) {
			return Vue.http.get(`api/coupons/${stripeCouponId}`) 
		}
  },
  customerPaymentPlan:{
    activateOneTime(){
      return Vue.http.get(`api/customers/activate-one-time-payment-plan`)
    },
    deactivateAndUnsubscribe(){
      return Vue.http.get(`api/customers/deactivate-payment-plan-cancel-subscription`)
    },
    deactivateOneTime(){
      return Vue.http.get(`api/customers/deactivate-one-time-payment-plan`)
    }
  },
  loginMessage:{
    getLoginMessage (){
      return Vue.http.get(`api/login-message/`)
    },
    async updateLoginMessage(formData){
      await fetch(`${config.API_URL}/api/login-message`,{
          method:'PUT',
          mode:'cors',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${util.getSessionData().access_token}`,
          },
          body:JSON.stringify(formData)
        })
    }
  },
  folder:{
    getFoldersForEvent (eventId){
      return Vue.http.get(`api/folder/${eventId}`)
    },
    deleteFolder(folderId){
      return Vue.http.delete(`api/folder/${folderId}`)
    },
    async postFolder(formData){
      await fetch(`${config.API_URL}/api/folder`,{
          method:'POST',
          mode:'cors',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${util.getSessionData().access_token}`,
          },
          body:JSON.stringify(formData)
        })
    },
    async updateFolder(formData){
      await fetch(`${config.API_URL}/api/folder`,{
          method:'PUT',
          mode:'cors',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${util.getSessionData().access_token}`,
          },
          body:JSON.stringify(formData)
        })
    }
  },
}
