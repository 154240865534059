<template>
  <form ref="form" class="form">
    <div v-if="user.userTypeId === 5 || user.userTypeId === 4" class="form-label-group">
      <select v-model="item.parentUserId" id="parentUserId" class="form-control" :placeholder="'Assign to Manager' | translate">
        <option v-for="d in managers" :key="d.userId" :value="d.userId">{{d.fullName}}</option>
      </select>
      <label for="parentUserId">{{'Assign to Manager' | translate}}</label>
    </div>
    <div class="form-label-group" style="">
      <input id="infoTitle" v-model="item.introductoryText" class="form-control" :placeholder="'Introduction Text' | translate" />
      <label for="infoTitle">{{'Introduction Text' | translate}}</label>
    </div>
    <div class="form-label-group">
      <input :class="{'is-invalid': $v.item.mainText.$error}" v-model="item.mainText" type="text" id="infoMainText" class="form-control" :placeholder="'Name of the Deceased *' | translate" required>
      <label for="infoMainText">{{'Name of the Deceased *' | translate}}</label>
    </div>
    <div class="form-label-group">
      <input :class="{'is-invalid': $v.item.subText.$error}" v-model="item.subText" type="text" id="infoSubText" class="form-control" :placeholder="'Born - Died *' | translate" required>
      <label for="infoSubText">{{'Born - Died *' | translate}}</label>
    </div>
    <div class="form-label-group">
       <v-date-picker v-model="item.eventDate"
        :popover="{ placement: 'top', visibility: 'click' }" 
        
        class="date-picker"
        id="infoFuneralDate" :placeholder="'Content Deadline Date' | translate"
        />
        <!-- <input :class="{'is-invalid': $v.item.subtitle.$error}" v-model="item.eventDate" type="text" id="infoFuneralDate" class="form-control" :placeholder="'Funeral Date *' | translate" required> -->
      <label class="picker-label" for="infoFuneralDate">{{'Content Deadline Date' | translate}}</label>
      <div class="sub-form-group">
        <select @change="updateTime()" v-model="item.hours" id="hours" class="form-control" :placeholder="'Hours' | translate">
          <option v-for="h in hours" :key="h" :value="h">{{h}}</option>
        </select>
        <label for="hours">{{'Hours' | translate}}</label>
      </div>
      <div class="sub-form-group">
        <select @change="updateTime()" v-model="item.minutes" id="minutes" class="form-control" :placeholder="'Minutes' | translate">
          <option v-for="m in minutes" :key="m" :value="m">{{m}}</option>
        </select>
        <label for="minutes">{{'Minutes' | translate}}</label>
      </div>
    </div>

    <div class="form-label-group">
      <input :class="{'is-invalid': $v.item.email.$error}" v-model="item.email" type="text" id="infoEmail" class="form-control" :placeholder="'Family Member Email' | translate">
      <label for="infoEmail">{{'Family Member Email' | translate}}</label>
      <div class="sub-form-group">
        <select class="form-control" v-model="userTypeId" :placeholder="'User Type' | translate" required>
          <option value="1">Uploader</option>
          <option value="2">Creator</option>
        </select>
        <label for="userType">{{'User Type' | translate}}</label>
      </div>
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      {{this.errorMessage}}
    </div>
    <div class="buttons-row">
      <button type="button" @click="closeDialog()" class="command btn btn-default">Cancel</button>
      <button type="button" @click="saveItem()" class="command btn btn-primary" :disabled="(item.eventId && !item.Paid) || isLoading"><i class="fa fa-save"></i> Save</button>
    </div>
  </form>
</template>
<script> 
import { required, email } from 'vuelidate/lib/validators'
import api from '@/api'
import util from '@/util'
export default {
  props: ['item', 'modalRef'],
  data () {
    return {
      eventData: [],
      managers: [],
      minutes: util.getMinutes(),
      hours: util.getHours(),
      error: false,
      errorMessage: '',
      userTypeId : 2,
      customer : {},
      betterPaymentPlan: {},
      isLoading: false
    }
  },
  computed: {
    user () {
      return util.getSessionInfo()
    }
  },
  validations: {
    item: {
      mainText: {
        required
      },
      subText: {
        required
      },
      email: {
        email
      }
    }
  },
  mounted () {
    if (this.item.eventId) { 
      this.userTypeId = this.item.userTypeId
    }
    api.users.getManagersList().then(result => {
      this.managers = result.data
    })
		this.getCurrentCustomer();
  },
  methods: {
    getCurrentCustomer(){
      api.customers.getCurrentCustomer().then(response=>{
        this.customer = response.data;      
      })
    },
    closeDialog() {
      this.error = false
      this.errorMessage = ''
      this.modalRef.close();
    },
    updateTime () {
      if (this.item.eventDate){
        if (this.item.minutes){
          this.item.eventDate.setMinutes(this.item.minutes)
        }
        if (this.item.hours){
          this.item.eventDate.setHours(this.item.hours - (new Date().getTimezoneOffset()) / 60)
        }  
      }      
    },
    loadList () {
      this.$store.dispatch('getEventList').then(() => {
        this.modalRef.close()
        this.$emit('getCurrentCustomer');
      })
    },
    saveItem () {
      this.isLoading = true;
      this.error = false;
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.isLoading = false;
        return
      }
      if (this.item.eventId) {
        this.updateTime()
        api.event.updateEvent(this.item).then(() => {
          this.loadList()
          this.isLoading = false
        }).catch(error => {
          this.error = true
          this.errorMessage = error.data;
          this.isLoading = false
        })
      } else {
        this.item.userTypeId = this.userTypeId;
        api.event.addEvent(this.item).then(() => {
          this.loadList()
          this.isLoading = false
        }).catch(error => {
          this.error = true
          this.errorMessage = error.data;
          this.isLoading = false
        })
      }
    }
  },
		
}
</script>
<style scoped>
.form-label-group {
  margin-top: 20px;
}
.sub-form-group {
  position: relative;
  padding-left: 10px;
  /* margin-top: 20px; */
  width: 25%;  
}
.sub-form-group label {
  position: absolute;
  top: 0;
  left: 24px;
}
.sub-form-group select {
  height: 46px;
}
.date-picker {
  width: 50%;
}
.picker-label {
  font-size: 12px;
  color: #777;
}
</style>
