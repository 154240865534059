import util from '../../util'
import config from "@/config";

export async function postPlan(formData){
    await fetch(`${config.API_URL}/api/payment-plan`,{
        method:'POST',
        mode:'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${util.getSessionData().access_token}`,           
        },
        body:JSON.stringify(formData)
      })
}

export async function updatePlan(formData){
    await fetch(`${config.API_URL}/api/payment-plan`,{
        method:'PUT',
        mode:'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${util.getSessionData().access_token}`,           
        },
        body:JSON.stringify(formData)
      })
}




