<template>
    <div class="tab-pane " id="invoices">
        <Invoices />
    </div>
</template>

<script>
import Invoices from '../../invoice/Invoices';

export default {
    components:{
        Invoices
    }
}
</script>