<template>
  <div>
    <MainNav></MainNav>
    <div class="wrapper">
      <div class="row">
        <div class="col-6">
          <div class="filter-buttons-container">
            <ContentFilter></ContentFilter>
          </div>
          <div class="scrollable-container">
          <ContentList :nopreview="true" :chooser="false" :readonly="true"></ContentList>
          </div>
        </div>
        <div class="col-6"><h3 class="device-title">Device Tiles</h3>
          <form ref="form" class="form">
            <div class="form-label-group" style="width:350px; padding-left: 45px;">
              <select @change="deviceChange()" v-model="presentation.deviceId" id="deviceSelect" class="form-control" :placeholder="'Device' | translate" required>
                <option v-for="(d, i) in devices" :key="i" :value="d.deviceId">{{d.name}}</option>
              </select>
              <label style="padding-left: 55px;" for="deviceSelect">{{'Device' | translate}}</label>
            </div>
          </form>
          <div v-if="presentation.deviceId" class="device-tiles" :class="{'drag-in-progress': dragInProgress}">
            <div class="row">
              <div
                @dragover.prevent @dragenter="dragEnter" @dragleave="dragLeave" @drop="itemDrop($event, item, presentationItems1, $index)"
                v-for="(item, $index) in presentationItems1" :key="$index"
                class="rounded theme-slide video-slide-dropzone"
                :class="{'has-content': item.userContentId, 'image': item.userContentTypeId === 2, 'audio': item.userContentTypeId === 3, 'video': item.userContentTypeId === 1, }"
                @click="openSlide(item)"
                >
                <i v-show="item.presentationSlideId" @click.prevent.stop="deletePresentationSlide(item.presentationSlideId, $index, item.orderNo)" class="btn btn-light fa fa-trash command-icon delete-icon"></i>
                <img v-if="item.thumbnailDataLink" :src="S3_URL + item.thumbnailDataLink" />
                <div v-if="item.userContentId && !item.thumbnailDataLink" class="rounded thumb-icon">
                  <i :class="{'fa-play': item.userContentTypeId === 1, 'fa-music': item.userContentTypeId === 3}" class="fa thumb-icon fa-3x"></i>
                </div>
                <div class="file-name" v-if="item.slideName || item.fileName">{{item.orderNo}}-{{item.slideName || item.fileName}}</div>
                <div v-if="!item.userContentId" class="tile-placeholder">
                  {{item.orderNo}}
                </div>
              </div>
            </div>
            <div class="device-command-buttons">
              <button style="width: 106px;" type="button" @click="$refs.exportPresentation.open()" class="command btn btn-primary">Export</button>
              
              <!-- <a :href="API_URL + 'api/download-zip-presentation/' + presentation.presentationId" class="command btn btn-primary">Export</a> -->
              <!-- <button :disabled="disableDownload" type="button" @click="downloadPresentation()" class="command btn btn-primary">Export</button> -->
              <button :disabled="disable" type="button" @click="confirmSendPresentation()" class="command btn btn-primary">Send Presentation</button>
            </div>
            <sweet-modal ref="sendPresentation" icon="warning">
              You are about to send presentation to FTP Server. Are you sure?
              <div>
                <button class="btn btn-light modal-button" @click="$refs.sendPresentation.close()" type="button">No</button>
                <button class="btn btn-danger modal-button" @click="sendPresentation()" type="button">Yes</button>
              </div>
            </sweet-modal>
            <sweet-modal ref="exportPresentation" icon="info">
              You are about to export presentation. Are you sure?
              <div class="device-command-buttons">
                <button class="btn btn-light modal-button" @click="$refs.exportPresentation.close()" type="button">No</button>
                <a @click="$refs.exportPresentation.close()" :href="API_URL + 'api/download-zip-presentation/' + presentation.presentationId" class="modal-button btn btn-primary">Yes</a>
              </div>
            </sweet-modal>
          </div>
        </div>
      </div>
    </div>
    <sweet-modal ref="deviceModal" title="Select Device">
      <form ref="form" class="form">
        <div class="form-label-group" style="">
          <select v-model="item.deviceId" id="deviceSelect" class="form-control" :placeholder="'User Type' | translate" required>
            <option v-for="(d, i) in devices" :key="i" :value="d.deviceId">{{d.name}}</option>
          </select>
          <label for="deviceSelect">{{'Device' | translate}}</label>
        </div>
      </form>
      <div class="dialog-buttons">
        <button class="btn btn-light" @click="$refs.deviceModal.close()" type="button">Cancel</button>
        <button class="btn btn-primary" @click="sendPresentation()" type="button">Send to Device</button>
      </div>
    </sweet-modal>



    <sweet-modal :blocking="true" @close="commandClose()" ref="commandModal" title="Set Command">
      <form ref="form" class="form">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" v-model="selectedSlide.loopMedia">
          <label class="form-check-label" for="inlineCheckbox1">Loop Media</label>
        </div>
        <div class="form-label-group">
          <span class="order-no">{{selectedSlide.orderNo}}-</span>
          <input :class="{'is-invalid': $v.selectedSlide.slideName.$error}" v-model="selectedSlide.slideName" type="text" id="mediaName" class="form-control" :placeholder="'Media name' | translate" required>
          <span class="order-no"> {{selectedSlide.extension}}</span>
          <label style="margin-left: 24px;" for="mediaName">{{'Media name' | translate}}</label>
        </div>
        <div class="form-label-group" style="">
          <select v-model="selectedSlide.command1Value" id="whenDone" class="form-control" :placeholder="'When Done' | translate">
            <option v-for="(d, i) in presentationItems1" :key="i" :value="d.orderNo">Play media entry {{d.orderNo}}</option>
          </select>
          <label for="whenDone">{{'When Done' | translate}}</label>
        </div>
        <div class="form-label-group" style="">
          <select v-model="selectedSlide.command2Value" id="onStart" class="form-control" :placeholder="'On media start, perform user action' | translate">
            <option v-for="(d) in deviceCommands" :key="d.orderNo" :value="d.orderNo">{{d.name}}</option>
          </select>
          <label for="onStart">{{'On media start, perform user action' | translate}}</label>
        </div>
        <div class="form-label-group" style="">
          <select v-model="selectedSlide.command3Value" id="onStop" class="form-control" :placeholder="'On media stop, perform user action' | translate">
            <option v-for="(d) in deviceCommands" :key="d.orderNo" :value="d.orderNo">{{d.name}}</option>
          </select>
          <label for="onStop">{{'On media stop, perform user action' | translate}}</label>
        </div>
      </form>
      <div class="dialog-buttons">
        <button class="btn btn-light" @click="$refs.commandModal.close()" type="button">Cancel</button>
        <button class="btn btn-primary" @click="saveCommands()" type="button">Save</button>
      </div>
    </sweet-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import MainNav from '@/components/common/MainNav'
import ContentList from '@/components/common/ContentList'
import ContentFilter from '@/components/common/ContentFilter'
import api from '@/api'
import config from '@/config'
import { EventBus } from '@/event'
import { required } from 'vuelidate/lib/validators'
export default {
  data () {
    return {
      disable: false,
      disableDownload: false,
      item: {},
      items: [],
      devices: [],
      deviceCommands: [],
      presentationSlides: [],
      presentation: {},
      selectedSlide: {},
      presentationItems1: [
        { orderNo: 1 },
        { orderNo: 2 },
        { orderNo: 3 },
        { orderNo: 4 },
        { orderNo: 5 },
        { orderNo: 6 },
        { orderNo: 7 },
        { orderNo: 8 },
        { orderNo: 9 },
        { orderNo: 10 },
        { orderNo: 11 },
        { orderNo: 12 },
        { orderNo: 13 },
        { orderNo: 14 },
        { orderNo: 15 },
        { orderNo: 16 }
      ],
      selectedDevice: {            
        name: '',
        address: '',
        username: '',
        password: '',
        deviceId: -1
      }
    }
  },
  validations: {
    selectedSlide: {
      slideName: {
        required
      }
    }
  },
  components: {
    MainNav,
    ContentList,
    ContentFilter
  },
  computed: {
    dragInProgress () {
      return this.$store.getters.dragInProgress
    },
    S3_URL () {
      return config.S3_URL
    },
    API_URL () {
      return config.API_URL
    }
  },
  methods: {
    commandClose () {
      this.selectedSlide.slideName = this.selectedSlide.slideName + this.selectedSlide.extension
    },
    confirmSendPresentation(){
      this.$refs.sendPresentation.open()
    },
    sendPresentation () {
      this.disable = true
      this.$refs.sendPresentation.close()
      api.presentation.exportPresentation(this.presentation.presentationId).then(() => {
        this.disable = false
        EventBus.$emit('notification', { message: 'Presentation sent.' })
      })
    },
    downloadPresentation () {
      this.disableDownload = true
      api.presentation.downloadPresentation(this.presentation.presentationId).then(() => {
        this.disableDownload = false
      })
    },
    openSlide (item) {
      this.selectedSlide = item
      item.extension = '.' + item.fileName.split('.').pop()
      this.selectedSlide.slideName = this.selectedSlide.slideName || item.fileName
      this.selectedSlide.slideName = this.selectedSlide.slideName.replace(item.extension, '')
      this.$refs.commandModal.open()
    },
    saveCommands () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.selectedSlide.slideName = this.selectedSlide.slideName + this.selectedSlide.extension
      api.presentation.upsertPresentationSlide(this.selectedSlide).then(() => {
        this.getPresentationSlides()
        this.$refs.commandModal.close()
      })
    },
    deviceChange () {
      api.presentation.upsertPresentation(this.presentation).then(result => {
        this.presentation = result.data || {}
        this.getCommands()
      }).catch(x=>{
      // eslint-disable-next-line
      console.log(x)
    })
    },
    getCommands () {
      api.device.getDeviceCommands(this.item && this.presentation.deviceId).then(result => {
        this.deviceCommands = result.data
      })
    },
    openDialog (device) {
      this.selectedDevice = device || {}
      this.$refs.deviceModal.open()
    },
    dragEnter(e) {
      e.currentTarget.classList.add('drag-enter')
    },
    dragLeave(e) {
      e.currentTarget.classList.remove('drag-enter')
    },
    // eslint-disable-next-line
    itemDrop (ev, item, items, index) {
      this.$store.commit('setDragInProgress', false)
      ev.currentTarget.classList.remove('drag-enter')
      ev.preventDefault()
      let data = JSON.parse(ev.dataTransfer.getData("text/plain"))
      item = {
        ...data,
        presentationId: this.presentation.presentationId,
        orderNo: item.orderNo
      }
      api.presentation.upsertPresentationSlide(item).then(() => {
        this.getPresentationSlides()
      })
      // let self = this
      //this.$store.dispatch('addVideoSlideAndGet', {slides: this.selectedVideo.videoSlides, slide: slide}).then(() => {
        //  this.$refs['croppieRef'+themeSlide.orderNo][0].bind({
        //   url: self.getTolSlide(slide.orderNo).userContentDataLink ? (self.API_URL + 'api/get-s3-file?file=' + self.getTolSlide(slide.orderNo).userContentDataLink) : self.emptyImage,
        //   points: self.getTolSlide(slide.orderNo).cropInfo.points
        // })
        // this.$refs['croppieRef'+themeSlide.orderNo][0].setZoom(0.15)
      // })
    },
    clearItems () {
      this.presentationItems1 = [
        { orderNo: 1 },
        { orderNo: 2 },
        { orderNo: 3 },
        { orderNo: 4 },
        { orderNo: 5 },
        { orderNo: 6 },
        { orderNo: 7 },
        { orderNo: 8 },
        { orderNo: 9 },
        { orderNo: 10 },
        { orderNo: 11 },
        { orderNo: 12 },
        { orderNo: 13 },
        { orderNo: 14 },
        { orderNo: 15 },
        { orderNo: 16 }
      ]
    },
    getPresentationSlides() {
      api.presentation.getSlidesForPresentation(this.presentation.presentationId).then(slides => {
        slides.data.map((x) => {
          let itemIndex = this.presentationItems1.findIndex(y => {
            return y.orderNo === x.orderNo
          })
          Vue.set(this.presentationItems1, itemIndex, x)
        })
      })
    },
    deletePresentationSlide (id, index, orderNo) {
      api.presentation.deletePresentationSlide(id).then(() => {
        Vue.set(this.presentationItems1, index, { orderNo: orderNo })
        this.getPresentationSlides()
      })
    }
  },
  mounted () {
    api.presentation.getPresentationForEvent(this.$route.params.id).then(result => {
      this.presentation = result.data || {}
      this.presentation.eventId = this.presentation.eventId || this.$route.params.id
      this.getPresentationSlides()
      this.getCommands()
    }).catch(x=>{
      // eslint-disable-next-line
      console.log(x)
    })
  },
  created () {
    api.device.getDeviceList().then(result => {
      this.devices = result.data
    })
  }
}
</script>
<style scoped>

.wrapper {
  width: 70%;
  margin: auto;
  margin-top: 24px;
  min-width: 1024px;
}

.drag-in-progress .video-slide-dropzone {
  border:1px dashed gray;
}
.drag-enter {
  border: 1px dashed rgb(0, 123, 255);
}
.theme-slide {
  display: inline-block;
  border: 1px solid lightgray;
  /* padding-top: 10px; */
  margin: 5px;
  position: relative;
  min-width: 140px;
  min-height: 120px;
  text-align: center;
  background-color: #f8f8f8;
  padding: 4px;
  cursor: pointer;
}
.theme-slide.video {
  background-color: green;
  color:#F1F1F1;
}
.theme-slide.audio {
  background-color: purple;
  color:#F1F1F1;
}
.theme-slide.image {
  background-color: orange;
}
.theme-slide img {
  max-width: 130px;
  max-height: 82px;
}
.theme-slide .frame {
  -moz-box-shadow: inset 0 0 5px #888;
  -webkit-box-shadow: inset 0 0 5px #888;
  box-shadow: inset 0 0 5px #888;
  vertical-align: middle;
}
.theme-slide .delete-icon {
  margin: 4px;
  z-index: 10000;
  right: 0px;
  top: 0;
}
.theme-slide:hover .command-icon{
  display: block;
}
.theme-slide .slide-order-no {
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin-right: 5px;
}
.device-title {
  width: 100%;
  text-align: center;
  margin-right: 64px;
}
.device-command-buttons {
  padding-top: 24px;
  text-align: center;
  width: 100%;
}
.device-command-buttons button {
  margin-left: 24px;
  margin-right: 24px;
}

.scrollable-container {
  max-height: calc(100vh - 150px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.device-tiles {
  padding-left: 50px;
  margin-top: 15px;
  min-width: 625px;
}
.dialog-buttons {
  text-align: center;
  margin-top: 24px;
  width: 100%;
}
.dialog-buttons button {
  margin-left: 15px;
  margin-right: 15px;
}
.filter-buttons-container {
  margin-bottom: 15px;
}
.tile-placeholder {
  margin-top: 36px;
  font-size: 24px;
}
.thumb-icon {
  margin-top: 12px;
}
.file-name {
  font-size: 12px;
  position: absolute;
  bottom: 0;
  max-width: 130px;
  left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 38px;
  line-height: 12px;
  padding-bottom: 4px;
}
.has-content {
  background-color: #fff;
}
.order-no {
  font-size: 18px;
  padding-right: 5px;
  padding-top: 14px;
}
</style>