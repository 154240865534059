import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'
import Registration from '@/components/Registration'
import Upload from '@/components/Upload'
import ForgotPassword from '@/components/user/ForgotPassword'
import ResetPassword from '@/components/user/ResetPassword'
import Register from '@/components/user/Register'
import DeviceList from '@/components/DeviceList'
import EventList from '@/components/event/EventList'
import UserList from '@/components/user/UserList'
import Presentation from '@/components/presentation/Presentation'
import Reports from '@/components/report/Reports'
import Share from '@/components/common/ShareContentList'
import Preview from '@/components/common/ShareContentPreview'
import PaymentPlans from '@/components/paymentPlan/PaymentPlans'
import InvoiceList from '@/components/invoice/InvoiceList'
import CustomerSettings from '@/components/customers/settings/CustomerSettings'
import SuccessPage from '@/components/customers/settings/SuccessPage'
import ErrorPage from '@/components/customers/settings/ErrorPage'

import PaymentMethodsList from '@/components/paymentMethods/PaymentMethodsList'
import CustomersList from '@/components/customers/CustomersList'
// import ClientForecast from '@/components/ClientForecast'
// import Preferences from '@/components/Preferences'
Vue.use(Router)

export default new Router({
  routes: [{
    path: '/',
    name: 'Home',
    redirect: 'Upload'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password/:key',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload
  },
  {
    path: '/devices',
    name: 'DeviceList',
    component: DeviceList
  },
  {
    path: '/events',
    name: 'Events',
    component: EventList
  },
  {
    path: '/users',
    name: 'Users',
    component: UserList
  },
  {
    path: '/presentation/:id',
    name: 'Presentation',
    component: Presentation
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/invitation/:key',
    name: 'Register',
    component: Register
  },
  {
    path: '/share/:guid',
    name: 'Share',
    component: Share
  },
  {
    path: '/view/:guid/:id',
    name: 'Preview',
    component: Preview
  },
  {
    path: '/payment-plans',
    name: 'PaymentPlan',
    component: PaymentPlans
  },
  {
    path: '/paymentMethodsList',
    name: 'Payment Methods List',
    component: PaymentMethodsList
  },
	{
    path: '/distributors',
    name: 'Companies List',
    component: CustomersList
  },
  {
    path: '/settings',
    name: 'Customer Settings',
    component: CustomerSettings
  },
  {
    path: '/successPage',
    name: 'SuccessPage',
    component: SuccessPage
  },
  {
    path: '/errorPage',
    name: 'ErrorPage',
    component: ErrorPage
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: InvoiceList
  },
	{
		path: '/registration/:paymentPlanId?',
		name: 'Registration',
		component : Registration
	}
]
})
