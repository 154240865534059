<template>
  <div>
    <form v-if="!savedPaymentMethod" @submit.prevent="savePaymentMethod" style="text-align:left">
      <label for="cardholderName">Name on card</label>
      <input type="text" ref="cardholderName" name="cardholderName" v-model="name" required/><br />
      <label for="cardEmail">Email</label>
      <input type="email" ref="cardEmail" name="cardEmail" v-model="currentUser.email"  required><br />
      <label for="BillingCountry">{{'Country' | translate}}</label>
      <select v-model="billingCountry" id="countryId" class="form-control" :placeholder="'Choose your country' | translate" required>
        <option v-for="country in countries" :key="country" :value="country">{{getCountryName(country)}}</option>
      </select> <br/>
      <div ref="card" id="card"></div>
      <button type="submit" :disabled="disabled">
        <span>Save card and make it default</span>
      </button>
      <br/>
      <div v-if="this.errorMsg" class="card-error" role="alert"> {{ this.errorMsg }} </div> 
    </form>
    <div v-if="isLoading" id="spinner"> </div>
    <div v-if="savedPaymentMethod" class="alert alert-success" role="alert">
        Card saved successfully
    </div>
    <button v-if ="savedPaymentMethod" @click="back"> OK </button>
  </div>
</template>

<script>
import api from '@/api'
import { loadStripe } from '@stripe/stripe-js';
const iso3311a2 = require('iso-3166-1-alpha-2')
import config from '@/config'
import util from '@/util'
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      savedPaymentMethod : false,
      disabled: true,
      customer: {},
      errorMsg:"",
      message: "",
      savedCardSuccess: false,
      card: {},
      clientSecret: "",
      isLoading: false,
      setupIntentId: null,
      email: "",
      name: "",
      billingCountry: "",
      countries: [],
      code : "",
    }
  },
  validations: {
    name: {
      required
    },
    email: {
      required
    },
    billingCountry: {
      required
    }
  },
  computed: {
    user () {
      return util.getSessionInfo()
    },
     currentCustomer() {
      return this.$store.getters.getCurrentCustomer
    },
    currentUser() {
      return this.$store.getters.getCurrentUser
    },
    plan() {
      return this.$store.getters.getSinglePaymentPlan
    },
  },
  mounted() {
    this.createCard();
  },
   created() {
    this.createCard();
    this.countries = iso3311a2.getCodes();
    if(!this.isEmptyObj(this.user)) {
      this.getCurrentCustomer();
      //this.$store.dispatch('getCustomerWithDefaultPaymentMethod')  
    }
  },
  methods: {
    isEmptyObj(object) {
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
            return false;
          }
      }
    return true;
    },
    back() {
      this.createCard();
      this.savedPaymentMethod = false;
      this.$emit('closeAddCardDialog')
    },
    getCurrentCustomer(){
      api.customers.getCurrentCustomer().then(response=> {
        this.customer = response.data;
        this.$store.dispatch('getSinglePaymentPlan',this.customer.paymentPlanId); 
        this.$store.dispatch('getCustomerWithDefaultPaymentMethod')
      })
    },
    subscribe() {
      sessionStorage.setItem('subscribe','paymentErrorPage');
      return util.subscribe();
    },
    createCard() {
      const style = {
        base: {
          color: "#32325d",
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
            "::placeholder": {
            color: "#32325d"
          }
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      }
      this.stripe = loadStripe(`${config.stripeApiKey}`)
        .then((result) => {
          this.elements = result.elements()
          this.card = this.elements.create('card', {style : style, hidePostalCode : true});
          this.card.mount(this.$refs.card);
          this.card.on("change", (event) => {
            if(!event.empty) {
              this.disabled = false;
            }else {
              this.disabled = true;
            }
          });
          this.confirmCardSetup = result.confirmCardSetup;
        })
      },
    async savePaymentMethod() { 
        this.isLoading = true;
        await api.paymentMethods.createSetupIntent().then(response => {
            if(response.data != null) {
              this.clientSecret = response.data.clientSecret;
            }            
          });
          this.confirmCardSetup(this.clientSecret, {
            payment_method: {
                  card: this.card,
                  billing_details: {
                    address: {
                        country: this.billingCountry,
                    },
                    email: this.currentUser.email,
                    name: this.name,
                },
                }
              
              }).then(response => {
                  if(response.error) {
                    this.isLoading = false;
                    this.disabled = true;
                    this.showError(response.error.message);
                  } else {
                    this.isLoading = false;
                    this.disabled = true;
                    this.setupCardComplete(response.setupIntent.id,response.setupIntent.payment_method);
                    this.setupIntentId = response.setupIntent.id;
                  }
              })
          this.isLoading = false;
          this.email = "";
          this.name="";
          this.billingCountry = "";		
    },
    getCountryName(country) {
      return iso3311a2.getCountry(country);
    },
    showError(errorMsgText) {
      this.errorMsg = errorMsgText;
    },
    setupCardComplete(setupIntentId, paymentMethod) {
      var postSendData = {                      
        stripeCustomerId: this.currentCustomer.stripeCustomerId,                   
        stripePaymentMethodId: paymentMethod                   
      };
      api.paymentMethods.makeDefault(postSendData).then(() => {
          this.$store.dispatch('getCustomerWithDefaultPaymentMethod') 
          this.$store.dispatch('getPaymentMethodsList') 
          this.savedPaymentMethod = true;

      })
    }, 
    payOneTime(){
      api.customerPaymentPlan.activateOneTime().then(()=>{
          this.getCurrentCustomer() 
          this.$router.push({ name: 'Events' })  
      })
    }
  }
}
</script>

<style scoped>
#card {
  color: "#32325d";
  -webkit-font-smoothing: "antialiased";
  font-family: 'Arial, sans-serif';
  font-size: "16px";
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

form {
  width: auto;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}
input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: 7%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}

#defaultCard {
  text-align: center;
  font-size: 20px;
}

</style>