<template>
  <div class="chooser">
    <div class="button-container">
      <button @click="saveSlides()" class="btn btn-primary save-button" type="button"><i class="fa fa-save"></i> {{'Add' | translate}}</button>
    </div>
    <div :class="{chooser: chooser}" class="stock-slides-container">
      <div v-if="themeStockSlidesRecommended.length">
        <h3>Recommended</h3>
        <hr/>
        <span 
          @click="toggle(item)"
          :class="[{selected: selectedUserContent.indexOf(item) !== -1}, item.userContentTypeName]"
          class="content-item"
          v-for="(item, $index) in themeStockSlidesRecommended"
          :key="$index"
          >
          <template>
            <!-- <i v-if="!chooser" @click="editVideo(item)" class="btn btn-light fa fa-tv command-icon edit-icon"></i>
            <i v-if="!chooser" @click="deleteItem(item)" class="btn btn-light fa fa-trash command-icon delete-icon"></i>
            <a v-if="!chooser" class="btn btn-light fa fa-download command-icon download-icon" :href="API_URL + 'api/get-user-content-data-download/' + item.userContentId"><i class=""></i></a> -->
            <div v-if="!item.thumbnailDataLink" class="rounded thumb-icon">
              <i class="fa fa-play video-icon fa-4x"></i>
            </div>
            <img v-if="item.thumbnailDataLink" style="max-height: 200px; margin: 5px" :src="S3_URL + item.thumbnailDataLink" class="rounded">
          </template>
        </span>
      </div>
      <div v-if="themeStockSlidesOther.length">
        <h3>Other</h3>
        <hr/>
        <span 
          @click="toggle(item)"
          :class="[{selected: selectedUserContent.indexOf(item) !== -1}, item.userContentTypeName]"
          class="content-item"
          v-for="(item, $index) in themeStockSlidesOther"
          :key="$index"
          >
          <template>
            <!-- <i v-if="!chooser" @click="editVideo(item)" class="btn btn-light fa fa-tv command-icon edit-icon"></i>
            <i v-if="!chooser" @click="deleteItem(item)" class="btn btn-light fa fa-trash command-icon delete-icon"></i>
            <a v-if="!chooser" class="btn btn-light fa fa-download command-icon download-icon" :href="API_URL + 'api/get-user-content-data-download/' + item.userContentId"><i class=""></i></a> -->
            <div v-if="!item.thumbnailDataLink" class="rounded thumb-icon">
              <i class="fa fa-play video-icon fa-4x"></i>
            </div>
            <img v-if="item.thumbnailDataLink" style="max-height: 200px; margin: 5px" :src="S3_URL + item.thumbnailDataLink" class="rounded">
          </template>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api'
import config from '@/config'
export default {
  data () {
    return {
      userContent: []
    }
  },
  props: {
    chooser: Boolean,
    single: Boolean,
    modalRef: Object
  },
  computed: {
    selectedUserContent () {
      return this.userContent
    },
    selectedVideo () {
      return this.$store.getters.selectedVideo
    },
    themeStockSlidesRecommended () {
      return this.$store.getters.themeStockSlides.filter(item => {
        return item.themeId === this.selectedVideo.themeId
      })
    },
    themeStockSlidesOther () {
      return this.$store.getters.themeStockSlides.filter(item => {
        return item.themeId !== this.selectedVideo.themeId
      })
    },
    API_URL () {
      return config.API_URL
    },
    S3_URL () {
      return config.S3_URL
    }
  },
  methods: {
    toggle (item) {
      let itemIndex = this.userContent.indexOf(item)
      if( itemIndex!== -1) {
        this.userContent.splice(itemIndex,1)
      } else {
        this.userContent.push(item)
      }
    },
    saveSlides () {
      let slides = []
      this.userContent.map(item => {
        slides.push({
        duration: item.slideTime,
        videoId: this.selectedVideo.videoId,
        userId: this.selectedVideo.userId,
        userContentId: item.userContentId
       })
      })
      if (slides.length !== 0) {
        api.video.addSlides(slides).then(()=> {
          this.$store.dispatch('getVideo', this.selectedVideo.videoId)
          this.userContent = []
        })
        this.modalRef.close()
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.content-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 2px;
  border: 2px solid transparent;
  margin: 5px;
}
.content-item.video .thumb-icon,
.content-item.audio .thumb-icon{
  border: 1px solid lightgray;
  width: 200px;
  height: 147px;
  padding: 2px;
  text-align: center;
  background-color: #fff;
}
.content-item.video .thumb-icon .video-icon,
.content-item.audio .thumb-icon .audio-icon {
  margin-top: 34px;
}
.chooser .content-item.selected {
  border: 2px solid #007bff;
  border-radius: 3px;
}
.content-item:hover .command-icon{
  display: block;
}

.delete-icon {
  top: 46px;
}
.download-icon {
  top: 82px;
}
.stock-slides-container {
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}
.save-button {
  float: right;
  clear: both;
}
.button-container {
  height: 46px;
}
</style>