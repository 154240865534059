<template>
    <div>
      <form ref="form" class="form" @submit.prevent="submitUpdateForm">
          <div class="form-label-group">
             <input :class="{ 'is-invalid': $v.updatedPlan.name.$error }"  id="updatedName" 
             type="text" class="form-control" placeholder="Enter Payment Plan Name" v-model.trim="updatedPlan.name" >
              <!-- <input :class="{'is-invalid': $v.item.mainText.$error}"  type="text" id="infoMainText" class="form-control" :placeholder="'Name of the Deceased *' | translate" required> -->
            <label for="paymentPlanName">{{'Payment Plan Name *' | translate}}</label>
          </div>
          <div class="form-label-group" v-if="paymentPlan.typeOfPlan!=3 && paymentPlan.typeOfPlan!=4">
            <select class="custom-select" required v-model="planType"  v-if="paymentPlan.typeOfPlan!=3">
              <option value="rgb(230,218,214)">Bronze</option>
              <option value="rgb(229,229,229)">Silver</option>
              <option value="rgb(232,228,205)">Gold</option>
              <option value="rgb(209,232,233)">Diamond</option>
            </select> 
            <label for="planType">{{'Plan Type' | translate}}</label>
          </div>
          <!-- <div class="buttons-row" style="margin:0px">
            <button type="submit" class="btn btn-primary modal-button">Update</button>
            <button class="btn btn-secondary modal-button" @click="closeDialog">Cancel</button>
          </div> -->
          <div class="buttons-row">
            <button type="button" @click="closeDialog" class="command btn btn-default">Cancel</button>
            <button type="submit" class="command btn btn-primary"><i class="fa fa-save"></i> Save</button>
          </div>
           
      </form>
    </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
export default {

    computed:{
        paymentPlan(){
            this.getInfo()
            return this.$store.getters.getSinglePaymentPlan
        },
    },
    data(){
        return{
            updatedPlan:{
                name:null,
                isHidden:false,
                displayColor:''
            },
            planType:''
        }
    },
    validations: {
        updatedPlan: {
            name: {
                required,
            },
        }
    },
    methods:{
        submitUpdateForm(){
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }     
            var formData={
                paymentPlanId:this.paymentPlan.paymentPlanId,
                name:this.updatedPlan.name,
                isHidden:this.updatedPlan.isHidden,
                displayColor:this.updatedPlan.displayColor
            }
            this.$emit('updatePlan',formData)         
        },
        closeDialog(){
            this.$emit('closeUpdateDialog');
        },
        updateTypeOfPlan(planColor){
            if(planColor=="rgb(209,232,233)"){
                this.updatedPlan.isHidden=true;
            }else{
                this.updatedPlan.isHidden=false;
            }
            this.updatedPlan.displayColor=planColor;
        },
        getInfo(){
            this.updatedPlan.name=this.$store.getters.getSinglePaymentPlan.name;
            this.planType=this.$store.getters.getSinglePaymentPlan.displayColor
        }
    },
    watch:{
        planType:{
             handler:function(){
                if(this.planType=="rgb(209,232,233)"){
                    this.updatedPlan.isHidden=true;
                }
                this.updatedPlan.displayColor=this.planType;
            }
        }
    }
}
</script>

<style scoped>
.command {
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
}
</style>