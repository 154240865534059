<template>
  <div>
    <MainNav></MainNav>
    <div class="wrapper">
     <ul class="nav nav-tabs">
        <li class="nav-item" @click="activeTab = 1">
            <a class="nav-link" :class="{'active': activeTab === 1}" href="#">Video Generation Summary</a>
        </li>
        <li class="nav-item" @click="activeTab = 2">
            <a class="nav-link" :class="{'active': activeTab === 2}"  href="#">Video Generation Details</a>
        </li>
        <li class="nav-item" @click="activeTab = 3">
            <a class="nav-link" :class="{'active': activeTab === 3}"  href="#">User Session</a>
        </li>
     </ul>
     <div v-if="activeTab === 1"><VideoGenrationSummary></VideoGenrationSummary></div>
     <div v-if="activeTab === 2"><VideoGenerationDetailed></VideoGenerationDetailed></div>
     <div v-if="activeTab === 3"><UserSessionDetailed></UserSessionDetailed></div>
  </div>
  </div>
</template>
<script>
import MainNav from '@/components/common/MainNav'
import VideoGenrationSummary from '@/components/report/VideoGenrationSummary'
import VideoGenerationDetailed from '@/components/report/VideoGenerationDetailed'
import UserSessionDetailed from '@/components/report/UserSession'
import config from '@/config'
import util from '@/util'
export default {
  // props: ['modalRef'],
  data () {
    return {
      // devices: [],
      activeTab: 1,
      selectedDevice: {            
        name: '',
        address: '',
        username: '',
        password: '',
        deviceId: -1
      },
      currentDeviceId: null
    }
  },
  components: {
    MainNav,
    VideoGenrationSummary,
    VideoGenerationDetailed,
    UserSessionDetailed
  },
  computed: {
    isUploading () {
      return this.uploading
    },
    devices () {
      return this.$store.getters.deviceList.map(item => {
        item.options = {
          target: `${config.API_URL}api/upload-device-config/${item.deviceId}`,
          testChunks: false,
          chunkSize: 100000000,
          headers: {
            Authorization: `Bearer ${util.getSessionData().access_token}`
          }
        }
        // this.$refs['uploader' + item.deviceId].uploader.on('uploadStart', () => {
        //   // eslint-disable-next-line
        //   this.uploading = true
        // })
        // this.$refs['uploader' + item.deviceId].uploader.on('complete', () => {
        //   // eslint-disable-next-line
        //   this.uploading = false
        //   // eslint-disable-next-line
        //   this.$refs.uploadInfo.open()
        // })
        return item
      })
    }
  },
  methods: {
    getCurrentDeviceId () {
      return this.currentDeviceId
    },
    openDialog (device) {
      this.selectedDevice = device
      this.$refs.deviceModal.open()
    }
  },
  created () {
    this.$store.dispatch('getDeviceList')
  }
}
</script>
<style scoped>
.command-column {
  width: 360px;
}
.command {
  margin-left: 10px;
  margin-right: 10px;
}
.data-table {
  margin-top: 30px;
}
.wrapper {
  width: 70%;
  margin: auto;
  margin-top: 20px;
  min-width: 1024px;
}

.add-command {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.upload-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  cursor: pointer;
}
label.upload-button {
  margin-bottom: 0;
}
.upload-button:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.uploader-example {
  display: inline;
}
</style>