<template>
  <div class="tab-pane" id="profile">
    <br>
    <h6>YOUR PROFILE INFORMATION</h6>
    <hr>
    <form>
      <div class="form-group">
        <label for="fullName">Full Name</label>
        <input disabled="true" type="text" class="form-control" id="fullName" aria-describedby="fullNameHelp" placeholder="Enter your fullname" :value="seasonInfo.fullName">
        <small id="fullNameHelp" class="form-text text-muted">Your name may appear around here where you are mentioned. In next version you will be able to change it.</small>
      </div>
      <div class="form-group">
              <label for="url">Email</label>
              <input disabled="true" type="text" class="form-control" id="url" placeholder="Enter your email address" :value="seasonInfo.email">
      </div>
      <div class="form-group">
        <label for="location">Company</label>
        <input disabled="true" type="text" class="form-control" id="location" placeholder="Enter Conpany Name" :value="seasonInfo.customerName" >
      </div>
      <div class="form-group small text-muted">
        Data update will be available in next versions.
      </div>
      <button disabled="true" type="button" class="btn btn-primary">Update Profile</button>
      <button disabled="true" type="reset" class="btn btn-light">Reset Changes</button>
    </form>
  </div> 
</template>

<script>
import util from '@/util'
export default {
    computed:{
          seasonInfo(){
      return util.getSessionInfo()
    },
    }
}
</script>