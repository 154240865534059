<template>
<header>
  <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
    <a class="navbar-brand" href="#/upload"><img src="/small-logo.png" class="logo"/>E-Funeral</a>
    <div class="dropdown create-button show-on-mobile">
          <!-- <button class="btn btn-light main-button dropdown-toggle" type="button" id="userMenuDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> -->
            <i class="dropdown-toggle user-icon fa-2x fa fa-user" id="userMenuDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
          <!-- </button> -->
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userMenuDropdownMenuButton">
            <a class="dropdown-item" @click.prevent="logout()" href="#"><i class="fa fa-power-off"></i> {{'Logout' | translate}}</a>
            <div class="dropdown-divider"></div>
            <LangChooser></LangChooser>
          
          </div>
        </div>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto main-menu-links">
        <router-link 
          to="/distributors"
          v-slot="{href, isActive}"
          v-if="[5].indexOf(user.userTypeId) !== -1">
          <li :class="{'active': isActive}" class="nav-item">
            <a class="nav-link" :href="href">Companies</a>
          </li>
        </router-link>
        <router-link 
          to="/devices"
          v-slot="{href, isActive}"
          v-if="[5,4].indexOf(user.userTypeId) !== -1"
        >
          <li :class="{'active': isActive}" class="nav-item">
            <a class="nav-link" :href="href">Devices</a>
          </li>
        </router-link>
        <router-link 
          to="/events"
          v-slot="{href, isActive}"
          v-if="[5,4,3].indexOf(user.userTypeId) !== -1"
        >
          <li :class="{'active': isActive}" class="nav-item">
            <a class="nav-link" :href="href">Funerals</a>
          </li>
        </router-link>
        <router-link 
          to="/invoices"
          v-slot="{href, isActive}"
          v-if="[5].indexOf(user.userTypeId) !== -1"
        >
          <li :class="{'active': isActive}" class="nav-item">
            <a class="nav-link" :href="href">Invoices</a>
          </li>
        </router-link>
        <router-link 
          to="/users"
          v-slot="{href, isActive}"
          v-if="[5,4].indexOf(user.userTypeId) !== -1"
        >
          <li :class="{'active': isActive}" class="nav-item">
            <a class="nav-link" :href="href">Users</a>
          </li>
        </router-link>
        <router-link 
          to="/reports"
          v-slot="{href, isActive}"
          v-if="[5].indexOf(user.userTypeId) !== -1"
        >
          <li :class="{'active': isActive}" class="nav-item">
            <a class="nav-link" :href="href">Reports</a>
          </li>
        </router-link>
        <router-link 
            to="/payment-plans"
            v-slot="{href, isActive}"
            v-if="[5].indexOf(user.userTypeId) !== -1"
        >
            <li :class="{'active': isActive}" class="nav-item">
              <a class="nav-link" :href="href">Payment Plans</a>
            </li>
          </router-link>

        <li class="time-left" title="Please finish your uploads before the deadline. You will not be able to change the content after the deadline is expired."
          v-if="[1,2].indexOf(user.userTypeId) !== -1 && timeLeft && !deadlineExpired">Content deadline {{timeLeft}} ({{timeLeftDate}})</li>
        <li class="time-left" 
          v-if="[1,2].indexOf(user.userTypeId) !== -1 && timeLeft && deadlineExpired">Content deadline expired.</li>
      </ul> 
      <form class="form-inline mt-2 mt-md-0">
        <!-- <LangChooser></LangChooser>
        <button class="btn btn-outline-danger my-2 my-sm-0" type="submit">Logout</button> -->
        
        <div class="dropdown create-button">
          <!-- <button class="btn btn-light main-button dropdown-toggle" type="button" id="userMenuDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> -->
        <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="user-email dropdown-toggle">{{user.email}}
            <i class=" user-icon fa-2x fa fa-user" id="userMenuDropdownMenuButton" ></i>
            </span>
          <!-- </button> -->
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userMenuDropdownMenuButton">
            <a v-if="[3,4].indexOf(user.userTypeId) !== -1" class="dropdown-item" @click.prevent="settings()" href="#"><i class="fa fa-cog"></i> {{'Settings' | translate}}</a>          
            <a v-if="[1,2,3,4,5].indexOf(user.userTypeId) !== -1" class="dropdown-item" href="#" @click.prevent="$refs.shareModal.open()" ><i class="fa fa-share-alt"></i> {{'Share' | translate}}</a> 
            <a v-if="[5].indexOf(user.userTypeId) !== -1" class="dropdown-item" href="#" @click.prevent="$refs.AddLoginMessage.open()" ><i class="fa fa-info-circle"></i> {{'Login message' | translate}}</a>
            <a class="dropdown-item" @click.prevent="logout()" href="#"><i class="fa fa-power-off"></i> {{'Logout' | translate}}</a>
            <div class="dropdown-divider"></div>
            <LangChooser></LangChooser>
          </div>
        </div>
      </form>
    </div>
  </nav>
    <sweet-modal ref="shareModal" icon="info" title="Info">Shareable link: <br/><span class="share-link">{{shareContentLink}} </span> <br/>
      <!-- <button class="btn btn-primary modal-button" @click="copyLink()" type="button">Copy</button> -->
      <button class="btn btn-primary modal-button" @click="$refs.shareModal.close()" type="button">Close</button>
    </sweet-modal>
    <sweet-modal :blocking="true" title="Update login message" ref="AddLoginMessage">
      <LoginMessageForm @closeLoginMessageForm="closeLoginMessageForm" @saveLoginMessage="saveLoginMessage"/>
    </sweet-modal>
</header>
</template>
<script>
import LangChooser from '@/components/common/LangChooser'
import LoginMessageForm from '@/components/loginMessage/LoginMessageForm'
import util from '@/util'
import api from '@/api'
import moment from 'moment'
export default {
  components:{
    LangChooser,
    LoginMessageForm
  },
  // data () {
  //   return {
  //     timeInfoLocal: null
  //   }
  // },
  computed: {
    deadlineExpired () {
      return this.user.eventInfo && this.user.eventInfo.eventDate && moment(this.user.eventInfo.eventDate) < moment(new Date())
    },
    user () {
      return util.getSessionInfo()
    },
    timeLeftDate () {
      return this.user.eventInfo && this.user.eventInfo.eventDate && moment(this.user.eventInfo.eventDate).format('YYYY-MM-DD HH:mm')
    },
    timeLeft () {
      return this.user.eventInfo && this.user.eventInfo.eventDate && moment(this.user.eventInfo.eventDate).fromNow() // ('YYYY-MM-DD HH:mm')
    },
    shareContentLink() {
      if (this.user.userTypeId >= 3) {
        return document.location.href.split('#')[0] + '#/share/' + sessionStorage.shareLink; 
      }
      return document.location.href.split('#')[0] + '#/share/' + this.user.shareContentLink; 
    }    
  },
  mounted () {
    // let self = this
    // setInterval(() => {
    //   self.timeInfoLocal = self.$store.getters.eventInfo && self.$store.getters.eventInfo.eventDate && moment(self.$store.getters.eventInfo.eventDate).fromNow()
    // },5000)
  },
  methods: {
    copyLink () {
      navigator.clipboard.writeText(this.shareContentLink).then(() => {
        //console.log('Async: Copying to clipboard was successful!')
      }, () => {
        // console.error('Could not copy text: ', err)
      });
      this.$refs.shareModal.close()
    },
    logout () {
      delete sessionStorage.sessionInfo
      delete sessionStorage.sessionData
      delete sessionStorage.userContext
      delete sessionStorage.userInfo
      delete sessionStorage.userContext
      this.$store.commit('setUserContentList', [])
      this.$router.push({name: 'Login'})
    },
    settings(){
       this.$router.push({name: 'Customer Settings'})
    },
    saveLoginMessage(formData){
        api.loginMessage.updateLoginMessage(formData).then(()=>{
          this.$refs.AddLoginMessage.close()
        })
    },
    closeLoginMessageForm(){
      this.$refs.AddLoginMessage.close()
    }
  }
}
</script>
<style scoped>
.logo {
  height: 32px;
  margin-right: 12px;
}
header {
  height: 56px;
}
.lang-link {
  color: gray;
  margin: 10px;
  font-size: 14px;
}
.lang-link.active{
  font-weight: bold;
  color: #007bff;
}
.user-icon{
  color: gray;
  cursor: pointer;
}
.main-menu-links {
  margin-right: 36px;
}
.time-left {
  color: white;
}
.user-email {
  color:gray;
  cursor: pointer;
}
.share-link{
  font-weight: bold;
}

</style>
