<template>
    <div>
        <div class="StatusAndCusomerField mt-4">
          <div class="col-md-3 ">
            <select class="custom-select" required v-model="filter.Status">
              <option value="">All</option>
              <option value="paid">Paid</option>
              <option value="draft">Draft</option>
              <option value="void">Void</option>
              <option value="uncollectible">Uncollectible</option>
            </select>
          </div>
          <div class="col-md-3" v-if="isAdmin">
            <select class="custom-select" required @change="changeCustomer($event)">
              <option value="">All</option>
              <option v-for="(customer, $index) in customers" :key="$index" :value="customer.customerId">{{customer.customerName}}</option>
            </select>
          </div>
      </div>
      <hr>
      <table v-if="!invoiceLoading" id="dtBasicExample" class="data-table table table-bordered" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th style="width: 250px;">Company</th>
            <th style="width: 190px;">Total</th>
            <th style="width: 190px;">Status</th>
            <th>Attachment</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, $index) in invoices.data" :key="$index">
            <td>{{item.customer_name}}</td> 
            <td>
                <span v-if="item.currency === 'eur'">{{item.total / 100}} €</span>
                <span v-if="item.status === 'usd'">{{item.total / 100}} $</span>
            </td>        
            <td style="text-align:center;vertical-align:middle;">
              <span v-if="item.status === 'open'" class="command btn btn-info btn-sm">Open</span>
              <span v-if="item.status === 'paid'" class="command btn btn-success btn-sm">Paid</span>    
              <span v-if="item.status === 'draft'" class="command btn btn-danger btn-sm">Draft</span>
              <span v-if="item.status === 'void'" class=" command btn btn-primary btn-sm">Void</span>
              <span v-if="item.status === 'uncollectible'" class="command btn btn-secondary btn-sm">Uncollectible</span>                
            </td>
            <td class="command-column" style="text-align:center;vertical-align:middle;">
              <a class="command btn btn-primary btn-sm" v-if="item.paid" :href="item.invoice_pdf">Download</a>
              <span v-if="!item.paid">Please complete your payment to see attachment</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center" v-else>
          <div class="spinner-border" style="width: 5rem; height: 5rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
      </div>
      <table v-if="!invoices" cellspacing="0" width="100%">
        <tr>
          <td>
            <div class="alert alert-primary" role="alert">
              There are no invoices to show.
            </div>
          </td>
        </tr>
      </table>
      <hr>
      <div class="pagination mb-3">
        <div class="col-md-3">
          <select class="custom-select" required v-model="filter.PageSize" @change="resetPageBeforeAfer">
            <option value="10">Show: 10</option>
            <option value="15">Show: 15</option>
            <option value="20">Show: 20</option>
            <option value="25">Show: 25</option>
          </select>
        </div>
          <nav aria-label="...">
            <ul class="pagination ml-2">
              <button :disabled="pageCounter==1?true:false" class="btn btn-primary mr-2" @click="prevPage()"><i class="fa fa-arrow-left" aria-hidden="true"/> Previous </button>
              <button class="btn btn-primary ml-2" @click="nextPage()" >Next <i class="fa fa-arrow-right" aria-hidden="true"/></button>                   
            </ul>
          </nav>
      </div>
    </div>
</template>

<script>
import util from '@/util'
export default {
  computed :{
    invoiceLoading(){
      return this.$store.getters.getInvoiceLoading
    },
    isAdmin(){
      return util.getSessionInfo().userTypeId===5?true:false
    },
    invoices () {
      return this.$store.getters.getAllInvoices
    },
    customers(){
      return this.$store.getters.getCustomersList
    }
  },
    data(){
        return{
            filter:{
                PageSize:10,
                Status:"",
                customerId:"",
                EndingBefore:null,
                StartingAfter:null,
            },
            pageCounter:1,
            beforeMemory:null,
        }
    },
    mounted(){
        this.getAllInvoices();
        this.$store.dispatch('getCustomerList')
    },
    methods:{
      resetPageBeforeAfer(){
        this.filter.EndingBefore=null;
        this.filter.StartingAfter=null;
        this.pageCounter=1;
        this.beforeMemory=null;
      },
      changeCustomer(e){
        this.filter.customerId=e.target.value;
        this.getAllInvoices();
      },
      nextPage(){
        this.filter.StartingAfter=this.invoices.StartingAfter;
        this.filter.EndingBefore=null;
        this.beforeMemory=this.invoices.EndingBefore;
        this.pageCounter++;
        this.getAllInvoices();
      },
      prevPage(){
        if(this.invoices.EndingBefore==null){
          this.filter.EndingBefore=this.beforeMemory;
        }else{
          this.filter.EndingBefore=this.invoices.EndingBefore;
        }
        this.filter.StartingAfter=null;
        this.pageCounter--;
        this.getAllInvoices();
      },
      getAllInvoices(){
        if(this.isAdmin){
        this.$store.dispatch('getAllInvoices', this.filter);
        }else{
          var formData={
              PageSize:this.filter.PageSize,
              Status:this.filter.Status,
              EndingBefore:this.filter.EndingBefore,
              StartingAfter:this.filter.StartingAfter,
          }
        this.$store.dispatch('getCustomerInvoice',formData)
        }
      }
    },
    watch:{
      'filter.PageSize':{
        deep:true,
        handler:function(){
          this.getAllInvoices();                      
        }
      },
      'filter.Status':{
        deep:true,
        handler:function(){
          this.getAllInvoices();                      
        }
      },
    }
}
</script>
<style scoped>
.command-column {
  width: 340px;
}
.command {
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
}
.data-table {
  margin-top: 30px;
}
.wrapper {
  width: 70%;
  margin: auto;
  min-width: 1024px;
}

.add-command {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.btn-sm {
  width: 95px;
}
.sortable th {
  cursor: pointer;
}
.StatusAndCusomerField{  
    display: flex;
    justify-content:space-between;
    align-items: center;
}
.pagination{
    display: flex;
    justify-content:space-between;
    align-items: center;
}
/*td 
{
     text-align: left; 
    vertical-align: middle;
    font-size: 16px;
    font-weight: 550; 
}
*/
th 
{
    /* text-align: center; 
    vertical-align: middle;
    font-size: 16px;
    font-weight: 550; */
    min-width: 15px;

}
</style>

