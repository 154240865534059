<template>
  <div>
    
    <div class="container">
      <div class="row">
        <div class="col-sm-3">
           <div class="form-label-group">
            <select v-model="filter.isPreview" class="form-control" placeholder="Is Preview">
              <option value="true">Preview</option>
              <option value="false">Full Size</option>
            </select>
            <label for="isPreview">{{'Video Size' | translate}}</label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-label-group">
            <select v-model="filter.videoType" class="form-control" placeholder="Video Type">
              <option value="AE">Tree Of Life</option>
              <option value="REGULAR">Regular</option>
            </select>
            <label for="videoType">{{'Video Type' | translate}}</label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-label-group">
            <select v-model="filter.userId" class="form-control" placeholder="User">
              <option key="null" value="null">--- all ---</option> 
              <option v-for="user in users" :key="user.userId" :value="user.userId">{{user.fullName}}</option>
            </select>
            <label for="user">{{'User' | translate}}</label>
          </div>
        </div>
        <div class="col-sm-3">
           <div class="form-label-group">
            <select v-model="filter.customerId" class="form-control" placeholder="Company">
              <option key="null" value="null">--- all ---</option> 
              <option v-for="d in customers" :key="d.customerId" :value="d.customerId">{{d.customerName}}</option>  
            </select>
            <label for="Company">{{'Company' | translate}}</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
            <div class="form-label-group">
              <v-date-picker v-model="filter.dateFrom"
                :popover="{ placement: 'top', visibility: 'click' }" 
                
                class="date-picker"
                 :placeholder="'Date From' | translate"
                />
              <!-- <input :class="{'is-invalid': $v.item.subtitle.$error}" v-model="item.eventDate" type="text" id="infoFuneralDate" class="form-control" :placeholder="'Funeral Date *' | translate" required> -->
              <label for="DateFrom">{{'Date From' | translate}}</label>
            </div>
        </div>
         <div class="col-sm-3">
            <div class="form-label-group">
              <v-date-picker v-model="filter.dateTo"
                :popover="{ placement: 'top', visibility: 'click' }" 
                
                class="date-picker"
                 :placeholder="'Date To' | translate"
                />
              <!-- <input :class="{'is-invalid': $v.item.subtitle.$error}" v-model="item.eventDate" type="text" id="infoFuneralDate" class="form-control" :placeholder="'Funeral Date *' | translate" required> -->
              <label for="DateTo">{{'Date To' | translate}}</label>
            </div>
        </div>
        <div class="col-sm-3">
           <div class="form-label-group">
            <select id="groupBy" v-model="filter.groupBy" class="form-control" placeholder="Group by">
              <option value="User">User</option>
              <option value="Company">Company</option>
              <option value="Type">Type</option>
              <option value="VideoType">Video Type</option>

            </select>
            <label for="groupBy">{{'Group By' | translate}}</label>
          </div>
        </div>
        <div class="col-sm-3 buttons">
          <button @click="clearFilter()" type="button" class="command btn btn-default">Clear</button>
          <button @click="getReportData()" type="button" class="command btn btn-primary">Load</button>
        </div>
      </div>
    </div>
    <!--{{list}} -->
      <table id="dtBasicExample" class="data-table table table-bordered sortable" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th @click="sort('keyString')" class="th-sm" id="GroupName">{{this.filter.groupBy}}
              <i v-if="sortField !== 'count'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('count')" class="th-sm">Count
              <i v-if="sortField === 'count'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, $index) in list" >
          <tr :key="$index+1">
            <td>{{item.keyString}}</td>
            <td>{{item.count}}</td>            
          </tr>
          </template>
        </tbody>
      </table>
    </div>
</template>
<script>
import api from '@/api'
export default {
  // props: ['modalRef'],
  data () {
    return {
      // devices: [],
      filter: this.getDefaultFilter(),
      users: [],
      customers: [],
      list: [],
      order: '',
      sortField: ''
    }
  },
  components: {},
  computed: { 
    rptGenSummarySort () {
      return localStorage.rptGenSummarySort
    },
    rptGenSummaryOrder () {
      return localStorage.rptGenSummaryOrder
    }
  },
  methods: {
    sort (field) {
      localStorage.rptGenSummarySort = field
      localStorage.rptGenSummaryOrder = localStorage.rptGenSummaryOrder === 'desc' ? 'asc' : 'desc'
      this.order = localStorage.rptGenSummaryOrder
      this.sortField = localStorage.rptGenSummarySort
       this.getReportData()
    },
    clearFilter () {
      this.filter = this.getDefaultFilter()
    },
    getDefaultFilter () {
      return {            
        isPreview: 'false',
        videoType: 'REGULAR',
        userId: null,
        customerId: null,
        dateFrom: null,
        dateTo: null,
        groupBy: 'User',
        order: 'asc',
        sort: 'keyString',
        page: 1,
        pageSize: 10000
      }
    },
    getReportData () {
      
      this.filter.order = localStorage.rptGenSummaryOrder || 'desc'
      this.filter.sort = localStorage.rptGenSummarySort || 'keyString'
      if (this.filter.userId === "null"){
        this.filter.userId = null
      }
      if (this.filter.customerId === "null"){
        this.filter.customerId = null
      }
      api.reports.getVideoGenerationSummaryList(this.filter).then(result => {
        let data = result.data.data.map(item => {
          return item
        })
        this.list = data
      })
    }    
  },
  mounted () {
    api.users.getUserList().then(result => {
      this.users = result.data; 
    })
	let filter = {
        page: 1,
        pageSize: 1000
    }
    api.customers.getCustomerList({ params: filter }).then(result => {
      this.customers = result.data.data
    })
  }
}
</script>
<style scoped>
.data-table {
  margin-top: 30px;
}
.wrapper {
  width: 70%;
  margin: auto;
  min-width: 1024px;
}
.buttons {
  text-align: right;
}
.buttons button {
    margin-top: 20px;
    margin-left: 20px;
}
.container {
  margin-top: 16px;
}
.sortable th {
  cursor: pointer;
}
</style>