<template>
  <div>
    <MainNav></MainNav>
    <div class="wrapper">
      <button type="button" @click="openEventDialog({})" class="add-command command btn btn-success"><i class="fa fa-plus"></i> Add Funeral</button>
      <table id="dtBasicExample" class="data-table table table-bordered sortable" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th @click="sort('mainText')" class="th-sm" style="min-width: 150px">Name
             <i v-if="sortField === 'mainText'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('eventDate')"  style="width: 100px;" class="th-sm">Deadline
              <i v-if="sortField === 'eventDate'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('fullName')"  class="th-sm">Manager
              <i v-if="sortField === 'fullName'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th v-if="user.userTypeId === 5" @click="sort('customerName')"  class="th-sm">Company
              <i v-if="sortField === 'customerName'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('actionTimestamp')"  style="width: 90px;" class="th-sm">Updated
              <i v-if="sortField === 'actionTimestamp'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('verified')"  style="width: 80px;">Status
              <i v-if="sortField === 'verified'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <!-- <th style="width: 80px;" >Status
              <i v-if="sortField === 'verified'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th style="width: 80px;">Pay
              <i v-if="sortField === 'verified'" class="fa" :class="'fa-sort-' + order"></i>
            </th> -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, $index) in events" :key="$index">
            <td>{{item.mainText}}</td>
            <td>{{item.eventDate | formatDate}}</td>
            <td>{{item.fullName}}</td>
            <td v-if="user.userTypeId === 5">{{item.customerName}}</td>
            <td>{{item.actionTimestamp | formatDate}}</td>
            <td style="text-align:center">
              <span v-if="!item.verified" class="badge badge-info">New</span>
              <span v-if="item.verified" class="badge badge-success">Registered</span>
            </td>
            <!-- <td class="center">
              <span v-if="!item.Paid" class="badge badge-info">Not Paid </span>
              <span v-if="item.Paid" class="badge badge-success">Paid</span>
            </td> -->
            <!-- <td>
              <button v-if="!item.Paid && fromSubscribe" class="btn btn-success rounded-lg confirm ml-2" type="button" @click="payWithSubModal(item.eventId)">Pay From Subsription</button>
              <button v-if="!item.Paid && fromSubscribeOneTime" class="btn btn-success rounded-lg confirm ml-2" type="button" @click="payOneTime(item.eventId)">Pay Single Funeral</button>
              <button v-if="!item.Paid && fromOneTime" class="btn btn-success rounded-lg confirm ml-2" type="button" @click="payOneTime(item.eventId)">Pay One Time</button>
            </td>      -->
            <td class="command-column">
              <button type="button" @click="openEventDialog(item)" class="command btn btn-primary btn-sm">Details</button>
              <button type="button" @click="viewContent(item)" class="command btn btn-primary btn-sm">Content</button>
              <button type="button" @click="openPresentation(item)" class="command btn btn-primary btn-sm">Presentation</button>
              <!-- <button type="button" @click="openInviteDialog(item)" class="command btn btn-primary btn-sm" :disabled="item.verified || !item.Paid">Invite</button> -->
              <button type="button" @click="openInviteDialog(item)" class="command btn btn-primary btn-sm" :disabled="item.verified">Invite</button>
              <button type="button" @click="setUserState(item)" :class="{'btn-success': item.enabled, 'btn-danger': !item.enabled}" class="command btn btn-sm">{{item.enabled ? 'Deactivate' : 'Activate'}}</button>
              <button type="button" @click="deleteEvent(item)" class="command btn btn-primary btn-sm btn-danger" v-if="user.userTypeId === 5" >Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
      <table v-if="!events.length" cellspacing="0" width="100%">
        <tr>
          <td>
            <div class="alert alert-primary" role="alert">
              There are no funerals to show.
            </div>
          </td>
        </tr>
      </table>
    </div>
    <sweet-modal :blocking="true" title="Funeral Details" ref="eventModal">
      <EventDialog :modal-ref="$refs.eventModal" :item="selectedItem" @getCurrentCustomer="getCurrentCustomer"></EventDialog>
    </sweet-modal>
    <sweet-modal :blocking="true" ref="inviteModal" :icon="selectedItem.email !== 'N/A' ? 'info' : 'warning'" title="Info">
      <div v-if="selectedItem.email !== 'N/A'">Invitation will be sent to {{selectedItem.email}}. <br/>Optionally, send the following link to the customer:<br/> {{documentUrl}}#/invitation/{{selectedItem.registrationKey}}<br/></div>
      <div v-if="selectedItem.email === 'N/A'">Please assing an e-mail to the Family Member in the Funeral Details</div>    
      <button v-if="selectedItem.email !== 'N/A'" class="btn btn-light modal-button" @click="$refs.inviteModal.close()" type="button">Cancel</button>
      <button v-if="selectedItem.email !== 'N/A'" class="btn btn-primary modal-button" @click="inviteUser()" type="button">Send</button>

      <button v-if="selectedItem.email === 'N/A'" class="btn btn-light modal-button" @click="$refs.inviteModal.close()" type="button">OK</button>
    </sweet-modal>
    <sweet-modal ref="confirmDelete" icon="warning">
      You are about to delete this funeral and all its content and users. Are you sure?
      <div>
        <button class="btn btn-light modal-button" @click="$refs.confirmDelete.close()" type="button">Cancel</button>
        <button class="btn btn-danger modal-button" @click="confirmDelete()" type="button">Delete</button>
      </div>
    </sweet-modal>
    <sweet-modal ref="inviteModalEmailMissing" icon="warning">
      Please assign a Family Member Email in the funeral Details.
      <div>
        <button class="btn btn-light modal-button" @click="$refs.inviteModalEmailMissing.close()" type="button">OK</button>
      </div>
    </sweet-modal>
        <sweet-modal ref="errorModal" icon="error">
      <div>
        <h2 class="modal-title">Payment Plan Missing</h2>
      </div>
      <div class="modal-body">
        <hr>
        <h4>Please activate your payment plan so you can proceed.</h4>
        <hr>
        <h6>You can activate your plan in settings tab on right corner user icon.</h6>
      </div>
    </sweet-modal>
    <!-- <sweet-modal :blocking="true" ref="payWithSubModal" icon="warrning">
      <div>
        <h2 class="modal-title">Are You Sure?</h2>
      </div>
      <div class="modal-body">
        <hr>
        <h4>This funeral will be paid from the currently active subscription!</h4>
        <hr>
        <h6>You can check the number of funerals spent in settings tab on right corner user icon.</h6>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-success btn-lg" data-dismiss="modal" @click="payWithSub()">Yes</button>
          <button type="button" class="btn btn-danger btn-lg" data-dismiss="modal" @click="closeSubDialog()">No</button>
      </div>      
    </sweet-modal> -->
    <sweet-modal  :blocking="true" ref="nextPaymentPlanModal"> 
      <NextPaymentPlanModal :betterPaymentPlan="betterPaymentPlan" @closeNextPaymentPlanDialog="closeNextPaymentPlanDialog" @nextPaymentPlan="nextPaymentPlan" @continuePaymentPlan="continuePaymentPlan"/>
    </sweet-modal>
    <sweet-modal :blocking="true" ref="managerPaymentPlanModal" title="Contact your company">
        <ManagerPaymentPlanModal :message="message" :distributors="distributors" :distributorsLength="distributorsLength" :distributor="distributor"/>
      </sweet-modal>
    <sweet-modal :blocking="true" ref="payPerEventDialog" icon="warning" hide-close-button>
      <div>
        <h2 class="modal-title">There is no better payment plan for you</h2>
      </div>
      <div class="modal-body"> 
          You spent all the funerals from your payment plan. Contact the company to create a Diamond payment plan for you.
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-danger btn-lg" data-dismiss="modal" @click="$refs.payPerEventDialog.close()">Close</button>
      </div> 
    </sweet-modal>
  </div>
</template>
<script>
import MainNav from '@/components/common/MainNav'
import EventDialog from '@/components/event/EventDialog'
import NextPaymentPlanModal from '@/components/paymentPlan/NextPaymentPlanModal'
import ManagerPaymentPlanModal from '@/components/common/ManagerPaymentPlanModal'
import api from '@/api'
import util from '@/util'
// import config from "@/config";
// const stripe = window.Stripe(config.stripeApiKey)
export default {
  computed :{
    isAvalable(){
      if(util.getSessionInfo().userTypeId===5 || this.paymentPlan.typeOfPlan == 4){
        return true
      }else if(this.customer.paymentPlanId!=null && this.customer.startDatePaymentPlan!=null){
        return true
      }else{
        return false
      }
    },
    eventSort () {
      return localStorage.eventSort
    },
    eventOrder () {
      return localStorage.eventOrder
    },
    events () {
      return this.$store.state.eventList
    },
    documentUrl (){     
      return document.location.href.split('#')[0]; 
    },
    paymentPlan(){
       return this.$store.getters.getSinglePaymentPlan
    },
    user () {
      return util.getSessionInfo()
    },
    fromSubscribe(){
      if((this.paymentPlan.typeOfPlan == 1 || this.paymentPlan.typeOfPlan == 2)
        && (this.customer.startDatePaymentPlan != null && this.customer.expirationDatePaymentPlan != null)
        && (this.customer.currentEventCountPaymentPlan  < this.paymentPlan.numberOfEvents)){
        return true
      }else{
        return false;
      }
    },
    fromSubscribeOneTime(){
      if((this.paymentPlan.typeOfPlan == 1 || this.paymentPlan.typeOfPlan == 2)
        && (this.customer.startDatePaymentPlan != null && this.customer.expirationDatePaymentPlan != null)
        && (this.customer.currentEventCountPaymentPlan  >= this.paymentPlan.numberOfEvents)){
        return true
      }else{
        return false;
      }
    },
    fromOneTime(){
      if (this.paymentPlan.isDeleted == false 
        && (this.paymentPlan.typeOfPlan == 3)
        && this.customer.startDatePaymentPlan != null){
           return true
         }else{
           return false
         }
    },
  },
  data () {
    return {
      selectedItem: {},
      order: '',
      sortField: '',
      customer:{},
      currentEventId:null,
      betterPaymentPlan: {},
      distributorsLength:0,
      distributor: {},
      message: "You spent all the funerals from your payment plan. Please contact your company to upgrade the payment plan or renew the current one.",
      distributors: [],
    }
  },
  components: {
    MainNav,
    ManagerPaymentPlanModal,
    EventDialog,
    NextPaymentPlanModal
  },
  created(){
    this.getCurrentCustomer()
    this.$store.dispatch('getEventList')
  },
  methods: {
    subscribe(){
      sessionStorage.setItem('subscribe', 'errorPage');
      sessionStorage.setItem('events', 'true');
      return util.subscribe()
      },
    nextPaymentPlan() {
      if(this.paymentPlan.typeOfPlan === 1 || this.paymentPlan.typeOfPlan === 2) {
        api.customerPaymentPlan.deactivateAndUnsubscribe().then(response => {
          this.customer = response.data;
          this.customer.paymentPlanId = this.betterPaymentPlan.paymentPlanId;
            api.customers.updateCustomer(this.customer).then(()=>{
              this.subscribe();
          })
        })
      }
    },
    continuePaymentPlan() {
     if(this.paymentPlan.typeOfPlan === 1 || this.paymentPlan.typeOfPlan === 2) {
        api.customerPaymentPlan.deactivateAndUnsubscribe().then(response => {
          this.customer = response.data;
          this.customer.paymentPlanId = this.paymentPlan.paymentPlanId;
            api.customers.updateCustomer(this.customer).then(()=>{
              this.subscribe();
          })
        })
      }
    },
    isEmptyObj(object) {
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
            return false;
          }
      }
    return true;
    },
    getCurrentCustomer(){
      api.customers.getCurrentCustomer().then(response=>{
      this.customer=response.data;
      this.$store.dispatch('getSinglePaymentPlan',this.customer.paymentPlanId);
      })
    },
    sort (field) {
      localStorage.eventSort = field
      localStorage.eventOrder = localStorage.eventOrder === 'desc' ? 'asc' : 'desc'
      this.order = localStorage.eventOrder
      this.sortField = localStorage.eventSort
      this.$store.dispatch('getEventList')
    },
    inviteUser () {
      this.selectedItem.language = localStorage.lang || 'en'
      api.users.sendInvite(this.selectedItem).then(() => {
        this.$refs.inviteModal.close()
      })
    },
    /*payWithSubModal(value){
      this.currentEventId=value;
      this.$refs.payWithSubModal.open();
    },
    */
    closeNextPaymentPlanDialog() {
      this.$refs.nextPaymentPlanModal.close();
    },
    /*closeSubDialog(){
      this.$refs.payWithSubModal.close();
    },
    payWithSub(){
      api.event.payEventWithSubscription(this.currentEventId).then(()=>{
        this.$store.dispatch('getEventList')
        this.getCurrentCustomer();
        this.$refs.payWithSubModal.close();
      })
    }
    payOneTime(value){

      let formData={
        urlSuccess:`${window.location.protocol}//${window.location.host}/#/successPage?sessionId={CHECKOUT_SESSION_ID}`,
        urlCancel:`${window.location.protocol}//${window.location.host}/#/errorPage`
        }
        fetch(`${config.API_URL}api/payment/start-payment-event`,{
          method:'POST',
          mode:'cors',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${util.getSessionData().access_token}`,           
          },
            body:JSON.stringify(formData)
          }).then(response=>{
            return response.json()
          }).then(session=>{
            sessionStorage.setItem('sessionId', session.id);
            sessionStorage.setItem('eventId', value);
            return stripe.redirectToCheckout({ sessionId: session.id });
          }).then(result=>{
            if(result.error){
              alert(result.error.message)
            }

          })
    } */
    openInviteDialog (item) {
      if (item.email) {
        this.selectedItem = item
        this.$refs.inviteModal.open()
      } else {
        this.$refs.inviteModalEmailMissing.open()
      }
    },
    openEventDialog (item) {
      if(this.user.userTypeId != 5 && this.user.userTypeId != 3 && this.customer.currentEventCountPaymentPlan === this.customer.paymentPlan.numberOfEvents && this.isEmptyObj(item)) {
        api.paymentPlan.getBetterPaymentPlan().then(response => {
          if(response.data === null) {
            this.$refs.payPerEventDialog.open();
          }else {
            this.betterPaymentPlan = response.data;
            this.$refs.nextPaymentPlanModal.open();
          }
        })
      }else if(this.user.userTypeId == 3 && this.customer.currentEventCountPaymentPlan === this.customer.paymentPlan.numberOfEvents) {
        this.getDistributorsForCompany();
      }
      else if(this.isAvalable){
      this.selectedItem = item
      this.selectedItem.email = this.selectedItem.email === 'N/A' ? '' : this.selectedItem.email
      
      this.$refs.eventModal.open()
      }else{
      this.$refs.errorModal.open()
      }
    },
    async viewContent (item) {
      // eslint-disable-next-line
      sessionStorage.shareLink = item.shareContentLink
      util.setUserContext(item.userId)
      await this.$store.dispatch('getUserEvent', item.userId)
      // sessionStorage.userContext = item.userId || 0
      await this.$store.dispatch('getUserContentList').then(() => {
        this.$router.push({ name: 'Upload' })
      })
    },
    openPresentation (item) {
      // eslint-disable-next-line
      util.setUserContext(item.userId)
      this.$store.dispatch('getUserContentList').then(() => {
        this.$router.push({ name: 'Presentation', params: { id: item.eventId }})
      })
    },
    setUserState (item) {
      api.users.setUserState(item.userId, !item.enabled).then(() => {
        this.$store.dispatch('getEventList')
      })
    },
    deleteEvent(item){
      this.eventId = item.eventId
      this.$refs.confirmDelete.open()
    },
    confirmDelete () {
      this.$refs.confirmDelete.close()
      api.event.deleteEvent(this.eventId).then((result) => {
        if(result.data) {
          //  this.errorMessage = result.data
          //  this.$refs.deleteError.open()
        } else {
          this.$store.dispatch('getEventList')
        }
      })
    },
     getDistributorsForCompany() {
       api.users.getDistributorsForCompany().then(response => {
        this.distributorsLength = response.data.length;
        if(this.distributorsLength === 1) {
          this.distributor = response.data[0];
        }else {
          this.distributors = response.data
        }
      })
      this.$refs.managerPaymentPlanModal.open();
    },
  }
}
</script>
<style scoped>
.command-column {
  width: 340px;
}
.command {
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
}
.data-table {
  margin-top: 30px;
}
.wrapper {
  width: 70%;
  margin: auto;
  min-width: 1024px;
}

.add-command {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.btn-sm {
  width: 95px;
}
.sortable th {
  cursor: pointer;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.card {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}

.card {
    /* position: relative; */
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    margin-right: -20px;
    margin-left: -20px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 20px;
    padding-left: 20px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
.shadow-none {
    box-shadow: none!important;
}

.card{
    border-radius: 7%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.card-title{
  color: white;
  font-size: 100%;

}

.title-field {
  word-break: break-all;
  position: relative;
  display: flex;
  text-align: center;
  border-bottom: 1px dotted black;
  justify-content: center;
  border-radius: 10%;
  align-items: center;
  box-shadow: 6px 6px 5px grey;
}

.title-field .tooltiptext {
  visibility: hidden;
  width: 190px;
  background-color: #fff;
  color: black;
  text-align: center;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}


.title-field:hover .tooltiptext {
  visibility: visible;
}
</style>
