export default {
  error (state) {
    return state.error
  },
  infoMsg (state) {
    return state.infoMsg
  },
  userContentList (state) {
    return (filter, chooser) => {
      if (filter && filter === 'images') {
        return state.userContentList.filter((item) => {
          return item.userContentTypeId === 2
        })
      }
      if (filter && filter === 'music') {
        return state.userContentList.filter((item) => {
          return item.userContentTypeId === 3
        })
      }
      return state.userContentList.filter((item) => {
        // eslint-disable-next-line
        return !chooser || chooser && item.userContentDataLink && item.userContentTypeId !== 3 && item.userContentTypeId !== 4  
      })
    }
  },
  folderList (state) {
    return state.folderList
  },
  getSelectedFolderId (state) {
    return state.selectedFolderId
  },
  getOnlySelectedFolderId (state) {
    if(state.selectedFolderId === -1) {
      return ""
    }
    else {
      return ("/" + state.selectedFolderId)
    }
  },
  themesList (state) {
    return state.themesList
  },
  stockMusic (state) {
    return state.stockMusic
  },
  themeStockSlides (state) {
    return state.themeStockSlides
  },
  themeSlides (state) {
    return state.themeSlides
  },
  contentFilter (state) {
    return state.contentFilter
  },
  selectedUserContent (state) {
    return state.selectedUserContent
  },
  coverPhotoContentId (state) {
    return state.coverPhotoContentId
  },
  selectedVideo (state) {
    return state.selectedVideo
  },
  selectedTheme (state) {
    return state.selectedTheme
  },
  exportStatus (state) {
    return state.exportStatus
  },
  dragInProgress (state) {
    return state.dragInProgress
  },
  eventList (state) {
    return state.eventList
  },
  userList (state) {
    return state.userList
  },
  deviceList (state) {
    return state.deviceList
  },
  eventInfo (state) {
    return state.eventInfo
  },
  getVideoStates (state) {
    return state.videoStates
  },
  getAllPaymnetPlans(state){
    return state.paymentPlans
  },
  getPaymentPlansForCustomers(state) {
    return state.paymentPlansForCustomers
  },
  getSinglePaymentPlan(state){
    return state.paymentPlan
  },
  getExternalMusic(state){
    return state.externalMusic
  },
	getCurrentUser(state) {
		return state.currentUser
	},
  isLoading(state){
    return state.isLoading;
  },
  isUrlLoading(state){
    return state.isUrlLoading;
  },
  externalRecordUrl(state){
    return state.externalRecordUrl;
  },
	getCustomerWithDefaultPaymentMethod(state) {
		return state.customerWithDefaultPaymentMethod
	},
	getPaymentMethodsList(state) {
		return state.paymentMethodsList
	},
	getCouponsList(state) {
		return state.couponList
	},
	getCurrentCustomer(state) {
		return state.customer
	},
	getCustomersList(state) {
		return state.customerList
	},
  getAllInvoices (state) {
    return state.invoiceList
  },
  getInvoiceLoading(state){
    return state.invoiceLoading
  }
}
