<template>
  <form ref="form" class="form" @submit.prevent="submitLoginMessage">
    <div class="form-label-group" style="margin-top: 48px;">
          <textarea maxlength="1000" v-model="newLoginMessage.message" class="form-control" :placeholder="'Enter login message' | translate" autofocus></textarea>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" v-model="newLoginMessage.active" id="isActive" >
      <label class="form-check-label" for="includeInfo">Show login message</label>
    </div>
    <div class="buttons-row">
      <button type="button" @click="closeLoginMessageForm" class="command btn btn-default">Cancel</button>
      <button type="submit" class="command btn btn-primary"><i class="fa fa-save"></i> Save</button>
    </div>
  </form>
</template>
<script>

import { required } from "vuelidate/lib/validators"
import api from '@/api'

export default {
    computed:{
        rules(){
            return {message:{required}}
        },
    },
    created(){
    api.loginMessage.getLoginMessage().then(response => {
      this.newLoginMessage.message = response.data.message
      this.newLoginMessage.active = response.data.active
    })
  },
    data(){
        return{
            newLoginMessage:{
                message:"",
                active: false
            },
        }
    },
    validations() {
        return{
          newLoginMessage:this.rules
        }
    },
    methods:{
      closeLoginMessageForm(){
        this.$emit('closeLoginMessageForm');
      },
      submitLoginMessage(){ 
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }       
            var formData={
                message:this.newLoginMessage.message,
                active:this.newLoginMessage.active
            }
            this.$emit('saveLoginMessage',formData);
            this.setToStartValues();
        },
        setToStartValues(){
                //TODO: Get from backend
                this.message="",
                this.active=false
        }
    }
}
</script>
<style scoped>
.form-label-group >>>.vue-swatches__trigger  {
  height: 38px !important;
  width: 38px !important;
  margin-left: 12px;
  border: 1px solid lightgray;
}
.sub-form-group {
  position: relative;
  /* text-align: left; */
  /* padding-left: 10px; */
  /* margin-top: 20px; */
  width: 33.33%; 
}
.sub-form-group label {
  position: absolute;
  top: 0;
  left: 11px;
}
.sub-form-group select {
  height: 46px;
}
.sub-form-group input {
  height: 46px;
}
.date-picker {
  width: 50%;
}
.picker-label {
  font-size: 12px;
  color: #777;
}
</style>
