<template>
      <div class="wrapper">
          <MainNav> </MainNav>
            <table class="data-table table table-bordered">
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Payment Plan</th>
                  <th> Set Payment plan</th>
                  <th> Payment plan start date</th>
                  <th> Discount </th>
                  <th> Apply discount </th>
                </tr>
              </thead>
              <tbody v-for="(customer, $index) in customersList" :key="$index" >
                  <CustomerItem :customer="customer" />
              </tbody>
            </table>
      </div>
</template>

<script>
import MainNav from '@/components/common/MainNav'
import CustomerItem from '@/components/customers/CustomerItem'
export default {
  data () {
    return {
      customer : {},
      coupon: ""
    }
  },
  components: {
    MainNav,
    CustomerItem
  },
  computed: {
    customersList() {
      return this.$store.getters.getCustomersList
    },
    couponsList() {
      return this.$store.getters.getCouponsList
    },
  },
  mounted() {
    this.$store.dispatch('getCustomerList')
    this.$store.dispatch('getCouponsList')
  },
}
</script>

<style scoped>
.command-column {
  width: 100px;
}
.command {
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
}
.data-table {
  margin-top: 30px;
}
.wrapper {
  width: 70%;
  margin: auto;
  min-width: 1024px;
}

.add-command {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.btn-sm {
  width: 95px;
}

th {
  min-width: 35px;
}

</style>