<template>
  <div>
    <MainNav></MainNav>
      <div class="wrapper">
        <Invoices />
      </div>  
  </div>
</template>
<script>
import MainNav from '@/components/common/MainNav';
import Invoices from './Invoices'
export default {
    components: {
        MainNav,
        Invoices
    },   
}
</script>
<style scoped>
.command-column {
  width: 340px;
}

.wrapper {
  width: 70%;
  margin: auto;
  min-width: 1024px;
}

</style>

