import { render, staticRenderFns } from "./ContentFilter.vue?vue&type=template&id=35cf5587&scoped=true&"
import script from "./ContentFilter.vue?vue&type=script&lang=js&"
export * from "./ContentFilter.vue?vue&type=script&lang=js&"
import style0 from "./ContentFilter.vue?vue&type=style&index=0&id=35cf5587&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35cf5587",
  null
  
)

export default component.exports