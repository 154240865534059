<template>
    <div class="form col-12" style="margin: 16px;">
        <form>
          <div class="form-label-group">
            <select :class="{'is-invalid': $v.device.userId.$error}" v-model="device.userId" id="parentUserId" class="form-control" :placeholder="'Associated User' | translate" required>
              <option v-for="d in distributors" :key="d.userId" :value="d.userId">{{d.fullName}}</option>
            </select>
            <label for="parentUserId">{{'Associated User' | translate}}</label>
          </div>
          <div class="form-label-group">
              <input :class="{'is-invalid': $v.device.name.$error}" v-model="device.name" type="text" id="name" class="form-control" :placeholder="'Name of the device *' | translate" required>
              <label for="inputName">{{'Name of the device *' | translate}}</label>                
          </div>
          <div class="form-label-group">
              <input :class="{'is-invalid': $v.device.address.$error}" v-model="device.address" type="text" id="address" class="form-control" :placeholder="'Address of the device *' | translate" required>
              <label for="inputName">{{'Address of the device *' | translate}}</label>              
          </div>
          <div class="form-label-group">
              <input v-model="device.username" type="text" id="username" class="form-control" :placeholder="'Username for accessing the device' | translate">
              <label for="inputName">{{'Username for accessing the device' | translate}}</label>                
          </div>
          <div class="form-label-group">
              <input v-model="device.password" type="text" id="password" class="form-control" :placeholder="'Password for accessing the device' | translate">
              <label for="inputName">{{'Password for accessing the device' | translate}}</label>                
          </div>
        </form>
        <button @click="saveDevice()" class="save-button btn btn-primary save-button" type="button"><i class="fa fa-save"></i> {{'Save' | translate}}</button>
    </div>
</template>

<script>
import api from '@/api'
import { required } from 'vuelidate/lib/validators'
export default {
  props: {
    device: Object,
    modalRef: Object
  },
  data () {
    return {
      distributors: []
    } 
  },
  validations: {
    device: {
      userId: {
        required
      },
      name: {
        required
      },
      address: {
        required
      }
    }
  },
  mounted () {
    api.users.getDistributorList().then(result => {
      this.distributors = result.data
    })
  },
  methods: {
    saveDevice(){
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      if (this.device.deviceId) {
        api.device.update(this.device).then(() => {
        this.$store.dispatch('getDeviceList')
          this.modalRef.close()
        })
      } else {
        api.device.add(this.device).then(() => {
          this.$store.dispatch('getDeviceList')
          this.modalRef.close()
        })
      }
    }
  }
}

</script>
<style scoped>
.save-button {
  float: right;
}
</style>