<template>
  <div>
    <sweet-modal @close="loadUserContent()" class="video-window" ref="modal" width="100%" style="height:calc(100vh - 56px)">
      <form-wizard ref="wizard" class="wizard" color="#007bff" title="" subtitle="" @on-change="onTabChange" @on-complete="onComplete" finish-button-text="Complete">
        <tab-content class="" title="Info" :before-change="validateInfo" icon="fa fa-info">
          <form ref="form" class="form">
            <div class="form-label-group" style="margin-top: 16px;">
              <input :class="{'is-invalid': $v.selectedVideo.name.$error}" @keydown="preventJsonSpecialCharacters($event)" id="infoVideoName" v-model="selectedVideo.name" class="form-control" :placeholder="'Video Name' | translate" autofocus />
              <label for="infoVideoName">{{'Video Name *' | translate}}</label>
            </div>
            <div class="form-label-group" style="margin-top: 16px;">
              <input id="infoTitle" @keydown="preventJsonSpecialCharacters($event)" v-model="selectedVideo.introductoryText" class="form-control" :placeholder="'Title' | translate" />
              <label for="infoTitle">{{'Title' | translate}}</label>
            </div>
            <div class="form-label-group">
              <input :class="{'is-invalid': $v.selectedVideo.title.$error}" @keydown="preventJsonSpecialCharacters($event)" v-model="selectedVideo.title" type="text" id="infoName" class="form-control" :placeholder="'Name of the Deceased *' | translate" required>
              <label for="infoName">{{'Name of the Deceased *' | translate}}</label>
            </div>
            <div class="form-label-group">
              <input :class="{'is-invalid': $v.selectedVideo.subtitle.$error}" @keydown="preventJsonSpecialCharacters($event)" v-model="selectedVideo.subtitle" type="text" id="infoBornDied" class="form-control" :placeholder="'Born - Died *' | translate" required>
              <label for="info  BornDied">{{'Born - Died *' | translate}}</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model="selectedVideo.includeInfo" id="includeInfo" value="Include Info">
              <label class="form-check-label" for="includeInfo">Include Info in Themed Video</label>
            </div>
            <br/><em>Info is always displayed in Tree of Life videos.</em>
          </form>
        </tab-content>

        <tab-content class="scrollable-container" title="Theme" :before-change="validateTheme" icon="fa fa-paint-brush">
          
          <span v-for="(item, $index) in themes" :key="$index" class="img-box" style="cursor:pointer" @click="selectTheme(item)" :class="{active: theme.themeId === item.themeId}">
            <img :src="S3_URL + item.thumbnailUrl" alt="..." class="img-thumbnail"/>
            <br/>
            {{item.themeName}}
            <br/>
            <span class="num-of-images" v-if="item.themeType === 'AE'">({{item.numberOfImages}} Images)</span>
          </span>
        </tab-content>
        <tab-content title="Slides" icon="fa fa-image"
        :before-change="validateTol">
          <template v-if="selectedVideo.typeId === 2">
            
            <div class="row">
              <div class="col-6 scrollable-container">
                <ContentList additionalFilter="images" :chooser="false" :readonly="true"></ContentList>
              </div>
              <div class="col-6 scrollable-container">
                <TolSlides :key="currentTabCalc"></TolSlides>
                <!-- <div
                  @dragover.prevent @dragenter="dragEnter"  @dragleave="dragLeave" @drop="tolDrop($event, slide)" 
                  v-for="(slide, $index) in themeSlides" :key="$index" 
                  class="rounded theme-slide video-slide-dropzone"
                  >
                  <i v-show="getTolSlide(slide.orderNo).thumbnailDataLink" @click="deleteVideoSlide('croppieRef' + slide.orderNo, getTolSlide(slide.orderNo))" class="btn btn-light fa fa-trash command-icon delete-icon"></i>
                  <vue-croppie 
                        :key="$index"
                        :enableRotation="true"
                        :ref="'croppieRef' + slide.orderNo" 
                        :enableOrientation="false"
                        :enableResize="false"
                        :boundary="{ width: slide.width / 10, height: slide.height / 10}"
                        :viewport="{ width: slide.width / 10, height: slide.height / 10, type: 'square'}"
                        :maxZoom="0.7"
                        @update="(val) => {cropUpdate(val, slide)}"
                        >
                    </vue-croppie>
                  </div> -->
                  <!-- <div class="rounded theme-slide" v-for="(slide, $index) in themeSlides" :key="$index">
                    <div @dragover.prevent @dragenter="dragEnter"  @dragleave="dragLeave" @drop="tolDrop($event, slide)" class="frame" :style="'width:' + slide.width / 10 + 'px; height: ' + slide.height / 10 + 'px;'+ 'line-height: ' + slide.height / 10 + 'px;'">
                      <img class="slide-image" :style="'max-width:' + slide.width / 10 + 'px; max-height: ' + slide.height / 10 + 'px;'" :src="getTolSlide(slide.orderNo).thumbnailDataLink ? (S3_URL + getTolSlide(slide.orderNo).thumbnailDataLink) : emptyImage"  />
                    </div>
                    <div class="slide-order-no">{{slide.orderNo}}</div>
                    <i v-show="getTolSlide(slide.orderNo).thumbnailDataLink" @click="deleteVideoSlide(getTolSlide(slide.orderNo))" class="btn btn-light fa fa-trash command-icon delete-icon"></i>
                  </div> -->
                  
              </div>
            </div>
            <!-- <div class="alert alert-warning" role="alert">
              For this theme you need to select <b>{{selectedVideo.theme.numberOfImages}}</b> photos.
            </div>
            <button @click="openContentList()" data-v-44fc689e="" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-light main-button dropdown-toggle"><i data-v-44fc689e="" class="fa fa-plus"></i> Add Photos</button> -->
          </template>
          <template v-if="selectedVideo.typeId !== 2">
            <button @click="openContentList()" type="button" class="btn btn-light main-button"><i class="fa fa-plus"></i> Add Slides</button>
            <button @click="openTextSlideDialog()" type="button"  class="btn btn-light main-button"><i class="fa fa-plus"></i> Add Text</button>
            <button @click="$refs.stockVideoModal.open()" type="button" class="btn btn-light main-button"><i class="fa fa-plus"></i> Add Stock Clips</button>
          </template>
            <div v-if="selectedVideo.typeId !== 2">
              <draggable 
                :list="selectedVideo.videoSlides"
                class="list-group"
                ghost-class="ghost"
                @end="dragEnd()" >
              <span v-for="(slide, $index) in selectedVideo.videoSlides" :key="$index" class="img-box">
                <img v-if="slide.thumbnailDataLink" :src="S3_URL + slide.thumbnailDataLink" alt="..." class="img-thumbnail"/>
                <div v-if="slide.userContentTypeId === 1 && !slide.thumbnailDataLink" alt="..." class="img-thumbnail">
                  <i class="fa fa-play fa-4x video-icon"></i>

                </div>
                <i @click="deleteSlide(slide)" class="btn btn-light fa fa-trash command-icon delete-icon"></i>
              </span>
              <div v-if="!selectedVideo.videoSlides.length" class="alert alert-primary" role="alert">
                There is no slides yet. Please add some slides.
              </div>
              </draggable>
            </div>
        </tab-content>
        <tab-content title="Music" icon="fa fa-music" :before-change="validateMusic">
          <template>
            <button @click="openMusicContentList()" type="button" class="btn btn-light main-button musicButton"><i class="fa fa-plus"></i> Add Music</button>
           <!-- <button @click="$refs.stockMusicContentModal.open()" type="button" class="btn btn-light main-button musicButton"><i class="fa fa-plus"></i> Add Stock Music</button> -->
            <button  @click="openExternalMusicDialog()" type="button" class="btn btn-light main-button musicButton"><i class="fa fa-plus"></i> Add External Music</button>

            <!-- Used to calculate theme video duration -->
            <video style="display:none" @loadedmetadata="updateThemeVideoDuration()" v-if="selectedVideo && selectedVideo.theme"
              :src="selectedVideo.theme.introductionVideo == null ? S3_URL + 'b3bd1a9d-b992-4803-8faa-134f6f4a06ca.mp4' : S3_URL + selectedVideo.theme.introductionVideo"
              :id="selectedVideo.theme.introductionVideo"></video>
            <!-- ----------------- -->
            <!-- <div v-if="!musicSlides.length" class="alert alert-primary" role="alert">
              Please upload some music files first.
            </div>
            <div v-if="musicSlides.length" class="alert alert-primary" role="alert">
              Add music to the video by clicking the <i class="fa fa-plus"></i> next to the song name.
            </div> -->
            <table v-if="musicSlides.length" class="table audio-table">
              
              <tbody>
                <tr v-for="(item, $index) in musicSlides" :key="$index">
                  <td>
                    <button v-if="item.videoSlideId" @click="removeMusicSlide(item)" type="button" class="btn btn-default"><i class="fa fa-trash"></i> </button>  
                  </td>
                  <td>{{item.fileName}}</td>
                  <td>
                    <audio
                        :id="item.userContentDataLink"
                        :ref="item.userContentDataLink"
                        controls controlsList="nodownload"
                        :src="S3_URL + item.userContentDataLink">
                            Your browser does not support the
                            <code>audio</code> element.
                    </audio>
                    <!-- <i @click="deleteItem(item)" class="btn btn-light fa fa-trash audio-command-icon"></i> -->
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="!musicSlides.length" class="alert alert-primary" role="alert">
              There is no music to show. Please add music.
            </div>
            <template v-if="selectedVideo.typeId !== 2">
            <div v-if="secondsPerSlide >= 6" class="alert alert-light" role="alert">
              Seconds per slide: {{secondsPerSlide}}
            </div>
            <div v-if="secondsPerSlide < 6 && musicSlides.length" class="alert alert-danger" role="alert">
              Music is too short for this video. Seconds per slide: {{secondsPerSlide}}
            </div>
            <div v-if="secondsPerSlide > 0 && (secondsPerSlide < 6 || secondsPerSlide > 12)" class="alert alert-warning" role="alert">
              Recommended slide duration is between <b>6</b> and <b>12</b> seconds. Video should have <b>{{maxSlides}}</b> slides for this choice of music.
            </div>
            </template>
          </template>
          <template v-if="selectedVideo.typeId === 2 && musicSlides.length">
            <div class="alert alert-primary" role="alert">
              <span v-if="musicDuration < selectedVideo.theme.audioFileLength"> Music is too short, it will start to loop after {{musicDuration}} seconds.</span>
              <span v-if="musicDuration > selectedVideo.theme.audioFileLength">
              Music will fade out after {{selectedVideo.theme.audioFileLength - 1}} seconds ({{(selectedVideo.theme.audioFileLength - 1) | formatSsToHhmmss}} min).
              </span>
            </div>
          </template> 
          <!-- <template v-if="selectedVideo.typeId === 2">
            <div class="alert alert-primary" role="alert">
              This theme has peredefined music.
            </div>
          </template> -->
        </tab-content>
        <tab-content title="Preview" icon="fa fa-play">
          <template v-if="selectedVideo.typeId === 2">
            <div v-if="!videoState.previewInProgress && videoState.previewDataLink"><em>Please note that preview is in lower quality than the final video.</em></div>
            <video v-if="!videoState.previewInProgress && videoState.previewDataLink" style="width: 720px;" :src="S3_URL + videoState.previewDataLink" controls></video>
            <div v-if="videoState.previewInProgress" class="alert alert-light" role="alert">
              <div class="progress">
                <div class="progress-bar bg-success progress-bar-striped" role="progressbar" :style="'width: ' + (videoState.previewProgressValue || 0) + '%'" :aria-valuenow="videoState.previewProgressValue || 0" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <!-- <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div> -->
              <br/>
              Video is being generated, this can take several minutes.<br/>
              You can close this window (X) and continue to work. The preview will be shown on the main screen when finished.
            </div>
            <br/>
            <button v-if="!videoState.previewInProgress" @click="generateTol()" type="button" class="btn btn-default">Generate Preview</button>
          </template>
          <template v-if="selectedVideo.typeId !== 2">
            <div v-if="!videoState.previewInProgress && videoState.previewDataLink"><em>NOTE: This is a low quality preview.</em></div>
            <video width="720px" ref="videoPreviewPlayer" v-if="videoState.previewDataLink && !videoState.previewInProgress || videoState.status === 'completed' && videoState.previewDataLink" :src="S3_URL + (videoState.previewDataLink || videoState.previewDataLink)" controls></video>
            <div v-if="videoState.status === 'completed' && !videoState.previewDataLink && videoState.previewInProgress">
              Loading Video...
            </div>
            <div v-if="videoState.status !== 'completed' && videoState.previewInProgress">
              <!--<span v-if="videoState.progress"> Generating preview: {{(videoState.progress || 0).toFixed(0)}}% </span>
              <span v-if="!videoState.progress"> Generating preview: Please wait... </span>-->
              <div class="progress">
                <div class="progress-bar bg-success progress-bar-striped" role="progressbar" :style="'width: ' + (videoState.progress || 0) + '%'" :aria-valuenow="videoState.progress || 0" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              Video is being generated, this can take several minutes.<br/>
              You can close this window (X) and continue to work. The preview will be shown on the main screen when finished.
            </div>
            <br/>
            <button v-if="!videoState.previewInProgress" @click="generatePreview()" type="button" class="btn btn-default">Generate Preview</button>
          </template>
          <!-- {{exportStatus}} -->
        </tab-content>
        <tab-content title="Complete" icon="fa fa-check">
          <div class="alert alert-warning" role="alert">
            Video is about to be submitted. After this step you will no longer be able to change it.
          </div>
        </tab-content>
      </form-wizard>
    </sweet-modal>
    <sweet-modal title="Select Files" ref="contentModal" width="80%" @close="selectNone()">
      <div class="modal-commands-header">
        <div class="filter-buttons-container">
          <ContentFilter :chooser="true"></ContentFilter>
        </div>
        <div class="modal-commands">
          <button @click="selectAll()" class="btn btn-light" type="button"><i class="fa fa-check"></i> Select All</button>
          <button @click="selectNone()" class="btn btn-light" type="button"><i class="fa fa-ban"></i> Select None</button>
          <button @click="saveSlides()" class="btn btn-primary" type="button"><i class="fa fa-pencil"></i> Ok</button>
        </div>
      </div>
      <ContentList :chooser="true"></ContentList>
    </sweet-modal>
    <sweet-modal title="Select Music" @close="stopAllMusic()" ref="musicContentModal" class="audio-modal" width="80%" >

      <table v-if="userMusicList.length" class="table audio-table">
        <tbody>
          <tr v-for="(item, $index) in userMusicList" :key="$index">
            <td>
              <button @click="addMusicSlide(item)" type="button" class="btn btn-default"><i class="fa fa-plus"></i> </button> 
            </td>
            <td>{{item.fileName}}</td>
            <td>
              <audio controlsList="nodownload"
                  :ref="'u-' + item.userContentDataLink"
                  controls
                  :src="S3_URL + item.userContentDataLink">
                      Your browser does not support the
                      <code>audio</code> element.
              </audio>
              <!-- <i @click="deleteItem(item)" class="btn btn-light fa fa-trash audio-command-icon"></i> -->
            </td>
          </tr>
        </tbody>
      </table>
      <uploader
        ref="uploader"
        :disabled="isUploading"
        :options="options"
        class="uploader-example"
      >
        <uploader-unsupport></uploader-unsupport>
        <uploader-btn :attrs="attrs" :class="{disabled: isUploading}" :disabled="isUploading" class="btn btn-light upload-button">
          <i style="font-size: 22px;" class="fa fa-upload"></i>
          <span>Upload</span>
        </uploader-btn>
        <div class="spinner-border upload" v-if="isUploading" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </uploader>
    </sweet-modal>
    <sweet-modal title="Select Stock Music" @close="stopAllMusic()" ref="stockMusicContentModal" width="80%" >

      <table v-if="stockMusic.length" class="table audio-table">
        <tbody>
          <tr v-for="(item, $index) in stockMusic" :key="$index">
            <td>
              <button @click="addMusicSlide(item)" type="button" class="btn btn-default"><i class="fa fa-plus"></i> </button> 
            </td>
            <td>{{item.fileName}}</td>
            <td>
              <audio controlsList="nodownload"
                  :ref="'s-' + item.userContentDataLink"
                  controls
                  :src="S3_URL + item.userContentDataLink">
                      Your browser does not support the
                      <code>audio</code> element.
              </audio>
              <!-- <i @click="deleteItem(item)" class="btn btn-light fa fa-trash audio-command-icon"></i> -->
            </td>
          </tr>
        </tbody>
      </table>
    </sweet-modal>
     <sweet-modal title="Select External Music" ref="externalMusicContentModal" width="80%" > 
      <div class="d-flex justify-content-center mb-4">
      <div class="input-group rounded w-75">
        <input 
        type="search" 
        class="form-control rounded" 
        placeholder="Search" 
        aria-label="Search"
        aria-describedby="search-addon" 
        v-model="searchFilter.search"
        :class="{'is-invalid': $v.searchFilter.search.$error}">
          <button class="btn btn-outline-primary" type="button" @click="clearResultsAndsearchExtenalMusic()"><i class="fa fa-search"></i></button>       
      </div>
      </div>
      <table class="table audio-table">
        <tbody v-if="!isLoading">
          <tr>
            <td><i class="fa fa-download offset-2" aria-hidden="true"></i></td>
            <td><i class="fa fa-user offset-1" aria-hidden="true"></i></td>
            <td><i class="fa fa-music offset-2" aria-hidden="true"></i></td>
            <td><i class="fa fa-play offset-3" aria-hidden="true"></i></td>
          </tr>
          <tr v-for="(item, $index) in externalMusic.data" :key="$index">
            <td>
              <button @click="addExternalMusicSlide(item)" type="button" class="btn btn-default"><i class="fa fa-plus"></i> </button> 
            </td>
            <td>{{item.group}}</td>
            <td>{{item.title}}</td>
            <td>
            <button @click="openRecordPreview(item)" type="button" class="btn btn-outline-primary">Preview</button> 
              <!-- <i @click="deleteItem(item)" class="btn btn-light fa fa-trash audio-command-icon"></i> -->
            </td>
          </tr>
        </tbody>
        <div class="text-center" v-else>
          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status" >
            <span class="sr-only">Loading...</span>
          </div>  
        </div>
      </table>
        <nav class="mt-5">
          <ul class="pagination d-flex justify-content-center" v-if="!isLoading && externalMusic.data!=null && externalMusic.data.length>0">
            <button class="btn btn-primary mr-1 btn-md" @click="previousPage()" :disabled="prevBtnDisabled">Previous</button>
            <div class="pageNumbers " v-for="(page,index) in externalMusic.pages" :key="index">
                <li class="page-item">
                    <button v-if="page!=searchFilter.pageNumber" class="btn btn-outline-primary btn-mg ml-2" @click="changePage(page)">{{page}}</button>
                    <button v-if="page==searchFilter.pageNumber" class="btn btn-primary btn-mg ml-2" @click="changePage(page)">{{page}}</button>
                </li>
            </div>
            <button class="btn btn-primary ml-1 btn-md" @click="nextPage()" :disabled="nextBtnDisabled">Next</button>
          </ul>
        </nav>
    </sweet-modal>

    <sweet-modal ref="externalMusicPreviewModal" title="Record Preview" @close="stopAllMusic()">
      <audio v-if="!isUrlLoading" controlsList="nodownload"
          ref="externalPreviewRecord"
          controls
          :src="externalRecordUrl"
          style="width: 100%;"                 
          >
          Your browser does not support the
          <code>audio</code> element.
       </audio> 
        <div class="text-center" v-else>
          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status" >
            <span class="sr-only">Loading...</span>
          </div>  
        </div>
    </sweet-modal>
    <sweet-modal ref="externalMusicErrorModal" icon="error">
      <div>
        <h2 class="modal-title">Access Denied</h2>
      </div>
      <div class="modal-body">
        <hr>
        <h4>You Can't Use External Music With Current <b>Payment Plan!</b></h4>
        <hr>
        <h6>Please subscribe to another <b>payment plan</b> that provides external music download!</h6>
          <hr>
        <button class="btn btn-success" @click="redirectToPlans()"  type="button">Upgrade Payment Plan</button>
      </div>
    </sweet-modal>
    <sweet-modal ref="externalMusicAPIErrorModal" icon="error">
        <div>
          <h2 class="modal-title">Error</h2>
            <div class="modal-body">
                <h3>{{errorMessage}}</h3>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-lg" data-dismiss="modal" @click="closeExternalMusicAPIErrorModal">OK</button>
            </div>  
        </div>
    </sweet-modal>

    <sweet-modal ref="musicErrorModal" icon="error" title="Error">Music is too long. Total music can not be longer than 7 minutes. <br/>
      <button class="btn btn-light modal-button" @click="$refs.musicErrorModal.close()" type="button">Ok</button>
    </sweet-modal>
    <sweet-modal ref="themeErrorModal" icon="error" title="Error">Please Select Theme. <br/>
      <button class="btn btn-light modal-button" @click="$refs.themeErrorModal.close()" type="button">Ok</button>
    </sweet-modal>
    <sweet-modal ref="infoErrorModal" icon="error" title="Error">Please fill in the required fields <br/>
      <button class="btn btn-light modal-button" @click="$refs.infoErrorModal.close()" type="button">Ok</button>
    </sweet-modal>
    <sweet-modal ref="textSlideModal" title="Text Slide" width="100%">
      <TextSlide ref="textSlide" :modalRef="$refs.textSlideModal"></TextSlide>
    </sweet-modal>
    <sweet-modal ref="stockVideoModal" title="Add Stock Clips" width="100%" style="height:calc(100vh - 56px); margin-top: 56px;">
      <StockVideo :modalRef="$refs.stockVideoModal"></StockVideo>
    </sweet-modal>
    <sweet-modal ref="tolError" icon="warning">
      You have to fill all placeholders with photos.
      <div>
        <button class="btn btn-light modal-button" @click="$refs.tolError.close()" type="button">Ok</button>
      </div>
    </sweet-modal>
        <sweet-modal ref="uploadErrorModal" icon="error">
      <div>
            <h2 class="modal-title">Upload Canceled</h2>
        </div>
        <div class="modal-body">
            <h3>{{errorUploadMessage}}</h3>
    <sweet-modal ref="uploadError" icon="error">
      The file "{{errorFileName}}" seems to be corrupted and can't be uploaded. Please check the file format and try again.
      <div>
        <button class="btn btn-light modal-button" @click="$refs.uploadError.close()" type="button">Ok</button>
      </div>
    </sweet-modal>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-lg" data-dismiss="modal" @click="closeUploadErrorModal">OK</button>
        </div>    
    </sweet-modal>
    <sweet-modal ref="videoErrorModal" icon="error" :blocking="true">
        <div>
          <h2 class="modal-title">Error</h2>
            <div class="modal-body">
                <h3>{{errorMessage}}</h3>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-lg" data-dismiss="modal" @click="closeVideoErrorModal">OK</button>
            </div>  
        </div>
    </sweet-modal>

    <video ref="videoContentList" id="videoHidden" style="visibility: hidden"/>
  </div>
</template>

<script>
import Vue from 'vue'
import api from '@/api'
import config from '@/config'
import util from '@/util'
import draggable from 'vuedraggable'
import ContentList from '@/components/common/ContentList'
import ContentFilter from '@/components/common/ContentFilter'
import TextSlide from '@/components/video/TextSlide'
import StockVideo from '@/components/video/StockVideo'
import TolSlides from '@/components/video/TolSlides'
import { required } from 'vuelidate/lib/validators'
import { EventBus } from '@/event'
export default {
  components: {
    ContentList,
    ContentFilter,
    TextSlide,
    StockVideo,
    TolSlides,
    draggable
  },
  data () {
    return {
      errorUploadMessage: "",
      searchFilter:{
        search:'',
        pageNumber:1,
      },
      customer:{},
      error: false,
      errorMessage : "",
      audio:null,
      uploadFlag:false,

      currentTab: null,
      valid: null,
      infoForm: {},
      previewDataLink: null,
      previewInProgress: false,
      previewProgressValue: 0,
      themeIntroductionVideoDuration: 0,
      videoNameEdit: false,
      options: {
        target: `${config.API_URL}api/add-user-content${util.getUserContextUrlParam()}`,
        testChunks: false,
        chunkSize: 1000000000,
        permanentErrors : [400, 404, 415, 500, 501],
        allowDuplicateUploads: true,
        headers: {
          Authorization: `Bearer ${util.getSessionData().access_token}`
        },
        successStatuses: [200,201, 202, 204]
      },
      attrs: {
        accept: '.mp4,.mp3'
      },
      uploading: false,
      errorFileName: ''
    }
  },
  validations: {
    searchFilter:{
      search:{
        required
      }
    },
    selectedVideo: {
      title: {
        required
      },
      subtitle: {
        required
      },
      name: {
        required
      },
      // [
      //   v => !!v || 'Name of the deceased is required'
      // ],
      // videoName: [
      //   v => !!v || 'Video name is required'
      // ],
      // bornDied: [
      //   v => !!v || 'Born-Died info is required'
      // ]
    },
    validationGroup: ['searchFilter', 'selectedVideo']
  },
  // mounted () {
    // this.selectedVideo.videoSlides.map(slide => {
    //   this.$refs['croppieRef'+slide.orderNo][0].bind({
    //     url: this.getTolSlide(slide.orderNo).userContentDataLink ? (this.API_URL + 'api/get-s3-file?file=' + this.getTolSlide(slide.orderNo).userContentDataLink) : this.emptyImage,
    //     ...slide.cropInfo
    //   })
    // })
  // },
  computed: {
    prevBtnDisabled(){
      if(this.externalMusic.previousPage == null || this.externalMusic.previousPage==0)
          return true;
      else
          return false;
    },
    nextBtnDisabled(){
      if(this.externalMusic.nextPage == null || this.externalMusic.nextPage==0)
          return true;
      else
          return false;
    },
    videoState () {
      // let states = this.$store.getters.getVideoStates
      return this.$store.getters.getVideoStates[this.selectedVideo.videoId] || {}
      // return this.$store.getters.getVideoStates
    },
    canUseExternal(){
      if(this.paymentPlan.typeOfPlan==4 && this.paymentPlan.withAddOn==1){
        return true
      }
      if((this.paymentPlan.withAddOn==0 || this.customer.startDatePaymentPlan==null)  && util.getSessionInfo().userTypeId!==5 ){
        return false
      }
      return true
    },
    isLoading(){
      return this.$store.getters.isLoading 
    },
    paymentPlan(){
       return this.$store.getters.getSinglePaymentPlan
    },
    isUrlLoading(){
     return this.$store.getters.isUrlLoading 
    },
    externalRecordUrl(){
      return this.$store.getters.externalRecordUrl;
    },
    externalMusic(){
      return this.$store.getters.getExternalMusic
    },
    currentTabCalc () {
      return this.currentTab
    },
    isUploading () {
      return this.uploading
    },
    dragInProgress () {
      return this.$store.getters.dragInProgress
    },
    themes () {
      return this.$store.getters.themesList
    },
    theme () {
      return this.$store.getters.selectedTheme
    },
    themeSlides () {
      return this.$store.getters.themeSlides
    },
    stockMusic () {
      return this.$store.getters.stockMusic
    },
    selectedVideo () {
      return this.$store.getters.selectedVideo
    },
    musicSlides () {
      return this.$store.getters.selectedVideo.videoSlides.filter(item => {
        return item.userContentTypeId === 3
      })
    },
    API_URL () {
      return config.API_URL
    },
    S3_URL () {
      return config.S3_URL
    },
    userMusicList () {
      return this.$store.getters.userContentList('music',false).filter((item) => {
        return item.userContentTypeId === 3
      }).map(item => {
        let slides = this.$store.getters.selectedVideo.videoSlides.filter(slide => {
          return slide.userContentId === item.userContentId
        })
        item.videoSlideId = slides[0] && slides[0].videoSlideId
        return item
      })
    },
    musicDuration () {
      let duration = 0
      this.$store.getters.selectedVideo.videoSlides.filter(slide => {
        return slide.userContentTypeId === 3 //music
      }).map(slide => {
        duration += slide.duration
      })
      return duration
    },
    slidesDuration () {
      let duration = 0
      this.$store.getters.selectedVideo.videoSlides.filter(slide => {
        return slide.userContentTypeId !== 3 //music
      }).map(slide => {
        duration += slide.duration
        if(slide.userContentTypeId === 1)
        {
          duration -= 1.5
        }
      })
      return duration + 2
    },
    themeVideoDuration () {
      return this.themeIntroductionVideoDuration
    },
    timeForSlides () {
      return this.musicDuration - this.slidesDuration - this.themeVideoDuration
    },
    secondsPerSlide () {
      // let videoSlidesDuration = 0
      let slides = this.selectedVideo.videoSlides.filter(slide => {
        return slide.userContentTypeId === 2 || slide.userContentTypeId === 4
      })
      // this.selectedVideo.videoSlides.map(slide => {
      //   videoSlidesDuration += slide.userContentTypeId === 1 ? slide.duration : 0
      // })

      return ((this.timeForSlides) / (slides.length || 1)).toFixed(2)
    },
    
    maxSlides () {
      return Math.round(this.timeForSlides / 10)
    }
  },
  created () {
    this.$store.dispatch('getThemesList')
    this.$store.dispatch('getThemeStockSlides')
    this.$store.dispatch('getStockMusic')
    this.$store.dispatch('getThemeSlides')
    this.getCurrentCustomer()
  },
  methods: {
  preventJsonSpecialCharacters(e) {
    if (/[^a-zA-Z0-9 -]/g.test(e.key)) {
      e.preventDefault();
    }
  },
  getCurrentCustomer(){
      api.customers.getCurrentCustomer().then(response=>{
      this.customer=response.data;
      this.$store.dispatch('getSinglePaymentPlan',this.customer.paymentPlanId);
      })
    },
    openTextSlideDialog () {
      this.$refs.textSlide.reset()
      this.$refs.textSlideModal.open()
    },
    validateTol () {
      return new Promise((resolve, reject) => {
        let slidesNo = this.selectedVideo.videoSlides.filter(x => x.userContentTypeId !== 3).length
        if (this.selectedVideo.typeId === 2 && slidesNo !== this.selectedVideo.theme.numberOfImages) {
          if (slidesNo > this.selectedVideo.theme.numberOfImages) {
            this.count = 0
            resolve(true)
            return
          }
          this.$refs.tolError.open()
          reject('You have to fill all placeholders with photos.')
        } else {
          this.count = 0
          resolve(true)
        }   
     })
    },
    generateTol() {
      EventBus.$emit('generateTOLVideo', { id: this.selectedVideo.videoId, seconds: self.secondsPerSlide || 0 })
    },
    
    dragEnd () {
      let slides = [...this.selectedVideo.videoSlides]
      for (let i = 0; i < slides.length; i++) {
        slides[i].orderNo = i + 1
      }
      api.video.reorderSlides(slides).then(() => {
        this.$store.dispatch('getVideo', this.selectedVideo.videoId)
      })
    },
    deleteSlide (item) {
      api.video.removeSlide(item.videoSlideId).then(() => {
        this.$store.dispatch('getVideo', this.selectedVideo.videoId)
      })
    },
    loadUserContent () {
      if (this.$refs.videoPreviewPlayer && this.$refs.videoPreviewPlayer.pause) {
        this.$refs.videoPreviewPlayer.pause()
      }
      this.$store.dispatch('getUserContentList')
      this.$store.commit('setSelectedVideo', {
        videoSlides: []
      })

    },
    validateTheme () {
      //reset filter
      this.$store.commit('setContentFilter', 'all')
      if (this.theme.themeId) {
        return true
      } else {
        this.$refs.themeErrorModal.open()
        return false
      }
    },
    validateMusic () {
      if (this.musicDuration > 420) {
        this.$refs.musicErrorModal.open()
        return false
      } else {
        return true
      }
    },
    validateInfo () {
      this.$v.$touch()
      if (this.$v.selectedVideo.$invalid) {
        this.$refs.infoErrorModal.open()
        return false
      }
      return true
      // if (this.theme.themeId) {
      //   return true
      // } else {
      //   this.$refs.infoErrorModal.open()
      //   return false
      // }
    },
    removeMusicSlide (item) {
      api.video.removeSlide(item.videoSlideId).then(()=> {
        Vue.set(item, 'videoSlideId', null)
        // item.videoSlideId = null
        this.$store.dispatch('getVideo', this.selectedVideo.videoId)
        this.$store.dispatch('getUserContentList')
      })
    },
    async addExternalMusicSlide(item){ 
      this.$store.dispatch("addLoading",true);           
      const response = await fetch(`${config.API_URL}api/music/download${util.getUserContextUrlParam()}?id=${item.id}&name=${item.title}`, {
      method: 'POST',
      headers: {
          Authorization: `Bearer ${util.getSessionData().access_token}`,         
        },
      });
      const resData = await response.json();
      var duration = parseInt(item.duration);
      let obj = {
        duration:duration,
        videoId: this.selectedVideo.videoId,
        userId: this.selectedVideo.userId,
        userContentId: resData.userContentId
      }
      this.$store.dispatch('getUserContentList')

      api.video.addSlide(obj).then((response)=> {
        this.$refs.externalMusicContentModal.close();
        Vue.set(item, 'videoSlideId', response.data)
        this.$store.dispatch('getVideo', this.selectedVideo.videoId)
        this.$store.dispatch('getUserContentList')
        this.$store.dispatch("addLoading",false); 
      })

    },
    openRecordPreview(item){
      this.$store.dispatch("getExternalRecordUrl",item.id);
      this.$refs.externalMusicPreviewModal.open();     
    },
     openExternalMusicDialog(){
      if(this.canUseExternal){
        this.$refs.externalMusicContentModal.open();
      }else{
        this.$refs.externalMusicErrorModal.open()
      }
    },
    redirectToPlans(){
      this.$router.push('settings');
    },
    changePage(page){
      this.searchFilter.pageNumber=page; 
      this.searchExtenalMusic();
    },
    nextPage(){
      this.searchFilter.pageNumber++;
      this.searchExtenalMusic();
    },
    previousPage(){
      this.searchFilter.pageNumber--;
      this.searchExtenalMusic();
    },
    clearResultsAndsearchExtenalMusic(){
      this.searchFilter.pageNumber=1;
      this.searchExtenalMusic();
    },
    searchExtenalMusic(){
      this.$v.$touch();
        if (this.$v.searchFilter.$invalid) {
        return;
      }   
      let search = this.searchFilter;
      this.$store.dispatch('addExternalMusic',search)
    },
    openExternalMusicAPIErrorModal() {
      this.$refs.externalMusicAPIErrorModal.open()
    },
    closeExternalMusicAPIErrorModal() {
      this.errorMessage="";
      this.$refs.externalMusicAPIErrorModal.close()
    },
    addMusicSlide(item){
      let audioElement =  (this.$refs['u-' + item.userContentDataLink] && this.$refs['u-' + item.userContentDataLink][0]) || (this.$refs['s-' + item.userContentDataLink] && this.$refs['s-' + item.userContentDataLink][0])
      let duration = audioElement && audioElement.duration
      item.duration = duration || 0
      let obj = {
        duration: duration,
        videoId: this.selectedVideo.videoId,
        userId: this.selectedVideo.userId,
        userContentId: item.userContentId,
        orderNo: 500
      }
      api.video.addSlide(obj).then((response)=> {
        this.$refs.musicContentModal.close()
        this.$refs.stockMusicContentModal.close()
        Vue.set(item, 'videoSlideId', response.data)
        // item.videoSlideId = response.data
        this.$store.dispatch('getVideo', this.selectedVideo.videoId)
        this.$store.dispatch('getUserContentList')
      })
    },
    resetWizard () {
      this.$refs.wizard.reset()
    },
    open (item) {
      this.resetWizard()
      if(!item) {
        api.video.getNextVideoNumber().then((res) => {
          let event = this.$store.getters.eventInfo && this.$store.getters.eventInfo.eventId ? this.$store.getters.eventInfo : util.getSessionInfo().eventInfo
          let name = Vue.i18n.translate('Unnamed Video') + ' ' + res.data
          this.$store.commit('resetSelectedVideo', {
            name: name,
            title: event.mainText,
            subtitle: event.subText,
            introductoryText: event.introductoryText,
          })
        })
        this.$refs.modal.open()
      } else {
        this.$store.dispatch('getVideo', item.videoId).then(() => {
          this.$refs.wizard.activateAll()
          this.$refs.modal.open()
          // this.selectedVideo.videoSlides.map(slide => {
          //   this.$refs['croppieRef'+slide.orderNo][0].bind({
          //     url: this.getTolSlide(slide.orderNo).userContentDataLink ? (this.API_URL + 'api/get-s3-file?file=' + this.getTolSlide(slide.orderNo).userContentDataLink) : this.emptyImage,
          //     ...slide.cropInfo
          //   })
          // })
        })
      }
    },
    openContentList () {
      this.$refs.contentModal.open()
    },
    openMusicContentList () {
      this.$refs.musicContentModal.open()

      this.$refs.uploader.uploader.on('fileAdded',(file) => {   
        var url = URL.createObjectURL(file.file);
        document.getElementById('videoHidden').setAttribute('src',url);
        var myVideo = document.getElementById("videoHidden");
        myVideo.load();
      })
      this.$refs.uploader.uploader.on('uploadStart', () => {
          if(this.uploadFlag===false){
            this.$refs.uploader.uploader.pause();
          }
          this.uploading=true;
          var myVideo = document.getElementById("videoHidden");
          myVideo.load();

          myVideo.onloadedmetadata=()=>{
            if(myVideo.duration>=1200){
              this.$refs.uploader.uploader.cancel();
              this.uploading=false;
              this.uploadFlag=false;
              this.errorUploadMessage='This video is too long, please upload smaller video!';
              this.openUploadErrorModal();
              return
            }
          }
          if(this.uploadFlag===false){
            this.uploadFlag=true;
            this.$refs.uploader.uploader.resume()
          }
      })
      this.$refs.uploader.uploader.on('complete', () => {
        this.uploading = false
        this.$store.dispatch('getUserContentList')
      })
      this.$refs.uploader.uploader.on('fileError', (rootFile, file) => {
        this.uploading = false
        this.errorFileName = rootFile && file.name
        this.$refs.uploadError.open()
      })
      this.$refs.uploader.uploader.on('fileError', (_rootFile, _file, message) => {
        let messageObj = JSON.parse(message);
        this.errorUploadMessage=messageObj.Message;
        this.$refs.uploader.uploader.cancel();
        this.uploading=false;
        this.uploadFlag=false;
        this.openUploadErrorModal();
      })
    },
    openUploadErrorModal(){
      this.$refs.uploadErrorModal.open();
    },
    openVideoErrorModal() {
      this.$refs.videoErrorModal.open()
    },
    closeVideoErrorModal() {
      this.$refs.videoErrorModal.close();
      this.$router.push({ name: 'Events' })
    },
    closeUploadErrorModal(){
      this.errorUploadMessage='';
      this.$refs.uploadErrorModal.close();
    },
    selectAll () {
      this.$store.commit('selectAllUserContentItems', this.$store.getters)
    },
    selectNone () {
      this.$store.commit('selectNoneUserContentItems')
    },
    onTabChange (prev, next) {
      this.currentTab = next
      if (prev === 0 && next === 1) { // info
        this.saveVideo()
      } else if (prev === 1 && next === 2) { // theme
        this.saveVideo()
      } else if (prev === 2 && next === 3) { //music
        if(this.theme.themeType === 'AE') { //TOL
          this.saveVideo()
        }
      } else if (prev === 3 && next === 4) { // preview
        this.saveVideo()
      } else if (prev === 4 && next === 5) { // finish
        this.saveVideo()
      }
    },
    onComplete () {
      if (this.selectedVideo.typeId === 2) {// TOL
        api.video.generateVideo(this.selectedVideo.videoId, false).then(() => {
          this.$store.dispatch('getUserContentList')
          EventBus.$emit('notification', { message: 'Video is being generated in the background. You will be notified when it is done.' })
        })
      } else if (this.selectedVideo.typeId === 1 || this.selectedVideo.typeId === 3) {
        api.video.generateRegularVideo({ id: this.selectedVideo.videoId, seconds: this.secondsPerSlide }).then(() => {
          this.$store.dispatch('getUserContentList')
          EventBus.$emit('notification', { message: 'Video is being generated in the background. You will be notified when it is done.' })
        })
      }
      this.$refs.modal.close()
    },
    saveVideo () {
      if(!this.theme.themeId) {
        //this.$refs.themeErrorModal.open()
        return false
      } else {
        let videoType = this.theme.themeType === 'AE' ? 2 : (this.theme.themeType === 'SW' ? 3 : 1)
        let event = this.$store.getters.eventInfo && this.$store.getters.eventInfo.eventId ? this.$store.getters.eventInfo : util.getSessionInfo().eventInfo
        if (this.selectedVideo.videoId) {
          let video = { ...this.selectedVideo }
          video.themeId = this.theme.themeId
          video.typeId = videoType
          video.secondsPerSlide = this.secondsPerSlide
          // video.title = this.infoForm.nameOfTheDeceased
          // video.subtitle = this.infoForm.bornDied
          // video.name = this.infoForm.videoName
          // video.introductoryText = this.infoForm.title
          video.eventId = event.eventId
          api.video.update(video).then(() => {
            this.$store.dispatch('getVideo', video.videoId)
          })
        } else {
          api.video.add({
            title: this.selectedVideo.title,
            subtitle: this.selectedVideo.subtitle,
            name: this.selectedVideo.name,
            introductoryText: this.selectedVideo.introductoryText,
            includeInfo: this.selectedVideo.includeInfo,
            typeId: videoType,
            themeId: this.theme.themeId,
            eventId: event.eventId 
          }).then(response => {
            this.$store.dispatch('getVideo', response.data)
            this.$store.commit('setBasicEventInfo', {title: this.selectedVideo.introductoryText, name: this.selectedVideo.title, year: this.selectedVideo.subtitle})
            this.$store.dispatch('getEventList', util.getSessionInfo().userId)
          }).catch((error)=> {
            this.errorMessage = error.data;
            this.openVideoErrorModal();
			})
        }
        return true
      }
    },
    generatePreview () {
      if(this.videoState && this.videoState.progress && this.videoState.progress < 100) {
        return
      }
      if(this.selectedVideo.typeId === 2) {
        return
      }
      let self = this
      this.previewDataLink = ''
      this.previewInProgress = true
      EventBus.$emit('notification', { message: 'Video is being generated in the background. You will be notified when it is done.' })

      EventBus.$emit('generateVideo', { id: this.selectedVideo.videoId, seconds: self.secondsPerSlide || 0 })
      return true
    },
    saveSlides () {
      this.$store.getters.selectedUserContent.map(item => {
        let el = document.getElementById('v-' + item.userContentDataLink)
        if (el && el.duration) {
          item.duration = el.duration
        }
        this.selectedVideo.videoSlides.push(item)
      })
      this.selectedVideo.updateSlides = true;
      api.video.update(this.selectedVideo).then(() => {
        this.$store.dispatch('getVideo', this.selectedVideo.videoId)
      })
      this.$store.commit('selectNoneUserContentItems')
      this.$refs.contentModal.close()
    },
    selectTheme (theme) {
      this.$store.commit('setSelectedTheme', theme)
    },
    updateThemeVideoDuration () {
      // let videoElement = document.getElementById(this.selectedVideo.theme.introductionVideo)
      // let duration = videoElement && videoElement.duration
      let duration = (this.selectedVideo.theme.introLength || 0) + (this.selectedVideo.theme.outroLength || 0)
      if(this.selectedVideo.theme.introductionVideo == null)
      {
        duration = 0
      }
      this.themeIntroductionVideoDuration = duration || 0
    },
    stopAllMusic () {
      this.stockMusic.map((item => {
        this.$refs['s-' + item.userContentDataLink] && this.$refs['s-' + item.userContentDataLink][0] && this.$refs['s-' + item.userContentDataLink][0].pause()
      }))
      this.userMusicList.map((item => {
        this.$refs['u-' + item.userContentDataLink] && this.$refs['u-' + item.userContentDataLink][0] && this.$refs['u-' + item.userContentDataLink][0].pause()
      }))
      this.$refs.externalPreviewRecord.pause();
    }
  }
}
</script>

<style lang="css" scoped>

.video-window.sweet-modal-overlay {
  height: calc(100vh - 56px);
  margin-top: 56px;
}
.video-window >>>.sweet-modal{
  height: calc(100vh - 56px);
}
.wizard-navigation {
  width: 700px;
}
.video-window >>>.sweet-modal .sweet-content {
  padding-top: 0;
}
.video-window >>>.vue-form-wizard.md .wizard-icon-circle {
    width: 55px;
    height: 55px;
    font-size: 22px;
}
.video-window >>>.vue-form-wizard .wizard-header {
  padding-top: 0;
}
.video-window >>>.vue-form-wizard .wizard-nav-pills > li > a{
  color: gray;
}
.video-window >>>.vue-form-wizard .wizard-card-footer {
  position: absolute;
  bottom: 24px;
  width: calc(100vw - 80px);
}
.img-box {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border: 2px solid transparent;
  padding: 2px;
  vertical-align: top;
  position: relative;
  cursor: move;
}
.img-box.active {
  border: 2px solid #007bff;
  border-radius: 3px;
}
.img-thumbnail {
  max-width: 200px;
  max-height: 120px;
}
div.img-thumbnail {
  width: 200px;
  height: 120px;
}
.num-of-images {
  font-size: 12px;
}
.modal-commands {
  float: right;
}
.filter-buttons-container {
  float: left;
}
.modal-commands-header {
  height: 64px;
}
.modal-commands button {
  margin-left: 4px;
  margin-right: 4px;
}
.progress {
  max-width: 800px;
  margin: auto;
  margin-top: 12px;
}
.wizard {
  max-width: calc(100% - 20px);
}
.audio-command-icon {
  margin-left: 10px;
}
.audio-table td {
  padding: 0;
  padding-top: 5px;
  vertical-align: middle;
}
.audio-table {
  max-width: 900px;
  margin: auto;
}
.video-icon {
  margin-top:20px;
}
.img-box:hover .command-icon{
  display: block;
}

.list-group {
  overflow-y: scroll;
  max-height: calc(100vh - 330px);
}
.video-name-form {
    width: 350px;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 0;
    height: 38px;
    position: fixed;
    bottom: 20px;
    margin-left: calc(50vw - 175px);
    background-color: #fff;
    z-index: 100;
    border-radius: 3px;
    border: 1px solid lightgray;
}
.video-name-form span {
  padding-top: 7px;
  padding-left: 13px;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
.video-name-form span i {
  font-size: 20px;
  display: block;
  float: right;
  margin-right: 10px;
}
.wizard .vue-form-wizard .wizard-header {
  padding-top: 0;
  padding-bottom: 0;
}

.scrollable-container {
  max-height: calc(100vh - 280px);
  overflow-y: scroll;
}
.form {
  text-align: left;
  margin: auto;
  width: 400px;
}
.upload-button {
  border-radius: 50%;
  font-size: 16px;
  width: 74px;
  height: 74px;
  padding-top: 12px;
}
.uploader-example {
  display: inline-block;
  height: 100px;
  width: 100px;
  padding: 10px;
  margin-top: 28px;
  margin-left: 32px;
  /* text-align: center; */
}
.audio-modal >>>.sweet-content-content {
  text-align: center;
}
#hidden{
  display: none;
}
.musicButton{
  width: 200px;
}


</style>