<template>
  <form ref="form" class="form" @submit.prevent="submitAddForm">
    <div class="form-label-group">
      <select id="Subscription" class="form-control" :placeholder="'Subscription' | translate" required v-model.trim="newPlan.period">
          <option value="3">Per Funeral</option>
          <option value="1">Monthly</option>
          <option value="2">Yearly</option>
          <option value="4">Free</option>
      </select>
      <label for="Subscription">{{'Subscription' | translate}}</label>
    </div>
    <div class="form-label-group" v-if="newPlan.period != 4">
       <input  :class="{ 'is-invalid': $v.newPlan.price.$error }" v-model.trim="newPlan.price" type="number" min="1"  class="form-control"  :placeholder="'Price' | translate" />
      <label for="Price">{{'Price (€) *' | translate}}</label>
    </div>
    <div class="form-label-group">
      <input :class="{ 'is-invalid': $v.newPlan.name.$error }" type="text" class="form-control" placeholder="Enter Payment Plan Name" v-model.trim="newPlan.name" >
      <!-- <input :class="{'is-invalid': $v.item.mainText.$error}"  type="text" id="infoMainText" class="form-control" :placeholder="'Name of the Deceased *' | translate" required> -->
      <label for="paymentPlanName">{{'Payment Plan Name *' | translate}}</label>
    </div>
    <div class="form-label-group" v-if="newPlan.period != 3 && newPlan.period!=4">
      <select class="custom-select" required v-model="planType" >
        <option value="rgb(230,218,214)">Bronze</option>
        <option value="rgb(229,229,229)">Silver</option>
        <option value="rgb(232,228,205)">Gold</option>
        <option value="rgb(209,232,233)">Diamond</option>
      </select> 
      <label for="planType">{{'Plan Type' | translate}}</label>
    </div>
    <div class="form-label-group" v-if="newPlan.period != 3 && newPlan.period != 4">
       <input :class="{ 'is-invalid': $v.newPlan.numOfEvents.$error }" type="number" min="1"   v-model.trim="newPlan.numOfEvents" class="form-control"  :placeholder="'Number of Funerals' | translate" />
      <label for="numOfEvents">{{'Number of Funerals *' | translate}}</label>
    </div>
  <div class="form-label-group">
       <div class="sub-form-group" v-show="newPlan.period!=4" style="padding-right:5px">
          <select class="custom-select" required v-model="newPlan.freeTrial"  >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        <label for="FreeTrial">{{'Free Trial' | translate}}</label>
      </div>
      <div class="sub-form-group" v-if="newPlan.freeTrial==1" style="padding-right:5px">
          <input :class="{ 'is-invalid': $v.newPlan.trialPeriodLength.$error }" 
          type="number" min="7" class="form-control" placeholder="Enter Trial Length" 
                    v-model.trim="newPlan.trialPeriodLength">
          <label for="Trial Period">{{'Trial Period *' | translate}}</label>
      </div>
      <div class="sub-form-group">
          <select class="custom-select" v-model="newPlan.addOns" >
              <option value="0">No</option>
              <option value="1">Yes</option>
          </select>
          <label for="Music Library">{{'Music Library' | translate}}</label>
      </div>
    </div>

    <!-- <div v-if="error" class="alert alert-danger" role="alert">
      {{this.errorMessage}}
    </div> -->

       <!-- <button type="submit" class="btn btn-primary ml-3">Add Plan</button>
      <button class="btn btn-secondary ml-3" @click="closeAddForm">Cancel</button> -->
      <!-- <button type="button" @click="closeDialog()" class="command btn btn-default">Cancel</button>
      <button type="button" @click="saveItem()" class="command btn btn-primary" ><i class="fa fa-save"></i> Save</button> -->

     <div class="buttons-row">
      <button type="button" @click="closeAddForm" class="command btn btn-default">Cancel</button>
      <button type="submit" class="command btn btn-primary"><i class="fa fa-save"></i> Save</button>
    </div>
  </form>
</template>
<script>
import { required,minValue} from "vuelidate/lib/validators";

export default {
    
    computed:{
        rules(){
            if(this.newPlan.period==4)
            {
                return {name:{required},
                }
            }
            else if(this.newPlan.period==3 && this.newPlan.freeTrial==1){
                return {
                name:{required},
                price:{required,minValue: minValue(1)},
                trialPeriodLength:{required,minValue: minValue(7)},
                }
            }
            else if(this.newPlan.freeTrial==1){
                return {
                name:{required},
                price:{required,minValue: minValue(1)},
                numOfEvents:{required,minValue: minValue(1)},
                //priceOfSingleEvent:{required,minValue: minValue(1)},
                trialPeriodLength:{required,minValue: minValue(7)},
                }
            }else if(this.newPlan.period!=3){
                return {
                name:{required},
                price:{required},
                numOfEvents:{required},
                //priceOfSingleEvent:{required},
                }
            }else{
                return {name:{required},
                price:{required},
                }
    
            }
        },
        paymentPlan(){
            return this.$store.getters.getSinglePaymentPlan
        },
    },
    data(){
        return{
            newPlan:{
                period:3,
                numOfEvents:null,
                name:"",
                price:null,
               // priceOfSingleEvent:null,
                isHidden:false,
                displayColor:"rgb(230,218,214)",
                freeTrial:0,
                trialPeriodLength:null,
                addOns:0
            },
            planType:"rgb(230,218,214)",

        }
    },
    validations() {
        return{
            newPlan:this.rules
        }
    },
    methods:{
        closeAddForm(){
            this.$emit('closeAddForm');
            this.setToStartValues();
        },
        submitAddForm(){ 
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            var formData={
                typeOfPlan:parseInt(this.newPlan.period),
                name:this.newPlan.name,
                price: this.newPlan.period !=4 ? parseInt(this.newPlan.price) : 0,
                numberOfEvents:parseInt(this.newPlan.numOfEvents===null? 1 : this.newPlan.numOfEvents),                
                //priceOfSingleEvent:parseInt(this.newPlan.priceOfSingleEvent===null?0 : this.newPlan.priceOfSingleEvent),
                isHidden: this.newPlan.period !=4 ? this.newPlan.isHidden : true,
                displayColor:this.newPlan.period !=3 ? this.newPlan.displayColor:"rgb(232,232,232)",
                trialPeriodProvided:this.newPlan.freeTrial==0? 0:1,
                numberOfTialPeriodDays:  this.newPlan.period !=4 ? this.setTrialLength() : 0,
                withAddOn:this.newPlan.addOns==0?0:1,
            }
            // eslint-disable-next-line no-console
            console.log(formData)

            this.$emit('addPlan',formData);
            this.setToStartValues();
        },
        setTrialLength(){
            if(this.newPlan.freeTrial!=0 && this.newPlan.trialPeriodLength!=null && this.newPlan.trialPeriodLength!=""){
                return this.newPlan.trialPeriodLength;
            }else{
                return 0;
            }
        },
        setToStartValues(){
                this.newPlan.period=3,
                this.newPlan.numOfEvents=null,
                this.newPlan.name=null,
                this.newPlan.price=null,
                //this.newPlan.priceOfSingleEvent=null,
                this.newPlan.isHidden=false,
                this.newPlan.displayColor="rgb(230,218,214)",
                this.newPlan.freeTrial=0,
                this.newPlan.trialPeriodLength=null
        }
    },
     watch:{
        planType:{
             handler:function(){
                if(this.planType=="rgb(209,232,233)"){
                    this.newPlan.isHidden=true;
                }
                this.newPlan.displayColor=this.planType;
            }
        }
    }
}
</script>
<style scoped>
.form-label-group {
  margin-top: 20px;
  /* text-align: left; */
}
.sub-form-group {
  position: relative;
  /* text-align: left; */
  /* padding-left: 10px; */
  /* margin-top: 20px; */
  width: 33.33%; 
}
.sub-form-group label {
  position: absolute;
  top: 0;
  left: 11px;
}
.sub-form-group select {
  height: 46px;
}
.sub-form-group input {
  height: 46px;
}
.date-picker {
  width: 50%;
}
.picker-label {
  font-size: 12px;
  color: #777;
}
</style>
