<template>
<div :class="{'drag-in-progress': dragInProgress}">
  <div
    @dragover.prevent @dragenter="dragEnter"  @dragleave="dragLeave" @drop="tolDrop($event, slide)"
    v-for="(slide, $index) in themeSlides" :key="$index"
    class="rounded theme-slide video-slide-dropzone"
    > {{slide.orderNo}}
    <i v-show="getTolSlide(slide.orderNo).thumbnailDataLink" @click="deleteVideoSlide('croppieRef' + slide.orderNo, getTolSlide(slide.orderNo))" class="btn btn-light fa fa-trash command-icon delete-icon"></i>
    <vue-croppie
          :key="$index"
          :enableRotation="true"
          :ref="'croppieRef' + slide.orderNo"
          :enableOrientation="false"
          :enableResize="false"
          :mouseWheelZoom="false"
          :boundary="{ width: slide.width / 10, height: slide.height / 10}"
          :viewport="{ width: slide.width / 10, height: slide.height / 10, type: 'square'}"
          :maxZoom="0.7"
          :zoom="0.1125"
          @update="(val) => {cropUpdate(val, slide)}"
          >
      </vue-croppie>
    </div>
</div>
</template>

<script>
import api from '@/api'
import config from '@/config'
export default {
  data () {
    return {
      emptyImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
    }
  },
  computed: {
    themeSlides () {
      return this.$store.getters.themeSlides.filter(item => {
        return item.themeId === this.selectedVideo.themeId
      })
    },
    selectedVideo () {
      return this.$store.getters.selectedVideo
    },
    dragInProgress () {
      return this.$store.getters.dragInProgress
    },
    API_URL () {
      return config.API_URL
    },
    S3_URL () {
      return config.S3_URL
    },

  },
  mounted () {
    this.selectedVideo.videoSlides.map (slide => {
      if (slide.userContentTypeId === 3) {
        return slide
      }
      if (this.$refs['croppieRef'+slide.orderNo] && this.$refs['croppieRef'+slide.orderNo][0]) {
      this.$refs['croppieRef'+slide.orderNo][0].bind({
        url: this.getTolSlide(slide.orderNo).userContentDataLink ? (this.API_URL + 'api/get-s3-file?file=' + this.getTolSlide(slide.orderNo).userContentDataLink) : this.emptyImage,
          ...slide.cropInfo
        })
      }
    })
  },
  updated () {
    // eslint-disable-next-line
    this.selectedVideo.videoSlides.map (slide => {
    if (this.$refs['croppieRef'+slide.orderNo] && this.$refs['croppieRef'+slide.orderNo][0]) {
      this.$refs['croppieRef'+slide.orderNo][0].bind({
        url: this.getTolSlide(slide.orderNo).userContentDataLink ? (this.API_URL + 'api/get-s3-file?file=' + this.getTolSlide(slide.orderNo).userContentDataLink) : this.emptyImage,
          ...slide.cropInfo
        })
      }
    })
  },
  methods:{
    cropUpdate (val, slide) {
      this.$store.commit('setVideoSlideCropInfo', {slide: this.getTolSlide(slide.orderNo), cropInfo: val})
      // eslint-disable-next-line
      // console.log(val)
      window.efCurrentCropInfo = val;
    },
    dragEnter(e) {
      e.currentTarget.classList.add('drag-enter')
    },
    dragLeave(e) {
      e.currentTarget.classList.remove('drag-enter')
    },
    tolDrop (ev, themeSlide) {
      // eslint-disable-next-line
      console.log(this.selectedVideo.videoSlides[themeSlide.orderNo])
      this.$store.commit('setDragInProgress', false)
      ev.currentTarget.classList.remove('drag-enter')
      ev.preventDefault()
      let data = JSON.parse(ev.dataTransfer.getData("text/plain"))
      
      let slide = {
        duration: 0,
        videoId: this.selectedVideo.videoId,
        userId: this.selectedVideo.userId,
        userContentId: data.userContentId,
        orderNo: themeSlide.orderNo,
        cropInfo: window.efCurrentCropInfo
      }
      // let self = this
      this.$store.dispatch('addVideoSlideAndGet', {slides: this.selectedVideo.videoSlides, slide: slide}).then(() => {
        //  this.$refs['croppieRef'+themeSlide.orderNo][0].bind({
        //   url: self.getTolSlide(slide.orderNo).userContentDataLink ? (self.API_URL + 'api/get-s3-file?file=' + self.getTolSlide(slide.orderNo).userContentDataLink) : self.emptyImage,
        //   points: self.getTolSlide(slide.orderNo).cropInfo.points
        // })
        // this.$refs['croppieRef'+themeSlide.orderNo][0].setZoom(0.15)
      })
    },
    getTolSlide (orderNo) {
      let result = this.selectedVideo.videoSlides.filter((item) => {
        return item.orderNo === orderNo
      })
      return result && result[0] || {}
    },
    deleteVideoSlide (croppieRef, item) {
      api.video.removeSlide(item.videoSlideId).then(()=>{
        this.$refs[croppieRef][0].bind({
          url: this.emptyImage
        })

        this.$store.dispatch('getVideo', this.selectedVideo.videoId)
      })
    },
  }
}
</script>
<style lang="css" scoped>
.theme-slide:hover .command-icon{
  display: block;
}
.drag-in-progress .video-slide-dropzone {
  border:1px dashed gray;
}
.drag-enter {
  border: 1px dashed rgb(0, 123, 255);
}
.drag-in-progress .video-slide-dropzone >>>.croppie-container,
.drag-enter >>>.croppie-container {
  visibility: hidden;
}
.video-slide-dropzone {
  display: inline-block;
  background-color: #EEE;
  max-width: 182px;
}
.video-slide-dropzone >>>.croppie-container {
  width: 182px;
}
.video-slide-dropzone >>>.croppie-container .cr-slider-wrap {
  margin: 5px auto;
}
.video-slide-dropzone >>>.croppie-container .cr-boundary {
  /* border: 1px solid gray; */
  -moz-box-shadow: inset 0 0 5px #888;
  -webkit-box-shadow: inset 0 0 5px #888;
  box-shadow: inset 0 0 5px #888;
  vertical-align: middle;
  background-color: #fff;
}
.video-slide-dropzone >>>.croppie-container .cr-boundary [alt=preview] {
  display: none;
}
.theme-slide {
  display: inline-block;
  border: 1px solid lightgray;
  padding-top: 10px;
  margin: 5px;
  position: relative;
}
.theme-slide .frame {
  -moz-box-shadow: inset 0 0 5px #888;
  -webkit-box-shadow: inset 0 0 5px #888;
  box-shadow: inset 0 0 5px #888;
  vertical-align: middle;
}
.theme-slide .delete-icon {
  margin: 4px;
  z-index: 10000;
  right: 0px;
  top: 0;
}
.theme-slide .slide-order-no {
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin-right: 5px;
}
</style>