<template>
<div class="card mb-3">
  <div class="row no-gutters">
    <div class="col-md-4 title-field" :style="planColor" @click="openInfoDialog">
      <h3 class="card-title">{{upperLeter}}</h3>
    </div>
    <div class="col-md-8">
      <div class="card-body">
        <p class="card-text">Price: <b>{{plan.price}}€</b>
        <br>
        Videos per funeral: <b>{{plan.videosPerEvent}}</b>
        <br>
         Number of funerals: <b>{{plan.typeOfPlan==4 ? "∞" : plan.numberOfEvents}}</b>
        </p>
        <div>
        <button v-if="!plan.isDeleted"   class="command btn btn-primary btn-sm" @click="openUpdateForm">Update</button>
        <button v-if="!plan.isDeleted"  class="command btn btn-primary btn-sm btn-danger"  @click="openDeleteDialog">Delete</button>
        <button v-if="!plan.isDeleted"  class="command btn btn-primary btn-sm"  @click="openRegistrationDialog" data-toggle="tooltip" data-placement="right" title="Get Registration URL"> Get URL</button>
        <button v-if="plan.isDeleted"  class="btn btn-outline-danger btn-sm ml-3"  @click="openRestoreDialog">Restore</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
    props:{
        plan:{
        paymentPlanId:Number,
        name:String,
        price:Number,
        numberOfEvents: Number,
        videosPerEvent:Number,
        displayColor:String,
        isDeleted:Boolean
        }
    },
    data(){
      return{
        show:false
      }
    },
    computed:{
      planColor(){
        return {backgroundColor: `${this.plan.displayColor}`};
      },
      upperLeter(){
        var words = this.plan.name.split(" ");
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
          return words.join(" ");
        }
    },
    methods:{
      openInfoDialog(){
         this.$emit('openInfoDialog',this.plan.paymentPlanId)
        },
      openDeleteDialog(){
        this.$emit('openDeleteDialog',this.plan.paymentPlanId)
      },
      openRestoreDialog() {
        var formData = {
          paymentPlanId: this.plan.paymentPlanId,
          isDeleted: false
        }
        this.$emit('openRestoreDialog', formData)
      },
      openUpdateForm(){
        this.$emit('openUpdateForm',this.plan.paymentPlanId)
      }, 
      openRegistrationDialog() {
         this.$emit('openRegistrationDialog',this.plan.paymentPlanId)
      } 
    }
}
</script>

<style scoped>
.card{
    border-radius: 0%;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
.card-title{
  color: white;
  font-size: 130%;

}
.title-field{
overflow: hidden;
word-break: break-all;
justify-content: center;
text-align: center;
display: flex;
align-items: center;
/* border-radius: 10%;
box-shadow: 6px 6px 5px grey; */
}

.title-field:hover{
 cursor: pointer;
 /* border: 2px solid rgb(0, 174, 255); */
}

.command-column {
  width: 340px;
}
.command {
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
}
.data-table {
  margin-top: 30px;
}
.wrapper {
  width: 70%;
  margin: auto;
  min-width: 1024px;
}

.add-command {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tooltip{
 color: black; 
}
</style>