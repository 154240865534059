<template>
  <div class="row" style="height:calc(100vh - 256px)">
    <div class="content col-9">
      <div class="image" :class="coverPhoto.background">
        <img v-if="previewData" :src="previewData" />
      </div>
    </div>
    <div class="form col-3">
      <button @click="saveCoverPhoto()" class="btn btn-primary save-button" type="button"><i class="fa fa-save"></i> {{'Save' | translate}}</button>
      <form>
        <div class="form-label-group" style="margin-top: 16px;">
          <input id="coverPhotoTitle" @change="change" v-model="coverPhoto.title" class="form-control" :placeholder="'Title' | translate" autofocus />
          <label for="coverPhotoTitle">{{'Title' | translate}}</label>

          <button @click="titleOpen = !titleOpen" type="button" class="btn btn-default advanced-controls-button"><i :class="{'fa-plus': !titleOpen, 'fa-minus': titleOpen}" class="fa"></i></button>
        </div>
        <div v-if="titleOpen" class="advanced-controls">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button @click="coverPhoto.titleAlignment = 'left'; change()" type="button" :class="{active: coverPhoto.titleAlignment == 'left'}" class="btn btn-default"><i class="fa fa-align-left"></i></button>
            <button @click="coverPhoto.titleAlignment = 'center'; change()" type="button" :class="{active: coverPhoto.titleAlignment == 'center'}" class="btn btn-default"><i class="fa fa-align-center"></i></button>
            <button @click="coverPhoto.titleAlignment = 'right'; change()" type="button" :class="{active: coverPhoto.titleAlignment == 'right'}" class="btn btn-default"><i class="fa fa-align-right"></i></button>
          </div>
          <input @change="change()" v-model="coverPhoto.titleFontSize" class="form-control font-size" max="90" min="12" type="number"/>
          <v-swatches @input="change()" v-model="coverPhoto.titleColor" :swatch-size="26" shapes="squares" :border-radius="0" :show-border="false" :spacing-size="0" colors="text-advanced" :swatches="colors" row-length="10" popover-to="left"></v-swatches>
          <select @change="change" v-model="coverPhoto.titleFont" class="form-control font-family" :placeholder="'Font' | translate" required>
            <option v-for="font in fonts" :key="font" :value="font">{{font}}</option>
          </select>
        </div>
        
        <div class="form-label-group">
          <input @change="change" v-model="coverPhoto.nameOfTheDeceased" type="text" id="inputName" class="form-control" :placeholder="'Name of the Deceased *' | translate" required>
          <label for="inputName">{{'Name of the Deceased *' | translate}}</label>

          <button @click="nameOfTheDeceasedOpen = !nameOfTheDeceasedOpen" type="button" class="btn btn-default advanced-controls-button"><i :class="{'fa-plus': !nameOfTheDeceasedOpen, 'fa-minus': nameOfTheDeceasedOpen}" class="fa"></i></button>
        </div>
        <div v-if="nameOfTheDeceasedOpen" class="advanced-controls">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button @click="coverPhoto.nameOfTheDeceasedAlignment = 'left'; change()" type="button" :class="{active: coverPhoto.nameOfTheDeceasedAlignment == 'left'}" class="btn btn-default"><i class="fa fa-align-left"></i></button>
            <button @click="coverPhoto.nameOfTheDeceasedAlignment = 'center'; change()" type="button" :class="{active: coverPhoto.nameOfTheDeceasedAlignment == 'center'}" class="btn btn-default"><i class="fa fa-align-center"></i></button>
            <button @click="coverPhoto.nameOfTheDeceasedAlignment = 'right'; change()" type="button" :class="{active: coverPhoto.nameOfTheDeceasedAlignment == 'right'}" class="btn btn-default"><i class="fa fa-align-right"></i></button>
          </div>
          <input @change="change()" v-model="coverPhoto.nameOfTheDeceasedFontSize" class="form-control font-size" max="90" min="12" type="number"/>
          <v-swatches @input="change()" v-model="coverPhoto.nameOfTheDeceasedColor" :swatch-size="26" shapes="squares" :border-radius="0" :show-border="false" :spacing-size="0" colors="text-advanced" :swatches="colors" row-length="10" popover-to="left"></v-swatches>
          <select @change="change" v-model="coverPhoto.nameOfTheDeceasedFont" class="form-control font-family" :placeholder="'Font' | translate" required>
            <option v-for="font in fonts" :key="font" :value="font">{{font}}</option>
          </select>
        </div>

        <div class="form-label-group">
          <input @change="change" v-model="coverPhoto.bornDied" type="text" id="inputBornDied" class="form-control" :placeholder="'Born - Died *' | translate" required>
          <label for="inputBornDied">{{'Born - Died *' | translate}}</label>

          <button @click="bornDiedOpen = !bornDiedOpen" type="button" class="btn btn-default advanced-controls-button"><i :class="{'fa-plus': !bornDiedOpen, 'fa-minus': bornDiedOpen}" class="fa"></i></button>
        </div>
        <div v-if="bornDiedOpen" class="advanced-controls">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button @click="coverPhoto.bornDiedAlignment = 'left'; change()" type="button" :class="{active: coverPhoto.bornDiedAlignment == 'left'}" class="btn btn-default"><i class="fa fa-align-left"></i></button>
            <button @click="coverPhoto.bornDiedAlignment = 'center'; change()" type="button" :class="{active: coverPhoto.bornDiedAlignment == 'center'}" class="btn btn-default"><i class="fa fa-align-center"></i></button>
            <button @click="coverPhoto.bornDiedAlignment = 'right'; change()" type="button" :class="{active: coverPhoto.bornDiedAlignment == 'right'}" class="btn btn-default"><i class="fa fa-align-right"></i></button>
          </div>
          <input @change="change()" v-model="coverPhoto.bornDiedFontSize" class="form-control font-size" max="90" min="12" type="number"/>
          <v-swatches @input="change()" v-model="coverPhoto.bornDiedColor" :swatch-size="26" shapes="squares" :border-radius="0" :show-border="false" :spacing-size="0" colors="text-advanced" :swatches="colors" row-length="10" popover-to="left"></v-swatches>
          <select @change="change" v-model="coverPhoto.bornDiedFont" class="form-control font-family" :placeholder="'Font' | translate" required>
            <option v-for="font in fonts.filter(x=> x !== 'Ananda Black')" :key="font" :value="font">{{font}}</option>
          </select>
        </div>
        <!-- <label for="coverPhotoFileInput" class="btn btn-light btn-block" style="position: relative">
          <i class="fa fa-pencil"></i> {{'Pick Cover Photo' | translate}}
        </label> -->
        <!-- <input type="file" id="coverPhotoFileInput" style="visibility: hidden; position: absolute; width: 1px; height: 1px;" /> -->
        
        <!-- <div class="form-label-group">
          <input @change="change" v-model="coverPhoto.fontSize" type="number" min="10" max="120" id="fontSize" class="form-control" :placeholder="'Text Size' | translate" required>
          <label for="fontSize">{{'Text Size' | translate}}</label>
        </div>
        <div class="form-label-group">
          <select @change="change" v-model="coverPhoto.fontType" id="fontType" class="form-control" :placeholder="'Font' | translate" required>
            <option value="Arial">Arial</option>
            <option value="Courier New">Courier New</option>
            <option value="Tahoma">Tahoma</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Vivaldi">Vivaldi</option>
          </select>
          <label for="fontType">{{'Font' | translate}}</label>
        </div> -->
        <div class="form-label-group">
          <select @change="change" v-model="coverPhoto.background" id="background" class="form-control" :placeholder="'Background' | translate" required>
            <option value="branch.jpg">Branch</option>
            <option value="flower1.jpg">Flower 1</option>
            <option value="flower2.jpg">Flower 2</option>
            <option value="flower3.jpg">Flower 3</option>
            <option value="roses.jpg">Roses</option>
            <option value="black">Black</option>
            <option value="white">White</option>
            <option value="custom">Custom</option>
          </select>
          <label for="background">{{'Background' | translate}}</label>
        </div>
        <div v-if="coverPhoto.background === 'custom'">
          <button class="btn btn-light btn-block pick-photo-button" @click="$refs.backgroundChooserModal.open()" type="button"><i class="fa fa-pencil"></i> {{'Pick Background Photo' | translate}}</button>
          <button @click="removeBackgroundPhoto()" type="button" v-if="coverPhoto.coverPhotoBackgroundContentId > 0" class="btn btn-light fa fa-trash remove-image-button"></button>
        </div>
        <button class="btn btn-light btn-block pick-photo-button" @click="$refs.contentChooserModal.open()" type="button"><i class="fa fa-pencil"></i> {{'Pick Portrait Photo' | translate}}</button>
        <button @click="removeCoverPhoto()" type="button" v-if="coverPhoto.coverPhotoContentId > 0" class="btn btn-light fa fa-trash remove-image-button"></button>
      </form>
    </div>
    <sweet-modal class="cover-photo-select" title="Select Background Photo" ref="backgroundChooserModal" width="80%">
      <ContentList :change="backgroundChange" :chooser="true" :single="true" additional-filter="images" :modalRef="$refs.backgroundChooserModal"></ContentList>
    </sweet-modal>
    <sweet-modal class="cover-photo-select" title="Select Portrait Photo" ref="contentChooserModal" width="80%">
      <ContentList :change="coverPhotoChange" :chooser="true" :single="true" additional-filter="images" :modalRef="$refs.contentChooserModal"></ContentList>
    </sweet-modal>
  </div>
</template>
<script>
import api from '@/api'
import util from '@/util'
import ContentList from '@/components/common/ContentList'
import VSwatches from 'vue-swatches'
export default {
  components: {
    ContentList,
    VSwatches
  },
  props: {
    modalRef: Object,
    item: Object,
    close: Function
  },
  data () {
    return {
      titleOpen: false,
      nameOfTheDeceasedOpen: false,
      bornDiedOpen: false,
      coverPhoto: this.getDefaultCoverPhoto(),
      previewData: null,
      colors: util.getColors(),
      fonts: util.getFonts()
    }
  },
  methods: {
    getDefaultCoverPhoto () {
      return {
        title: '',
        titleColor: '#4D4D4D',
        titleFontSize: 54,
        titleFont: 'Ahellya',
        titleAlignment: 'left',
        nameOfTheDeceased: '',
        nameOfTheDeceasedColor: '#4D4D4D',
        nameOfTheDeceasedFontSize: 72,
        nameOfTheDeceasedFont: 'Ahellya',
        nameOfTheDeceasedAlignment: 'left',
        bornDied: '',
        bornDiedColor: '#4D4D4D',
        bornDiedFontSize: 54,
        bornDiedFont: 'Ahellya',
        bornDiedAlignment: 'left',
        fontType: 'Vivaldi',
        background: 'white',
        coverPhotoContentId: -1,
        coverPhotoBackgroundContentId: -1
      }
    },
    saveCoverPhoto () {
      api.video.saveCoverPhoto(this.coverPhoto, this.$store.getters.getSelectedFolderId).then(() => {
        this.$store.dispatch('getUserContentList')
        this.$store.dispatch('getEventList', util.getSessionInfo().userId)
        this.$store.commit('setBasicEventInfo', {title: this.coverPhoto.title, name: this.coverPhoto.nameOfTheDeceased, year: this.coverPhoto.bornDied})
        this.coverPhoto = this.getDefaultCoverPhoto()
        this.updateInfo()
        this.previewData = null
      })
      this.close()
    },
    change () {
      // switch (this.coverPhoto.background) {
        // case 'black':
        //   this.coverPhoto.bornDiedColor = '#F3F3F3'
        //   this.coverPhoto.nameOfTheDeceasedColor = '#F3F3F3'
        //   this.coverPhoto.titleColor = '#F3F3F3'
        //   break
        // default:
        //   this.coverPhoto.bornDiedColor = '#000000'
        //   this.coverPhoto.nameOfTheDeceasedColor = '#000000'
        //   this.coverPhoto.titleColor = '#000000'
        // break
      // }
      api.video.getCoverPhotoPreview(this.coverPhoto).then(result => {
        this.previewData = result.data
      })
    },
    updateInfo () {
      this.coverPhoto.title = this.coverPhoto.title || this.item.introductoryText
      this.coverPhoto.nameOfTheDeceased = this.coverPhoto.nameOfTheDeceased || this.item.mainText
      this.coverPhoto.bornDied = this.coverPhoto.subText || this.item.subText
    },
    coverPhotoChange (contentId) {
      this.$store.commit('setCoverPhotoContentId', contentId)
      this.coverPhoto.coverPhotoContentId = this.$store.getters.coverPhotoContentId
      this.change()
    },
    backgroundChange (contentId) {
      this.coverPhoto.coverPhotoBackgroundContentId = contentId
      this.change()
    },
    removeCoverPhoto () {
      this.coverPhoto.coverPhotoContentId = -1
      this.change()
    },
    removeBackgroundPhoto () {
      this.coverPhoto.coverPhotoBackgroundContentId = -1
      this.change()
    }
  }
}
</script>
<style lang="css" scoped>
.form {
  float: right;
}
.content {
  height: 520px;
  text-align: center;
}
.content .image {
  display: inline-block;
  width: 864px;
  height: 488px;
  border: 1px solid gray;
  padding: 1px;
}
.content .image img {
    width: 860px;
    height: 484px;
}
.content .image.black {
  background: black;
}
.content .image.white {
  background: white;
}
.save-button {
  margin-bottom: 12px;
  float: right;
}
.btn-group .active {
  background-color: lightgray;
}
.advanced-controls-button {
  height: 38px;
  margin-left: 10px;
}
.advanced-controls {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.advanced-controls .font-size {
  width: 68px;
  margin-left: 15px;
  margin-right: 15px;
}
.advanced-controls .vue-swatches >>>.vue-swatches__trigger  {
  height: 38px !important;
  width: 38px !important;
  border: 1px solid lightgray;
}
.advanced-controls .btn-group {
  height: 38px;
}
.pick-photo-button {
  margin-top: 16px;
  display: inline-block;
  width: 394px;
}
.remove-image-button {
  margin-top: 16px;
  height: 38px;
  margin-left: 10px;
}
.font-family {
  margin-left: 10px;
}
</style>