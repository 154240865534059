<template>
  <div :class="{'gray-out': isUploading}">
    <div class="share-content" :class="{chooser: chooser}">
      <img alt="EFuneral" src="../../assets/logo.png">
      <br/>
      <h3></h3>
      <span 
        v-show="item.userContentTypeId !== 4 && (filter === 'all' || (item.userContentTypeId === 2 && filter === 'images' || item.userContentCreationTypeId === 3 && filter === 'created' || item.userContentTypeId === 1 && filter === 'videos' || item.userContentTypeId === 3 && filter === 'music'))"
        :class="[{selected: selectedUserContent.indexOf(item) !== -1}, item.userContentTypeName]"
        class="content-item"
        v-for="(item, $index) in userContentList"
        :key="$index"
        >
      
        <template v-if="item.userContentTypeId === 1 && (filter === 'all' || filter === 'videos' || (item.userContentCreationTypeId === 3 && filter === 'created'))">
          <i v-if="!chooser && item.previewDataLink || item.userContentDataLink" @click.stop="playVideo(item)" class="btn btn-light fa fa-play command-icon play-icon"></i>
          <a v-if="!chooser && item.userContentDataLink" class="btn btn-light fa fa-download command-icon download-icon" :href="API_URL + 'api/get-user-content-data-download/' + item.userContentId"><i class=""></i></a>
          <a v-if="!chooser && !item.userContentDataLink && item.previewDataLink" class="btn btn-light fa fa-download command-icon download-icon" :href="API_URL + 'api/get-user-content-preview-data/' + item.userContentId"><i class=""></i></a>
          <div v-if="!item.thumbnailDataLink" class="rounded thumb-icon">
            <i class="fa fa-play video-icon fa-4x"></i>
          </div>
          <span v-if="!item.thumbnailDataLink" class="file-name">{{item.fileName}}</span>
          <img v-if="item.thumbnailDataLink" style="max-height: 200px; margin: 5px" :src="S3_URL + item.thumbnailDataLink" class="rounded">
        </template>
        <template v-if="(item.userContentTypeId === 2 || item.userContentTypeId === 4) && (filter === 'all' || filter === 'images' || (item.userContentCreationTypeId === 3 && filter === 'created'))">
          <a onclick="function(e){e.stopPropagation();}" v-if="!chooser" class="btn btn-light fa fa-download command-icon download-icon" :href="API_URL + 'api/get-user-content-data-download/' + item.userContentId"><i class=""></i></a>
          <img style="max-height: 200px; margin: 5px" :src="S3_URL + item.thumbnailDataLink" class="rounded">
        </template>
      </span>
      
    </div>
    <sweet-modal ref="playVideoModal" class="play-video-modal" title="Video" @close="closeVideoPlayer()">
      <h5>Preview</h5>
      <template v-if="itemToPlay.userContentCreationTypeId === 3">
        <video style="height: 480px; width: 720px;" ref="videoPlayer" :src="S3_URL + itemToPlay.previewDataLink" controls></video>
        <br />
        <br />
        <a v-if="itemToPlay.userContentDataLink" target="_blank" :href="S3_URL + itemToPlay.userContentDataLink" class="btn btn-default">Open Completed Video</a>
      </template>
      <video v-if="itemToPlay.userContentCreationTypeId !== 3" style="height: 480px; width: 720px;" ref="videoPlayer" :src="S3_URL + itemToPlay.userContentDataLink" controls></video>
      <!-- <br />
      <br />
      <a v-if="itemToPlay.userContentDataLink" :download="itemToPlay.name + 'mp4'" :href="S3_URL + itemToPlay.userContentDataLink" class="btn btn-default">Download Completed Video</a> -->
    </sweet-modal>
  </div>
</template>
<script>
import api from '@/api'
import config from '@/config'
import util from '@/util'
// import PhotoEditor from '@/components/common/PhotoEditor'
// import VideoCreator from '@/components/video/VideoCreator'
export default {
  props: {
    chooser: Boolean,
    single: Boolean,
    additionalFilter: String,
    modalRef: Object,
    openVideoCreator: Function,
    change: Function,
    readonly: Boolean
  },
  data () {
    return {
      userContentList: [],
      uploadUpdateKey: true,
      itemToDelete: -1,
      itemToPlay: '',
      musicItemToPlay: '',
      options: {
        target: `${config.API_URL}api/add-user-content${util.getUserContextUrlParam()}`,
        testChunks: false,
        chunkSize: 1000000000,
        allowDuplicateUploads: true,
        headers: {
          Authorization: `Bearer ${util.getSessionData().access_token}`
        },
        successStatuses: [200,201, 202, 204]
      },
      attrs: {
        accept: '.jpg,.png,.jpeg,.jpe,.mp4,.gif,.mov,.mp3'
      },
      errorMessage: '',
      uploading: false
    }
  },
  components: {
    // PhotoEditor //,
    // VideoCreator
  },
  computed: {
    uploadUpdate () {
      return this.uploadUpdateKey
    },
    isUploading () {
      return this.uploading
    },
    filter () {
      return this.$store.getters.contentFilter
    },
    API_URL () {
      return config.API_URL
    },
    S3_URL () {
      return config.S3_URL
    },
    userImages () {
      return this.$store.getters.userContentList(this.additionalFilter, this.chooser).filter((item)=> {
        return item.userContentTypeId === 2
      })
    },
    selectedUserContent () {
      return this.$store.getters.selectedUserContent
    },
    filesExists () {
      return this.$store.getters.userContentList(this.additionalFilter, this.chooser).length > 0
    },
    noImagesExists () {
      return this.filter === 'images' && this.$store.getters.userContentList(this.additionalFilter, this.chooser).filter((item) => { 
          return item.userContentTypeId === 2 
          }).length === 0
    },
    noVideosExists () {
      return this.filter === 'videos' && this.$store.getters.userContentList(this.additionalFilter, this.chooser).filter((item) => { 
          return item.userContentTypeId === 1 
          }).length === 0
    },
    noMusicExists () {
      return this.filter === 'music' && this.$store.getters.userContentList(this.additionalFilter, this.chooser).filter((item) => { 
          return item.userContentTypeId === 3
          }).length === 0
    },
    noCreatedExists () {
      return this.$store.getters.userContentList(this.additionalFilter, this.chooser).filter((item) => { 
          return item.userContentCreationTypeId === 3 
          }).length === 0
    }
  },
  mounted () {
    api.userContent.getUserShareableContentList(this.$route.params.guid).then(result => {
      this.userContentList = result.data
    })
  },
  methods: {
    dragstart (ev, item) {
      this.$store.commit('setDragInProgress', true)
      ev.dataTransfer.setData("text/plain", JSON.stringify(item));
    },
    playVideo (item) {
      this.$refs.playVideoModal.open()
      this.itemToPlay = item
    },
    playMusic (item) {
      this.$refs.playMusicModal.open()
      this.musicItemToPlay = item.userContentDataLink
    },
    closeVideoPlayer () {
      this.$refs.videoPlayer.pause()
    },
    closeMusicPlayer () {
      this.$refs.musicPlayer.pause()
    },
    editPhoto (index) {
      this.$refs.photoEditor.open(index)
    },
    editVideo (item) {
      this.openVideoCreator(item)
    },
    deleteItem (item) {
      if (!item.deletable) {
        this.$refs.deleteError.open()
      } else {
        this.itemToDelete = item.userContentId
        this.$refs.confirmDelete.open()
      }
    },
    confirmDelete () {
      this.$refs.confirmDelete.close()
      api.userContent.deleteUserContent(this.itemToDelete).then((result) => {
        // eslint-disable-next-line
        if(result.data) {
          this.errorMessage = result.data
          this.$refs.deleteError.open()
        } else {
          this.$store.dispatch('getUserContentList')
        }
      })
    },
    toggle (item, index, event) {
      if (event.target.tagName === 'A') { // in case of download action prevent item click
        return
      }
      // if (!this.chooser && !this.readonly) {
      //   let imageIndex = 0
      //   switch (item.userContentTypeId) {
      //     case 1:
      //       if (item.userContentDataLink) {
      //         this.playVideo(item)
      //       } else {
      //         this.editVideo(item)
      //       }
      //       return
      //     case 2: 
      //       imageIndex = this.userImages.indexOf(item)
      //       this.$refs.photoEditor.open(imageIndex)
      //       return
      //     case 3: 
      //       this.playMusic(item)
      //       return
      //   }
      // }
      // if (this.single) {
      //   if (this.change) {
      //     this.change(item.userContentId)
      //   }
      //   this.modalRef.close()
      //   return
      // }
      // if (this.chooser) {
        // let videoElement = document.getElementById(item.userContentDataLink)
        // let duration = videoElement && videoElement.duration
        // item.duration = duration || 0

        if (this.single) {
          if (this.change) {
            this.change(item.userContentId)
          }
          this.modalRef.close()
          return
        }
        let len = this.selectedUserContent.length

        if (event.shiftKey && len) {
          let lastEl = this.selectedUserContent[len - 1]
          let indexFrom = this.userContentList.indexOf(lastEl)
          let itemIndex = this.userContentList.indexOf(item)
          for (let i = indexFrom + 1; i <= itemIndex; i++) {
            if (this.selectedUserContent.indexOf(this.userContentList[i]) === -1) {
              this.$store.commit('toggleUserContent', this.userContentList[i], event.shiftKey)
            }
          }
        } else {
          this.$store.commit('toggleUserContent', item, false)
        }
      // }
    }
  }
}
</script>
<style lang="css" scoped>

.content-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 2px;
  border: 2px solid transparent;
  margin: 5px;
  cursor: pointer;
}
.content-item.video .thumb-icon,
.content-item.audio .thumb-icon{
  border: 1px solid lightgray;
  width: 210px;
  height: 147px;
  padding: 2px;
  text-align: center;
  background-color: #fff;
}
.content-item.video .thumb-icon .video-icon,
.content-item.audio .thumb-icon .audio-icon {
  margin-top: 34px;
}
.content-item.selected {
  border: 2px solid #007bff;
  border-radius: 3px;
}
.content-item:hover .command-icon{
  display: block;
}

.delete-icon {
   top: 44px;
}
.download-icon {
  top: 78px;
}
.play-icon {
  top: 112px;
}
.play-video-modal {
  text-align: center;
}
.file-name {
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  height: 35px;
  white-space: nowrap;
  font-size: 10px;
  margin-top: -20px;
  margin-left: 5px;
}
.upload-button {
  border-radius: 50%;
  /* color: #ffffff; */
  font-size: 34px;
  /* background-color: #007bff; */
  width: 64px;
  height: 64px;
  padding-top: 9px;
}
.uploader-example {
  display: inline-block;
  height: 100px;
  width: 100px;
  padding: 10px;
  margin-top: 28px;
  margin-left: 32px;
}
.gray-out {
  opacity: .5;
}
.video-badge {
  position: absolute;
  left: 12px;
  top: 12px;
}
.video-progress-spinner {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}
.share-content {
  text-align: center;
}
.play-video-modal >>>.sweet-modal {
  max-width: 790px;
}
</style>