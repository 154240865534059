<template>
  <div class="wrapper">
    <form class="form-signin">
      <img alt="EFuneral" src="../../assets/logo.png">
      <div v-if="!success" class="alert alert-primary" role="alert">
        {{'Please set password to register you E-Funeral account.' | translate}}
      </div>
      <div v-if="!success" class="form-label-group" :class="{ 'form-group--error': $v.password.$error }">
        <input  :class="{ 'is-invalid': $v.password.$error }" v-model="password" type="password" id="inputPassword" class="form-control" :placeholder="'Password' | translate" required>
        <label for="inputPassword">{{'Password' | translate}}</label>
      </div>
      <div v-if="!success" class="form-label-group" :class="{ 'form-group--error': $v.password2.$error }">
        <input  :class="{ 'is-invalid': $v.password2.$error }" v-model="password2" type="password" id="inputPassword2" class="form-control" :placeholder="'Retype Password' | translate" required>
        <label for="inputPassword2">{{'Retype Password' | translate}}</label>
      </div>
      <div v-if="!$v.password.minLength || !$v.password2.minLength" class="alert alert-danger" role="alert">
        {{'Password must be at least 8 characters long.' | translate}}
      </div>
      <div v-if="notMatch" class="alert alert-danger" role="alert">
        {{'Password must match.' | translate}}
      </div>
      <div v-if="error" class="alert alert-danger" role="alert">
        {{'Password set failed.' | translate}}
      </div>
      <div v-if="success" class="alert alert-success" role="alert">
        {{'Password is set.' | translate}}
      </div>
      <button v-if="!success" class="btn btn-lg btn-primary btn-block"  @click="register()" type="button">{{'Register' | translate}}</button>
      <router-link v-if="success" to="/login"><a>{{'Go to Login' | translate}}</a></router-link>
    </form>
  </div>
</template>

<script>

import api from '@/api'
// import Cookie from "vue-cookie";
import { required, minLength } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      password: '',
      password2: '',
      error: false,
      success: false,
    } 
  },
  computed: {
    notMatch () {
      return this.password2.length && this.password.length && this.password2 !== this.password
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(8)
    },
    password2: {
      required,
      minLength: minLength(8)
    }
  },
  methods: {
    register() {
      this.$v.$touch()
      if (!this.$v.$invalid && !this.notMatch) {
        this.error = false
        api.users.finishRegistration({
          password: this.password,
          key: this.$route.params.key
          }).then(() => {
            this.success = true
          }, () => {
            this.error = true
        })
      }
    }
  }
};
</script>

<style scoped>



button {
  width: 100%;
}
.error-height {
  height: 16px;
}
.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }
  .form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin {
  text-align: center;
}

.form-signin .form-control:focus {
  z-index: 2;
}
.form-label-group {
  text-align: left;
}
</style>
