<template>
  <div class="wrapper">
    <form class="form-signin">
      <img alt="EFuneral" src="../../assets/logo.png">
      <div class="alert alert-primary" role="alert">
        {{'Password reset link will be sent to your email address.' | translate}}
      </div>
      <div class="form-label-group">
        <input :class="{ 'is-invalid': $v.email.$error }" type="email" v-model="email" id="inputEmail" class="form-control" :placeholder="'Email address' | translate" required autofocus>
        <label for="inputEmail">{{'Email address' | translate}}</label>
      </div>
      <div v-if="error" class="alert alert-danger" role="alert">
        {{'Invalid email.' | translate}}
      </div>
      <div v-if="success" class="alert alert-success" role="alert">
        {{'Email is sent.' | translate}}
      </div>
      <button class="btn btn-lg btn-primary btn-block"  @click="sendLink()" type="button">{{'Send Link' | translate}}</button>
    </form>
    
  </div>
</template>

<script>
import Vue from "vue";
import api from '@/api'
// import Cookie from "vue-cookie";
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      email: '',
      error: false,
      success: false
    } 
  },
  validations: {
    email: {
      required
    }
  },
  methods: {
    sendLink() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.error = false
        api.users.requestPasswordReset({
          email: this.email,
          language: localStorage.lang || 'en',
          subject: Vue.i18n.translate('E-Funeral - Password Reset')
          }).then(() => {
            this.success = true
          }, () => {
            this.error = true
        })
      }
    }
  }
};
</script>

<style scoped>



button {
  width: 100%;
}
.error-height {
  height: 16px;
}
.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }
  .form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin {
  text-align: center;
}

.form-signin .form-control:focus {
  z-index: 2;
}
.form-label-group {
  text-align: left;
}
</style>
