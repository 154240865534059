<template>
      <tr>
        <td>{{customer.customerName}}</td>
        <td v-if="customer.paymentPlan != null">  {{customer.paymentPlan.name}}</td>
        <td v-else> There is no payment plan  </td>
        <td v-if="customer.paymentPlan == null || (customer.paymentPlan != null && customer.startDatePaymentPlan == null)">       
            <div class="form-group" style="margin:5px">
              <div class="input-group">
                <span class="input-group-addon">
                  <select v-model="paymentPlanId" class="set form-control form-control-sm" :placeholder="'Choose your payment plan' | translate" required style="height:27px">
                      <option v-for="paymentPlan in paymentPlans" :key="paymentPlan.paymentPlanId" :value="paymentPlan.paymentPlanId">{{paymentPlan.name}}</option> 
                  </select>
                </span>
                <span class="input-group-addon"> 
                  <button class="set btn btn-primary btn-sm" type="button" @click="setPaymentPlan()" style="margin-left:12px;">
                    Set
                  </button> 
                </span> 
              </div>
            </div> 
        </td> 
        <td v-else>       
            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon"> 
                  <div class="paymentPlanName"> {{customer.paymentPlan.name}} </div>
                </span> 
              </div>
            </div>
        </td>
        <td v-if="customer.paymentPlanId != null && getPaymentPlanFromId(customer.paymentPlanId).typeOfPlan == 4"> Free payment plan is active  </td>
        <td v-else-if="customer.paymentPlan != null && customer.startDatePaymentPlan">{{customer.startDatePaymentPlan | formatDateTime}}</td>
        <td v-else> Payment plan is not active  </td>
        <td v-if="customer.stripeCouponId == null"> There is no discount </td>
        <td v-else> Discount is {{discount}}</td> 

        <td v-if="customer.stripeCouponId == null">
          <button type="button" class="options btn btn-primary btn-sm" @click="addDiscountModal()"> Select </button> 
         </td>
        <td v-if="customer.stripeCouponId != null"> 
           <button type="button" class="options btn btn-danger btn-sm"  @click="removeDiscountModal()"> Remove </button> 
        </td>
        <sweet-modal :blocking="true" ref="chooseCouponModal" title="Choose Discount" width="600px">
            <div class="wrapper">
              <div class="sub-form-group">
                <label for="stripeCouponId">{{'Choose Discount' | translate}}</label>
                  <select v-model="selectedCupon"  id="stripeCouponId" class="form-control" :placeholder="'Stripe Coupon Id' | translate" >
                    <option v-for="(coupon, $index) in couponsList" :key="$index" :value="coupon.id">{{coupon.name}}</option>
                  </select>
            <br/>
            <button type="button" class="command btn btn-primary btn-sm" style="padding:5px;" @click="applyDiscount()"> Apply </button>
            <button type="button" class="command btn btn-light btn-sm" style="padding:5px;"  @click="$refs.chooseCouponModal.close()"> Cancel </button>
           </div>
         </div>      
         </sweet-modal>
        <sweet-modal ref="confirmRemove" icon="warning">
          You are about to remove this discount. Are you sure?
          <div>
            <button class="command btn btn-light btn-sm"  style="padding:5px;"  @click="$refs.confirmRemove.close()" type="button">Cancel</button>
            <button class="command btn btn-danger btn-sm"  style="padding:5px;" @click="removeDiscount()" type="button">Remove</button>
          </div>
         </sweet-modal>
  </tr>
</template>
<script>
import api from '@/api'
export default {
  
  props: {
    customer : Object,
  },
  data() {
    return {
      discount: null,
      selectedCupon:"",
      paymentPlanId : null,
      filter : {
        page:1,
        pagesize: 1000,
        isDeleted: false 
      }
    }
  },
  computed: {
    couponsList() {
      return this.$store.getters.getCouponsList
    },
    paymentPlans() {
      return this.$store.getters.getPaymentPlansForCustomers.data
    }
  },
  created() {
    this.getDiscount()
  },
  mounted() {
    this.$store.dispatch('getPaymentPlansForCustomers', this.filter);
  },
  methods : {
    setPaymentPlan() {
      if(this.paymentPlanId != null) {
          this.customer.paymentPlanId = this.paymentPlanId;
          this.$store.dispatch('updateCustomer', this.customer);
      }	
      this.paymentPlanId = null
    },
    addDiscountModal() {
      this.$refs.chooseCouponModal.open()
    },
    removeDiscountModal() {
      this.$refs.confirmRemove.open()
    },
   applyDiscount(){
      if(this.selectedCupon != "") {
        this.customer.stripeCouponId = this.selectedCupon;
        this.$store.dispatch('updateCustomer', this.customer);
        this.getDiscount()
        this.$refs.chooseCouponModal.close();
      }
    },
    removeDiscount(){
      this.customer.stripeCouponId = null;
      this.$store.dispatch('updateCustomer', this.customer);
      this.$refs.confirmRemove.close()
    },
    getDiscount() {
      if(this.customer.stripeCouponId != null) {
          api.coupons.getCouponById(this.customer.stripeCouponId).then(response => {
            this.discount = response.data.coupon.percent_off!=null? response.data.coupon.percent_off+"%": (response.data.coupon.amount_off/100) +response.data.coupon.currency; 
        })
      }
    },
    getPaymentPlanFromId(customerPaymentPlanId) {
        for (var paymentPlan of this.$store.getters.getPaymentPlansForCustomers.data) {
          if(customerPaymentPlanId == paymentPlan.paymentPlanId) {
            return paymentPlan
          }
      }
    }
  }
}
</script>
<style scoped>
.command-column {
  width: 340px;
}
.command {
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  /* margin-bottom: 5px; */
}

.set {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom:5px;
  margin-top: 5px;
}

.options {
  margin-left: 5px;
  margin-top: 10px;
  margin-right: 5px;
}

.paymentPlanName {
   margin-left: 5px;
  /* margin-top: 10px;  */
  margin-right: 5px;
  margin-bottom:10px;
}
.data-table {
  margin-top: 30px;
}
.wrapper {
  width: 50%;
  margin: auto;
}
.add-command {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.btn-sm {
  width: 95px;
}
td {
  min-width: 150px;
}
</style>