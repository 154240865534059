import Vue from 'vue'
export default {
  setError (state, message) {
    state.error = { message: message, show: true }
  },
  setInfoMsg (state, message) {
    state.infoMsg = { message: message, show: true }
  },
  setUserContentList (state, userContentList) {
    state.userContentList = userContentList
  },
  setFolderList (state, folderList) {
    state.folderList = folderList
  },
  setSelectedFolderId (state, selectedFolderId) {
    state.selectedFolderId = selectedFolderId
  },
  resetSelectedFolderId (state) {
    state.selectedFolderId = -1
  },
  setThemesList (state, themesList) {
    state.themesList = themesList
  },
  setStockMusic (state, stockMusic) {
    state.stockMusic = stockMusic
  },
  setThemeStockSlides (state, themeStockSlides) {
    state.themeStockSlides = themeStockSlides
  },
  setThemeSlides (state, themeSlides) {
    state.themeSlides = themeSlides
  },
  setContentFilter (state, contentFilter) {
    state.contentFilter = contentFilter
  },
  selectUserContentItem (state, item) {
    state.selectedUserContent.push(item)
  },
  toggleUserContent (state, item, isShift) {
    let itemIndex = state.selectedUserContent.indexOf(item)
    // let itemIndexVS = state.selectedVideo.videoSlides.indexOf(item)
    let orderNo = state.selectedUserContent.length + 1
    if (itemIndex === -1) {
      item.orderNo = orderNo
      item.slideTime = 0
      item.transition = 'Auto'
      state.selectedUserContent.push(item)
      // state.selectedVideo.videoSlides.push(item)
    } else if (!isShift) {
      state.selectedUserContent.splice(itemIndex, 1)
      // if (itemIndexVS !== -1) {
      //   state.selectedVideo.videoSlides.splice(itemIndexVS, 1)
      // }
    }
  },
  selectAllUserContentItemsNoFilter (state, currentFolderId) {
    state.selectedUserContent.splice(0, state.selectedUserContent.length)
    let content = state.userContentList
    for (let i = 0; i < content.length; i++) {
      if ((content[i].folderId == null && currentFolderId === -1) // for root folder
      || content[i].folderId == currentFolderId) {
        state.selectedUserContent.push(content[i])
        content[i].orderNo = content[i].userContentId
        content[i].slideTime = 0
        content[i].transition = 'Auto'
        content[i].duration = 0
      }
    }
  },
  selectAllUserContentItems (state, getters) {
    state.selectedUserContent.splice(0, state.selectedUserContent.length)
    let content = getters.userContentList(null, true)
    for (let i = 0; i < content.length; i++) {
      if (content[i].userContentDataLink &&
      ((content[i].folderId == null && getters.getSelectedFolderId === -1) // for root folder
      || content[i].folderId == getters.getSelectedFolderId)) {
        state.selectedUserContent.push(content[i])
        content[i].orderNo = content[i].userContentId
        content[i].slideTime = 0
        content[i].transition = 'Auto'
        content[i].duration = 0
        // state.selectedVideo.videoSlides.push(content[i])
      }
    }
  },
  selectNoneUserContentItems (state) {
    state.selectedUserContent = []
    // state.selectedVideo.videoSlides = []
  },
  setCoverPhotoContentId (state, id) {
    state.coverPhotoContentId = id
  },
  setSelectedVideo (state, video) {
    state.selectedVideo = video
  },
  updateSelectedVideoSlides (state, slides) {
    state.selectedVideo.videoSlides = slides
  },
  resetSelectedVideo (state, obj) {
    state.selectedVideo = {
      ...obj,
      videoSlides: []
    }
    state.selectedTheme = {}
    state.selectedUserContent = []
  },
  setSelectedVideoSlides (state, videoSlides) {
    state.selectedVideoSlides = videoSlides
  },
  setSelectedTheme (state, theme) {
    state.selectedTheme = theme
  },
  setExportStatus (state, exportStatus) {
    state.exportStatus = exportStatus
  },
  setDragInProgress (state, dragInProgress) {
    state.dragInProgress = dragInProgress
  },
  setVideoSlideCropInfo (state, obj) {
    if (state.selectedVideo && state.selectedVideo.videoSlides && state.selectedVideo.videoSlides.length) {
      let index = state.selectedVideo.videoSlides.indexOf(obj.slide)
      if (index !== -1) {
        obj.slide.cropInfo = obj.cropInfo
        state.selectedVideo.videoSlides[index] = obj.slide
      }
    }
  },
  setCurrentCustomer(state, currentCustomer) {
    state.currentCustomer = currentCustomer
  },
	setCustomer(state, customer) {
		state.customer = customer;
	},
  setCurrentUser(state,currentUser) {
    state.currentUser = currentUser
  },
  setEventList (state, eventList) {
    state.eventList = eventList
  },
  setUserList (state, userList) {
    state.userList = userList
  },
  setDeviceList (state, deviceList) {
    state.deviceList = deviceList
  },
  setCustomerList (state, customerList) {
    state.customerList = customerList
  },
  setEventInfo (state, eventInfo) {
    state.eventInfo = eventInfo
  },
  setCustomerWithDefaultPaymentMethod(state, customerWithDefaultPaymentMethod) {
    state.customerWithDefaultPaymentMethod = customerWithDefaultPaymentMethod
  },
  setPaymentMethodsList(state, paymentMethodsList) {
    state.paymentMethodsList = paymentMethodsList
  },
  setPaymentPlansForCustomers(state, paymentPlansForCustomers) {
    state.paymentPlansForCustomers = paymentPlansForCustomers
  },
  setBasicEventInfo (state, payload) {
    state.eventInfo.introductoryText = payload.title
    state.eventInfo.mainText = payload.name
    state.eventInfo.subText = payload.year
  },
  setVideoState (state, payload) {
    Vue.set(state.videoStates, payload.id, payload.video)
    // state.videoStates[id] = video
  },
  setExternalMusic(state,payload){
    state.externalMusic=payload;
  },
  setLoading(state,payload){
    state.isLoading=payload;
  },
  setUrlLoading(state,payload){
    state.isUrlLoading=payload;
  },
  setExternalRecordUrl(state,payload){
    state.externalRecordUrl=payload;
  },
  setPaymentPlans(state,payload){
    state.paymentPlans=payload;
  },
  setPaymentPlan(state,payload){
    state.paymentPlan=payload;
  },
  setInvoiceLoading(state,payload){
    state.invoiceLoading=payload;
  },
  setInvoiceList (state, invoiceList) {
    state.invoiceList = invoiceList;
  },
	setCouponsList(state, couponList) {
		state.couponList = couponList;
	}
}
