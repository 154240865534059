<template>
  <div>
      <div>
        <h5 class="modal-title">You have reached the number of funerals. <br/> Do you want to upgrade to the next payment plan or continue using the existing one?</h5>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="col-md-6 ">
            <label class="modal-title" style="margin:5px;"> Your current payment plan is: </label>
                  <div class="card mb-3">
                    <div class="row no-gutters">
                      <div class="col-md-4 title-field" :style="currentPlanColor">
                        <h3 class="card-title">{{plan.name}}</h3>
                      </div>
                      <div class="col-md-8">
                        <!-- <div class="card-body"> -->
                          <p class="card-text">Price: <b>{{plan.price}}€</b>
                              <br>
                              Videos per funeral: <b>{{plan.videosPerEvent}}</b>
                              <br>
                              Number of funerals: <b>{{plan.typeOfPlan==4 ? "∞" : plan.numberOfEvents}}</b>
                          </p>
                          <div>
                          </div>
                        <!-- </div> -->
                      </div>
                    </div>
                   
                  </div>
                  <button type="button" class="command btn btn-primary" @click="continuePlan()">Renew</button>
             
            </div> 

            <div class="col-md-6">
              <label class="modal-title" style="margin:5px;"> Your next payment plan is: </label>
              <div class="card mb-3">
                    <div class="row no-gutters">
                      <div class="col-md-4 title-field" :style="planColor">
                        <h3 class="card-title">{{betterPaymentPlan.name}}</h3>
                      </div>
                      <div class="col-md-8">
                        <!-- <div class="card-body"> -->
                          <p class="card-text">Price: <b>{{betterPaymentPlan.price}}€</b>
                          <br>
                          Videos per funeral: <b>{{betterPaymentPlan.videosPerEvent}}</b>
                          <br>
                          Number of funerals: <b>{{betterPaymentPlan.typeOfPlan==4 ? "∞" : betterPaymentPlan.numberOfEvents}}</b>
                          </p>
                          <div>
                       
                          </div>
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>
                  <button type="button" class="command btn btn-primary" @click="next()">Upgrade</button>
            </div>  
          </div>
      </div>
       <div class="buttons-row" style="float:right">
          <button type="button" @click="close()" class="command btn btn-default">Cancel</button>
          <!-- <button type="button" @click="saveItem()" class="command btn btn-primary" :disabled="item.eventId && !item.Paid"><i class="fa fa-save"></i> Save</button> -->
        </div>



      <!-- <div class="modal-footer">
          <button type="button" class="btn btn-success btn-lg" data-dismiss="modal" @click="next()">Yes</button>
          <button type="button" class="btn btn-danger btn-lg" data-dismiss="modal" @click="close()">No</button>
      </div>  -->
  </div>
</template>

<script>
import api from '@/api'
export default {
  props: {
    betterPaymentPlan : Object
  },
  created() {
    this.getCurrentCustomer();
  },
  data() {
    return {
      customer: {}
    }
  },
  computed: {
    plan(){
      return this.$store.getters.getSinglePaymentPlan
    },
    planColor(){
      return {backgroundColor: `${this.betterPaymentPlan.displayColor}`};
    },
    currentPlanColor() {
       return {backgroundColor: `${this.plan.displayColor}`};
    },
    externalMusic(){
      return this.betterPaymentPlan.withAddOn ? "Yes": "No"
    },
    externalMusicCurrent(){
      return this.plan.withAddOn ? "Yes": "No"
    },
    typeOfPlan(){
      switch(this.betterPaymentPlan.typeOfPlan){
          case 1: 
               return "Monthly"
          case 2: 
               return "Yearly"
          case 3:
               return "One Time"            
          default:
               return "Undefined"
      }
    },
    typeOfPlanCurrentPaymentPlan() {
      switch(this.plan.typeOfPlan){
          case 1: 
               return "Monthly"
          case 2: 
               return "Yearly"
          case 3:
               return "One Time"            
          default:
               return "Undefined"
      }
    },
    trialPeriod(){
      if(this.betterPaymentPlan.trialPeriodProvided){
        return this.betterPaymentPlan.numberOfTialPeriodDays+" Days";
      }
      return "None"
    },
     trialPeriodCurrent(){
      if(this.plan.trialPeriodProvided){
        return this.plan.numberOfTialPeriodDays+" Days";
      }
      return "None"
    },

  },
  methods: {
      next() {
        this.$emit('nextPaymentPlan');
      },
      close() {        
        this.$emit('closeNextPaymentPlanDialog')
      },
      getCurrentCustomer(){
            api.customers.getCurrentCustomer().then(response=>{
            // this.customer=response.data;
            this.$store.dispatch('getSinglePaymentPlan',response.data.paymentPlanId);
        })
      },
      continuePlan() {
        this.$emit('continuePaymentPlan');
      }
  }
}
</script>

<style scoped>
.card-body {
    flex: 1 1 auto;
    /* min-height: 1px; */
    padding: 1rem;
}


.card{
    border-radius: 0%;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
.card-title{
  color: white;
  font-size: 70%;
  text-align: center;

}
.title-field{
overflow: hidden;
word-break: break-all;
justify-content: center;
text-align: center;
display: flex;
align-items: center;
/* border-radius: 10%;
box-shadow: 6px 6px 5px grey; */
}

.title-field:hover{
 cursor: pointer;
 /* border: 2px solid rgb(0, 174, 255); */
}

.command-column {
  width: 340px;
}
.command {
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
}
.data-table {
  margin-top: 30px;
}
.wrapper {
  width: 70%;
  margin: auto;
  min-width: 1024px;
}

.add-command {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>