export default {
  // General
  'Logout': 'Logout',
  // Login
  'E-Funeral': 'E-Funeral',
  'Email address': 'Email address',
  'Password': 'Password',
  'Wrong username or password.': 'Wrong username or password.',
  'Login': 'Login',
  // Upload
  'Portrait Photo': 'Portrait Photo',
  'Upload': 'Upload',
  'Created Content': 'Created Content',
  'Title': 'Title',
  'Name of the Deceased *': 'Name of the Deceased *',
  'Born - Died *': 'Born - Died *',
  'Pick Portrait Photo': 'Pick Portrait Photo',
  'Pick Background Photo': 'Pick Background Photo',
  'Unnamed Video': 'Unnamed Video',
  'Send Link': 'Send Link',
  'Invalid credentials.': 'You do not have access to E-Funeral. Please check your username and password and try again.',
  'Invalid email.': 'Invalid email.',
  'Password reset link will be sent to your email address.': 'Password reset link will be sent to your email address.',
  'Forgot password?': 'Forgot password?',
  'E-Funeral - Password Reset': 'E-Funeral - Password Reset',
  'Email is sent.': 'Email is sent.',
  'Password reset failed.': 'Password reset failed.',
  'Password is reset.': 'Password is reset.',
  'Reset Password': 'Reset Password',
  'Please enter the new password.': 'Please enter the new password',
  'New Password': 'New Password',
  'Retype New Password': 'Retype New Password',
  'Password must be at least 8 characters long.': 'Password must be at least 8 characters long.',
  'Go to Login': 'Go to Login'
}
