<template>
  <div>
  <div class="wrapper">
    <form class="form-signin">
      <img alt="EFuneral" src="../assets/logo.png">
      <div class="form-label-group">
        <input :class="{ 'is-invalid': $v.username.$error }" type="email" v-model="username" id="inputEmail" class="form-control" :placeholder="'Email address' | translate" required autofocus>
        <label for="inputEmail">{{'Email address' | translate}}</label>
      </div>
      <div class="form-label-group" :class="{ 'form-group--error': $v.password.$error }">
        <input  :class="{ 'is-invalid': $v.password.$error }" v-model="password" type="password" id="inputPassword" class="form-control" :placeholder="'Password' | translate" required>
        <label for="inputPassword">{{'Password' | translate}}</label>
      </div>
      <div v-if="error" class="alert alert-danger" role="alert">
        You do not have access to E-Funeral. Please check your username and password and try again.
      </div>
      <button class="btn btn-lg btn-primary btn-block"  @click="login()" type="button">{{'Login' | translate}}</button>
      <router-link class="forgot-password-link" to="/forgot-password"><a>{{'Forgot password?' | translate}}</a></router-link>
      <p class="mt-5 mb-3 text-muted">
        <LangChooser></LangChooser>
      </p>
    </form>
    <WizardActivatePaymentPlan ref="wizardActivatePaymentPlan"/>
    <sweet-modal :blocking="true" ref="managerActivatePaymentPlan" title="Contact your company">
      <ManagerPaymentPlanModal :message="message" :distributors="distributors" :distributorsLength="distributorsLength" :distributor="distributor"/>
    </sweet-modal>
  </div>
  <div v-show="isActive" class="alert alert-primary login-message-box" role="alert">
     {{loginMessage}}
  </div>
  </div>
</template>

<script>
// import config from "@/config";
import api from '@/api'
// import Cookie from "vue-cookie";
import { required } from 'vuelidate/lib/validators'
import LangChooser from '@/components/common/LangChooser'
import util from '@/util'
import WizardActivatePaymentPlan from '@/components/common/WizardActivatePaymentPlan'
import ManagerPaymentPlanModal from '@/components/common/ManagerPaymentPlanModal'
export default {
  components:{
    LangChooser,
    WizardActivatePaymentPlan,
    ManagerPaymentPlanModal
  },
  data() {
    return {
      username: '',
      password: '',
      loginMessage: '',
      isActive: false,
      error: false,
      customer: {},
      paymentMethods: [],
      distributors: [],
      distributorsLength:0,
      distributor: {},
      message: "You don't have an active subscription, please contact your company.",
       filter: {
          page: 1,
          pageSize: 1000,
          isHidden: false,
          isDeleted: false,
        }
    } 
  },
  validations: {
    username: {
      required
    },
    password: {
      required
    }
  },
   
  computed: {
    user() {
      return util.getSessionInfo()
    },
    currentCustomer() {
      return this.$store.getters.getCurrentCustomer;
    },
    paymentMethodsListStore() {
      return this.$store.getters.getPaymentMethodsList.length
    },
    plan() {
      return this.$store.getters.getSinglePaymentPlan
    },
    activateAndSubscribe(){
      if(this.plan.typeOfPlan!==3 && this.currentCustomer.startDatePaymentPlan===null){    
        return true
      }
      return false
      },
  },
  created(){
    api.loginMessage.getLoginMessage().then(response => {
      this.loginMessage = response.data.message
      this.isActive = response.data.active
    })
  },
  methods: {
    login() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.error = false
        api.users.login(this.username,this.password).then((response) => {
        
          util.setSessionData(response.data)
          sessionStorage.removeItem('subscribe');
          api.users.getUserInfo().then(userResult => {
            util.setSessionInfo(userResult.data)
            this.$store.dispatch('getCurrentUser', userResult.data);
            this.$store.dispatch('getCurrentCustomer');

            this.$store.dispatch('getCustomerWithDefaultPaymentMethod')
            this.$store.dispatch('getPaymentMethodsList');


            this.$store.dispatch('getUserContentList')
        
            if(userResult.data.userTypeId === 1 || userResult.data.userTypeId === 2) {
              // this.$store.dispatch('getUserEvent', util.getSessionInfo().userId)
              this.$store.commit('setEventInfo', userResult.data.eventInfo)
              //this.getCustomer();
              this.$router.push({ name: 'Upload' })
            }else if(userResult.data.userTypeId === 5) {
              this.$store.dispatch('getEventList', userResult.data.userId)
              if (localStorage.returnUrl) {
                document.location.href = localStorage.returnUrl
                delete localStorage.returnUrl
                return
              }
              this.$router.push({ name: 'Events' })
            }else if(userResult.data.userTypeId === 3) {
               this.getCustomer(userResult.data.userTypeId);
            }else if(userResult.data.userTypeId === 4){
              this.$store.dispatch('getEventList', userResult.data.userId)
              if (localStorage.returnUrl) {
                document.location.href = localStorage.returnUrl
                delete localStorage.returnUrl
                return
              }
              this.getCustomer(userResult.data.userTypeId);
            }
          })
        }, (err) => {
            this.error = err
      })
      }
    },
    getCustomer(userTypeIdParam) {
      api.customers.getCurrentCustomer().then(response => {
          this.$store.dispatch('getAllPaymentPlans',this.filter)
          this.$store.dispatch('getSinglePaymentPlan', response.data.paymentPlanId);
          api.paymentMethods.getPaymentMethodsList().then(resp => {
          if (this.plan.typeOfPlan === 4){
            this.$router.push({ name: 'Events' })
          }else if(response.data.startDatePaymentPlan === null && userTypeIdParam == 3) {
            this.getDistributorsForCompany();
            this.$refs.managerActivatePaymentPlan.open();
          }else if(userTypeIdParam == 4 && response.data.startDatePaymentPlan == null && resp.data.paymentMethods.length === 0  && this.plan.typeOfPlan !== 3) {
            sessionStorage.getItem('subscribe', 'paymentErrorPage')
            return util.subscribe();
          }else if(userTypeIdParam == 4 && response.data.startDatePaymentPlan == null && resp.data.paymentMethods.length === 0 && this.plan.typeOfPlan === 3) {
            this.payOneTime();
          }else if(userTypeIdParam == 4 && response.data.startDatePaymentPlan == null && resp.data.paymentMethods.length !== 0) {
              this.$refs.wizardActivatePaymentPlan.open()
          }else {
            this.$router.push({ name: 'Events' })
          }
        })

      })
    },
    getDistributorsForCompany() {
       api.users.getDistributorsForCompany().then(response => {
        this.distributorsLength = response.data.length;
        if(this.distributorsLength === 1) {
          this.distributor = response.data[0];
        }else {
          this.distributors = response.data
        }
      })
    },
    payOneTime() {
      api.customerPaymentPlan.activateOneTime().then(()=>{
        this.$router.push({ name: 'Events' })
      })
    }
  }
};
</script>

<style scoped>
button {
  width: 100%;
}
.error-height {
  height: 16px;
}
.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }
  .form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin {
  text-align: center;
}

.form-signin .form-control:focus {
  z-index: 2;
}
.form-label-group {
  text-align: left;
}
.forgot-password-link {
  display: block;
  font-size: 14px;
  margin-top: 5px;
}

.form {
  text-align: left;
  margin: auto;
  width: 400px;
}

.text-are-noresize{
  width: 100%;
  resize: none;
}

.login-message-box {
  text-align: center;
  color: black;
  margin-left: 10%;
  margin-right: 10%;
}

</style>
