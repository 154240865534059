<template>
  <div>
     <div class="alert alert-danger" role="alert">
      By choosing a different plan, you might lose the option to create more funerals from your current plan.
    </div>
    <!-- v-if="paymentPlanId !== customer.paymentPlanId"-->
      <!-- <div class="form-row mt-4" v-if="paymentPlanId !== customer.paymentPlanId">
+                <div class="col-md-12  mb-0">
+                  <label class="d-block">New Payment Plan</label>
+                    <div class="card mb-3">
+                      <div class="row no-gutters">
+                        <div class="col-md-4 title-field" :style="planColor">
+                            <h3 class="card-title">{{paymentPlan.name}}</h3>
+                          </div>
+                          <div class="col-md-8">
+                          
+                              <p class="card-text">Price: <b>{{paymentPlan.price}}€</b>
+                              <br>
+                              Videos per funeral: <b>{{paymentPlan.videosPerEvent}}</b>
+                              <br>
+                              Number of funerals: <b>{{paymentPlan.numberOfEvents}}</b>
+                              </p>
+                            </div>
+                        
+                        </div>
+                      </div>
+                    </div>
+                    
+                    
+              <div > 
+                    </div>
+              </div> -->


              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4 title-field" :style="planColor">
                    <!-- <h3 class="card-title" v-if="updatedPaymentPlan.name != null || updatedPaymentPlan.name != undefined">
+                      {{updatedPaymentPlan.name}}
+                    </h3> -->
                     <h3 class="card-title">
                      {{updatedPaymentPlan.name}}
                    </h3>
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <p class="card-text">Price: <b>{{updatedPaymentPlan.price}}€</b>
                      <br>
                      Videos per funeral: <b>{{updatedPaymentPlan.videosPerEvent}}</b>
                      <br>
                      Number of funerals: <b>{{updatedPaymentPlan.typeOfPlan==4 ? "∞" : updatedPaymentPlan.numberOfEvents}}</b>
                      </p>
                      <div>
                    
                      </div>
                    </div>
                  </div>
            </div>
          </div>
          <div class="form-group mt-3">
              <label>Switch to another payment plan</label>
              <select class="custom-select" @change="onChange($event)" required>
             
                <option  v-for="(paymentPlan,$index) in paymentPlans" 
                :selected="updatedPaymentPlan.paymentPlanId === paymentPlan.paymentPlanId"
                :value="paymentPlan.paymentPlanId" :key="$index" >
                {{paymentPlan.name}}
                 </option>
              </select>
          </div>
           <div class="buttons-row" style="float:right">
                <button type="button" @click="closeDialog()" class="command btn btn-default">Cancel</button>
                <!-- <button :disabled="updatedPaymentPlan.paymentPlanId === customer.paymentPlanId" class="command btn btn-primary" type="button" style="margin-left:8px" @click="switchPlan"> <i class="fa fa-save"></i> Save</button> -->
                <button :disabled="this.disabledoption" class="command btn btn-primary" type="button" style="margin-left:8px" @click="switchPlan"> <i class="fa fa-save"></i> Save</button>

            </div>
  </div>
</template>

<script>
import api from '@/api'
import util from '@/util'
export default {
  props: {
    updatedPaymentPlan : Object
  },
    data(){
        return{
          paymentPlan : this.updatedPaymentPlan,
        
            customer:{},
            discount:null,
          isLoading:false,
            paymentPlanId: null,
            newPaymentPlan : {}
        }
    },
    created(){
         
      this.getCurrentCustomer();
      //sessionStorage.removeItem('subscribe');
    },
  computed: {
    disabledoption() {
      if(this.updatedPaymentPlan.paymentPlanId === this.currentCustomer.paymentPlanId) {
        return true
      }else {
        return false
      }
    },
    updatedplan() {
      return sessionStorage.getItem('current');
    },
    currentCustomer() {
      return this.$store.getters.getCurrentCustomer
    },
  
      paymentPlans(){
          return this.$store.getters.getAllPaymnetPlans.data
        },
         hasPaymentPlan(){
          return this.customer.paymentPlanId!==null?true:false
        },
        planColor(){
        return {backgroundColor: `${this.plan.displayColor}`};
      },
        plan(){
          return this.$store.getters.getSinglePaymentPlan
        },
  },
  methods: {
    //  subscribe(){
    //     sessionStorage.setItem('subscribe', 'errorPage');
    //     return util.subscribe();
    //   },
     unsubscribeAndSwitch(){
           api.customerPaymentPlan.deactivateAndUnsubscribe().then(response => {
          this.customer = response.data;
          this.customer.paymentPlanId = this.paymentPlanId;
            api.paymentPlan.getSinglePlan(this.customer.paymentPlanId).then(response3 => {
              this.newPaymentPlan = response3.data;
            })
            api.customers.updateCustomer(this.customer).then(()=>{
              this.activate();
          })
        })
           //this.deactivateUnsubscribe()
        },
      activate(){
        if(this.newPaymentPlan.typeOfPlan === 3) {
            api.customerPaymentPlan.activateOneTime().then(() => {      
             this.getCurrentCustomer();
                this.$emit('changeState')
                this.$emit('closeUpdateSwitchDialog');
             
              
              
            });
        }else {
          sessionStorage.setItem('subscribe', 'errorPage');
          return util.subscribe();
        }
      },


      // activateAndSubscribe() {
      //   if(this.newPaymentPlan.typeOfPlan === 3) {
      //       api.customerPaymentPlan.activateOneTime().then(() => {
      //           this.getCurrentCustomer();
      //         });
      //   }else {
      //     sessionStorage.setItem('subscribe', 'errorPage');
      //     return util.subscribe();
      //   }
      // },

    // deactivateOneTime() {
    //     api.customerPaymentPlan.deactivateOneTime().then(response => {
    //       this.customer = response.data;
    //       this.customer.paymentPlanId = this.paymentPlanId;
    //         api.paymentPlan.getSinglePlan(this.customer.paymentPlanId).then(response3 => {
    //           this.newPaymentPlan = response3.data;
    //         })
    //         api.customers.updateCustomer(this.customer).then(()=>{
    //           this.activateOneTime();
    //       })
    //     })
      
     
    // }, 
    stopOneTimeAndSwitch(){
      api.customerPaymentPlan.deactivateOneTime().then(response => {
          this.customer = response.data;
          this.customer.paymentPlanId = this.paymentPlanId;
            api.paymentPlan.getSinglePlan(this.customer.paymentPlanId).then(response3 => {
              this.newPaymentPlan = response3.data;
            })
            api.customers.updateCustomer(this.customer).then(()=>{
              this.activate();
             
          })
        })

        },
    switchPlan() {
      if(this.plan.typeOfPlan !== 3) {
        this.unsubscribeAndSwitch();
      }else {
        this.stopOneTimeAndSwitch();
      }
      
    },
     onChange(event){
          if(event.target.value!=0){
          
             this.paymentPlanId = parseInt(event.target.value);
             api.paymentPlan.getSinglePlan(this.paymentPlanId).then(response => {
               // this.paymentPlan = response.data
               this.updatedPaymentPlan = response.data;
               
              })
          }
        },
         getCurrentCustomer(){
           api.customers.getCurrentCustomer().then(response=>{
            this.customer=response.data;

            this.$store.dispatch('getSinglePaymentPlan',this.customer.paymentPlanId);
            this.$store.dispatch('getCurrentCustomer')
             if(this.customer.paymentPlanId === this.plan.paymentPlanId) {
               this.paymentPlanId = this.customer.paymentPlanId
            }

             api.paymentPlan.getSinglePlan(this.paymentPlanId).then(response => {
               this.paymentPlan = response.data;
              //this.updatedPaymentPlan = response.data
             })
            this.getDiscount();
            this.getAllPaymentPlans();
            this.isLoading=false;
        })
        },
        getDiscount() {
          if(this.customer.stripeCouponId != null) {
              api.coupons.getCouponById(this.customer.stripeCouponId).then(response => {
                this.discount = response.data.coupon.percent_off!=null? response.data.coupon.percent_off+"%": (response.data.coupon.amount_off/100) +response.data.coupon.currency; 
            })
          }
        },
          getAllPaymentPlans(){
          let filter = {
            page: 1,
            pageSize: 1000,
            isHidden: false,
            isDeleted: false,
          };
          this.$store.dispatch('getAllPaymentPlans',filter)
       },
        closeDialog() {
          this.$emit('closeUpdateSwitchDialog');
        }
  }
}
</script>
<style scoped>
body{
    margin-top:20px;
    color: #1a202c;
    text-align: left;
    background-color: #e2e8f0;    
}
.main-body {
    padding: 15px;
}

.nav-link {
    color: #4a5568;
}
/* .card {
+    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
+} */

.card {
    border-radius: 0%;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    /* border-radius: .25rem; */
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
/* .shadow-none {
+    box-shadow: none!important;
+} */
/* .card{
+    border-radius: 7%;
+    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
+} */
.card-title{
  color: white;
  text-align: center;
  font-size: 100%;

}
.title-field{
overflow: hidden;
word-break: break-all;
justify-content: center;
text-align: center;
display: flex;
align-items: center;
/* border-radius: 10%;
+box-shadow: 6px 6px 5px grey; */
}

.title-field:hover{
 cursor: pointer;
 /* border: 2px solid rgb(0, 174, 255); */
}

.confirm{
  margin-left: 20%;
  margin-top: 20%;
}
#cancelButton {
  padding:10px;
}
</style>