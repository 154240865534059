<template>
  <div>
    <InfoDialog/>
    <div class="form-group mt-3">
      <label>Choose payment plan</label>
        <select class="form-control" @change="onChange($event)" required >
          <option 
              v-for="(item,$index) in paymentPlans" 
              :value="item.paymentPlanId" 
              :selected="item.paymentPlanId === plan.paymentPlanId"
              :key="$index"
              >{{item.name}}
          </option>
        </select>
      </div>
    </div>  
</template>

<script>
import api from '@/api'
import util from '@/util'
import InfoDialog from '@/components/paymentPlan/paymentPlanForms/InfoDialog'
export default {
   data(){
      return{
        customer: {},
        paymentPlanId : null,
        filter: {
          page: 1,
          pageSize: 1000,
          isHidden: false,
          isDeleted: false,
        }
      }
    },
  components: {
    InfoDialog
  },
  created() { 
    this.paymentPlanId = this.customer.paymentPlanId
    if(!this.isEmptyObj(this.user)) {
       this.$store.dispatch('getAllPaymentPlans',this.filter)
       this.$store.dispatch('getSinglePaymentPlan',this.currentCustomer.paymentPlanId);
      this.getCurrentCustomer();   
    }
  },
  watch: {
     paymentPlanId:{
          handler:function(){
           // this.paymentPlanId = this.currentCustomer.paymentPlanId
          if(!this.isEmptyObj(this.user)) {
            this.getCurrentCustomer();   
          }
        }
      },
  },
 computed: {
   currentCustomer() {
     return this.$store.getters.getCurrentCustomer
   },
    plan() {
      return this.$store.getters.getSinglePaymentPlan
    },
    paymentPlans(){
      return this.$store.getters.getAllPaymnetPlans.data
    },   
    user() {
      return util.getSessionInfo()
    } 
 },
 methods: {
    onChange(event){
      if(event.target.value!=0){
        this.paymentPlanId = parseInt(event.target.value);
        this.currentCustomer.paymentPlanId=event.target.value;
        api.customers.updateCustomer(this.currentCustomer).then(()=>{
          this.$store.dispatch('getSinglePaymentPlan',this.currentCustomer.paymentPlanId);
          this.getCurrentCustomer()
        })
        }
    },
    getCurrentCustomer(){
      api.customers.getCurrentCustomer().then(response=>{
        this.customer = response.data;
      
      
        this.$store.dispatch('getSinglePaymentPlan',this.customer.paymentPlanId);
        this.$store.dispatch('getCustomerWithDefaultPaymentMethod');
      })
    },
      isEmptyObj(object) {
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
    },
 } 
}
</script>

