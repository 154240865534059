<template>
    <div>
        <MainNav></MainNav>
        <div class="wrapper">
            <div class="selectAndAddField">
                <div class="col-md-3 ">
                    <select class="custom-select" required v-model="deletedPlans">
                    <option value="0">Available Plans</option>
                    <option value="1">Deleted Plans</option>
                    <option value="2">All Plans</option>
                    </select>
                 </div>
                <div>            
                <button @click="openAddForm" type="button" class="add-command command btn btn-success"><i class="fa fa-plus"></i> Add Payment Plan</button>
                </div>
            </div>
            <hr>
            <div class="container-fluid mt-5">              
                <div class="row row-cols-1 row-cols-md-2 g-4 " >
                    <div class="col-md-4" v-for="(plan,index) in paymentPlans.data" :key="index">
                    <PlanCard :plan="plan" @openInfoDialog="openInfoDialog" @openDeleteDialog="openDeleteDialog" @openRestoreDialog="openRestoreDialog" @openUpdateForm="openUpdateForm" @openRegistrationDialog="openRegistrationDialog"/>
                    </div>                                              
                </div>
            </div>
            <hr>
            <div class="pagination">
                <div class="col-md-3 ">
                    <select class="custom-select" required v-model="pagination.pagesize">
                    <option value="6">Plans Per Page: 6</option>
                    <option value="9">Plans Per Page: 9</option>
                    <option value="12">Plans Per Page: 12</option>
                    <option value="15">Plans Per Page: 15</option>
                    </select>
                 </div>
                <nav aria-label="...">
                    <ul class="pagination ml-2">
                        <!-- <button :disabled="disablePrev" class="btn btn-primary mr-1" @click="changePage(paymentPlans.previousPage)"><i class="fa fa-arrow-left" aria-hidden="true"/> Previous</button> -->
                        <div class="pageNumbers " v-for="(page,index) in paymentPlans.pages" :key="index">
                            <li class="page-item">
                                <button v-if="page!=pagination.page" class="btn btn-outline-primary btn-mg ml-2" @click="changePage(page)">{{page}}</button>
                                <button v-if="page==pagination.page" class="btn btn-primary btn-mg ml-2" @click="changePage(page)">{{page}}</button>
                            </li>
                        </div>
                        <!-- <button :disabled="disableNext" class="btn btn-primary ml-1" @click="changePage(paymentPlans.nextPage)">Next <i class="fa fa-arrow-right " aria-hidden="true"/></button>                    -->
                    </ul>
                </nav>
            </div>
        </div>
        <sweet-modal ref="planInfoModal" class="infoModal" title="Payment Plan Details">
            <!-- <InfoDialog/> -->
            <PaymentPlanInfo @closePlanInfoModal="closePlanInfoModal"/>
        </sweet-modal>
        <!-- <sweet-modal ref="deleteModal">        
            <DeleteDialog @deletePlan="deletePlan" @closeDeleteModal="closeDeleteModal"/>   
        </sweet-modal> -->
         <sweet-modal ref="deleteModal" icon="warning">
            You are about to delete this payment plan. Are you sure?
            <div>
              <button class="btn btn-light modal-button" @click="$refs.deleteModal.close()" type="button">Cancel</button>
              <button class="btn btn-danger modal-button" @click="deletePlan()" type="button">Delete</button>
            </div>
         </sweet-modal>
				<sweet-modal ref="restoreModal" icon="warning">
           You are about to restore this payment plan. Are you sure?
            <div>
              <button class="btn btn-light modal-button" @click="$refs.restoreModal.close()" type="button">Cancel</button>
              <button class="btn btn-danger modal-button" @click="restorePlan()" type="button">Restore</button>
            </div>        
            <!-- <RestoreDialog @restorePlan="restorePlan" @closeRestoreModal="closeRestoreModal"/>    -->
        </sweet-modal>
        <sweet-modal ref="UpdateModal" :blocking="true" title="Update Payment Plan">
            <UpdateForm v-if="!isLoading" @updatePlan="updatePlan" @closeUpdateDialog="closeUpdateDialog"/>
            <div v-if="isLoading" class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </sweet-modal>
         <sweet-modal :blocking="true" title="Add new payment plan" ref="AddModal">
            <!-- <AddForm v-if="!isLoading" @closeAddForm="closeAddForm" @addPlan="addPlan"/> -->
            <AddPaymentPlanForm v-if="!isLoading" @closeAddForm="closeAddForm" @addPlan="addPlan"/>
            <!-- <CustomerDialog /> -->
            <div v-if="isLoading" class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </sweet-modal>
         <sweet-modal :blocking="true" ref="registrationModal" :icon="'info'" title="Info">
          <div> Use this URL to register a company with pre-selected payment plan <br/>
           {{documentUrl}}#/registration/{{registrationId}}<br/> </div>
         
          
          <button class="btn btn-light modal-button" @click="$refs.registrationModal.close()" type="button">Cancel</button>
          <button  class="btn btn-primary modal-button" @click="$refs.registrationModal.close()" type="button">OK</button>
    </sweet-modal>
    </div>
</template>

<script>
import MainNav from '@/components/common/MainNav'
import PlanCard from '@/components/paymentPlan/PlanCard'
import UpdateForm from './paymentPlanForms/UpdateForm'
//import AddForm from './paymentPlanForms/AddForm'
import AddPaymentPlanForm from '@/components/paymentPlan/paymentPlanForms/AddPaymentPlanForm'
import PaymentPlanInfo from '@/components/paymentPlan/paymentPlanForms/PaymentPlanInfo'
//import CustomerDialog from '@/components/user/CustomerDialog'
//import InfoDialog from './paymentPlanForms/InfoDialog'
//import DeleteDialog from './paymentPlanForms/DeleteDialog'
//import RestoreDialog from './paymentPlanForms/RestoreDialog'
import {postPlan, updatePlan} from './PostAndPutPlanApi.js'
import api from '@/api'

export default {
    components:{
        MainNav,
        PlanCard,
        UpdateForm,
        //AddForm,
        AddPaymentPlanForm,
        //AddPaymentPlan,
        //CustomerDialog,
        //InfoDialog,
        PaymentPlanInfo,
        //DeleteDialog,
        //RestoreDialog
    },
    computed:{
        paymentPlans(){
            return this.$store.getters.getAllPaymnetPlans
        },
        paymentPlan(){
            return this.$store.getters.getSinglePaymentPlan
        },
        documentUrl (){     
          return document.location.href.split('#')[0]; 
        },
    },

    data(){
        return{
            pagination:{
                sort:'paymentPlanId',
                pagesize:6,
                page:1,
                isDeleted:false
            },
            deleteId:null,
            disablePrev:true,
            disableNext:true,
            deletedPlans:0,
            isLoading:false,
            formData: {},
            registrationId:null,
            selectedItem: {
              parentUserId: 1,
              introductoryText: 'Tekst1',
              mainText: 'Main Text',
              subText : '1940-2021',
              eventDate: '2020'
            }
        }
    },
    mounted(){
        this.$store.dispatch('getAllPaymentPlans',this.pagination);
    },
    methods:{
        openInfoDialog(id){
            this.$store.dispatch('getSinglePaymentPlan',id)
            this.$refs.planInfoModal.open();
        },
        closePlanInfoModal() {
          this.$refs.planInfoModal.close();
        },
        openDeleteDialog(id){
            this.deleteId=id;
            this.$refs.deleteModal.open();
        },
        openRestoreDialog(formData) {
          this.formData = formData;
          this.$refs.restoreModal.open();
        },
        openRegistrationDialog(id) {
          this.registrationId = id;
          this.$refs.registrationModal.open();
        },
        closeDeleteModal(){
          this.$refs.deleteModal.close();
        },
        closeRestoreModal() {
          this.$refs.restoreModal.close();
        },
        deletePlan(){
            api.paymentPlan.deleteSinglePlan(this.deleteId).then(()=>{
              this.renderPlansOnPage()
              this.closeDeleteModal();
            })
        },
        restorePlan() {
          updatePlan(this.formData).then(() => {
              this.renderPlansOnPage()
              this.closeRestoreModal();
          })
        },
        openAddForm(){
            this.$refs.AddModal.open();
        },
        addPlan(formData){
            this.isLoading=true;  
            postPlan(formData).then(()=>{
            this.isLoading=false;
            this.renderPlansOnPage()
            this.$refs.AddModal.close()
            })
        },
        closeAddForm(){
            this.$refs.AddModal.close();
        },
        openUpdateForm(id){
            this.$store.dispatch('getSinglePaymentPlan',id)
            this.$refs.UpdateModal.open();
        },
        updatePlan(formData){
            this.isLoading=true;
            updatePlan(formData).then(()=>{
                this.isLoading=false;
                this.renderPlansOnPage()
                this.$refs.UpdateModal.close();
            })
        },
        closeUpdateDialog(){
             this.$refs.UpdateModal.close();
        },
        renderPlansOnPage(){
            if(this.deletedPlans==1){
                this.$store.dispatch('getAllPaymentPlans',{sort:'paymentPlanId',pagesize:this.pagination.pagesize,page:this.pagination.page,isDeleted:true});
            }else if(this.deletedPlans==0){
                this.$store.dispatch('getAllPaymentPlans',this.pagination);
            }else{
                this.$store.dispatch('getAllPaymentPlans',{sort:'paymentPlanId',pagesize:this.pagination.pagesize,page:this.pagination.page});
            }
        },
        changePage(page){
            this.disablePrev=page==1?true:false;
            if(this.paymentPlans.totalPages==1 || this.paymentPlans.totalPages==page ||this.paymentPlans.nextPage==0){
                this.disableNext=true;  
            }else{
                this.disableNext=false
            }
            this.pagination.page=page;
            this.renderPlansOnPage()
        }          
    },
    watch:{
        deletedPlans:{
            handler:function(){
                this.renderPlansOnPage()
                setTimeout(()=>{  this.changePage(1) }, 100); 
            }
        },
        'pagination.pagesize':{
            deep:true,
            handler:function(){
                 this.renderPlansOnPage()

                 setTimeout(()=>{  this.changePage(1) }, 100);                   
            }
        }
    },
    
}
</script>

<style scoped>
.wrapper {
  width: 80%;
  margin: auto;
  margin-top: 20px;
  min-width: 1024px;
 
}
.selectAndAddField{
    
    display: flex;
    justify-content:space-between;
    align-items: center;
}
.pagination{
    display: flex;
    justify-content:space-between;
    align-items: center;
}
</style>