<template>
  <div>
    <MainNav></MainNav>
    <div class="wrapper">
      <button type="button" @click="openUserDialog()" class="add-command command btn btn-success"><i class="fa fa-plus"></i> Add User</button>
      <button v-if="user.userTypeId === 5" type="button" @click="openCustomerDialog()" class="add-command command btn btn-success"><i class="fa fa-plus"></i> Add Company</button>
      <table v-if="users.length" id="dtBasicExample" class="data-table table table-bordered sortable" cellspacing="0" width="100%">
        <thead>
          <tr>
             <th @click="sort('customerName')" class="th-sm">Company
              <i v-if="sortField === 'customerName'" class="fa" :class="'fa-sort-' + order"></i>
            </th> 
            <th @click="sort('fullName')" class="th-sm">Name
              <i v-if="sortField === 'fullName'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('email')" class="th-sm">Email
              <i v-if="sortField === 'email'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('userTypeName')" class="th-sm">Role
              <i v-if="sortField === 'userTypeName'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th @click="sort('verified')" style="width: 100px;">Status
              <i v-if="sortField === 'verified'" class="fa" :class="'fa-sort-' + order"></i>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, $index) in users" :key="$index">
            <td>{{item.customerName}}</td>
            <td>{{item.fullName}}</td>            
            <td>{{item.email}}</td>
            <td>{{item.userTypeName}}</td>            
            <td>
              <span v-if="!item.verified" class="badge badge-info">New</span>
              <span v-if="item.verified" class="badge badge-success">Registered</span>
            </td>
            <td class="command-column">
              <button type="button" @click="openUserDialog(item)" class="command btn btn-primary btn-sm">Details</button>
              <button type="button" @click="openInviteDialog(item)" class="command btn btn-primary btn-sm" :disabled=item.verified>Invite</button>
              <button type="button" @click="setUserState(item)" :class="{'btn-success': item.enabled, 'btn-danger': !item.enabled}" class="command btn btn-sm">{{item.enabled ? 'Deactivate' : 'Activate'}}</button>
            </td>
          </tr>
        </tbody>
      </table>
      <table v-if="!users.length" cellspacing="0" width="100%">
        <tr>
          <td>
            <div class="alert alert-primary" role="alert">
              There are no users to show.
            </div>
          </td>
        </tr>
      </table>
    </div>
    <sweet-modal :blocking="true" title="User Details" ref="userModal">
      <UserDialog :modal-ref="$refs.userModal" :item="selectedItem" @name-change="changeCustomerName"></UserDialog>
    </sweet-modal>
    <sweet-modal :blocking="true" title="Add new company" ref="customerModal">
      <CustomerDialog :modal-ref="$refs.customerModal" :item="selectedItem"  @name-change="changeCustomerName"></CustomerDialog>
    </sweet-modal>
      <sweet-modal :blocking="true" ref="inviteModal" icon="info" title="Info">Invitation will be sent to {{selectedUser.email}}. <br/>Optionally, send the following link to the customer:<br/> {{documentUrl}}#/invitation/{{selectedUser.registrationKey}}<br/>
        <button class="btn btn-light modal-button" @click="$refs.inviteModal.close()" type="button">Cancel</button>
        <button class="btn btn-primary modal-button" @click="inviteUser()" type="button">Send</button>
      </sweet-modal>
  </div>
</template>
<script>
import MainNav from '@/components/common/MainNav'
import UserDialog from '@/components/user/UserDialog'
import CustomerDialog from '@/components/user/CustomerDialog'
import api from '@/api'
import util from '@/util'
export default {
  computed :{
    userSort () {
      return localStorage.userSort
    },
    userOrder () {
      return localStorage.userOrder
    },
    users () {
      return this.$store.state.userList
    },
    documentUrl (){     
      return document.location.href.split('#')[0]; 
    },
    user () {
      return util.getSessionInfo()
    },
  },
  data () {
    return {
      selectedItem: {Customer: {}},
      selectedUser: {},
      order: '',
      sortField: '',
      loggedInUserCustomerName: '',
      loggedInUserCustomerId: null
    }
  },
  components: {
    MainNav,
    UserDialog,
    CustomerDialog
  },
  mounted () {
    this.$store.dispatch('getUserList'),
    api.users.getUserInfo().then(result => {
    this.loggedInUserCustomerName = result.data.customerName
    this.loggedInUserCustomerId = result.data.customerId
  })
  },
  methods: {
    sort (field) {
      localStorage.userSort = field
      localStorage.userOrder = localStorage.userOrder === 'desc' ? 'asc' : 'desc'
      this.order = localStorage.userOrder
      this.sortField = localStorage.userSort
      this.$store.dispatch('getUserList')
    },
    openInviteDialog (item) {
      this.selectedUser = item
      this.$refs.inviteModal.open()
    },
    inviteUser() {
      this.selectedUser.language = localStorage.lang || 'en'
      api.users.sendInvite(this.selectedUser).then(() => {
        this.$store.dispatch('getUserList')
        this.$refs.inviteModal.close()
      })
    },
    openUserDialog (item) {
      if (!item) {
        item = {
          userTypeId: 2,
          Customer: {
            customerName: this.loggedInUserCustomerName,  
            customerId: this.loggedInUserCustomerId
          }
        };
      }
      
      this.selectedItem = item
      this.$refs.userModal.open()
    },
    openCustomerDialog (item) {
      this.selectedItem = item
      this.$refs.customerModal.open()
    },
    setUserState (item) {
      api.users.setUserState(item.userId, !item.enabled).then(() => {
        this.$store.dispatch('getUserList')
      })
    },
    changeCustomerName(newName) {
      this.selectedItem.customerName = newName;
    }
  }
}
</script>
<style scoped>
.command-column {
  width: 372px;
}
.command {
  margin-left: 10px;
  margin-right: 10px;
}
.data-table {
  margin-top: 30px;
}
.wrapper {
  width: 70%;
  margin: auto;
  min-width: 1024px;
}

.add-command {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.btn-sm {
  width: 95px;
}
.message {
  margin-top: 50px;
}
.sortable th {
  cursor: pointer;
}
</style>
