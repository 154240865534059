<template>
  <div> 
    <div>
        <h5 class="modal-title">{{message}}<br/>
          <p v-if="distributorsLength === 1"> Your company is {{distributor.fullName}} ({{distributor.email}}) </p>
          <p v-else> Your companies are:  </p>
         </h5>
    </div>
    <div class="container" v-if="distributorsLength > 1">
         <div class="row" style="text-align:left">
            <div class="col-md-6">
                  <div class="demo-content">Full name</div>
                </div>
                <div class="col-md-6">
                  <div class="demo-content">Email</div>
              </div>
            </div>
    
              <div v-for="item in distributors" :key="item.userId">
                <div class="row" style="text-align:left">
                  <div class="col-md-6">
                    <div  class="demo-content">
                        {{item.fullName}}
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div  class="demo-content">
                        {{item.email}}
                      </div>
                    </div>
                  </div>
              </div>
          </div>
  </div>
</template>

<script>
export default {
  props : {
    distributors: Array,
    distributorsLength: Number,
    distributor: Object,
    message: String
  }
}
</script>
