<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 mx-auto mt-5">
                <div class="payment">
                    <div class="payment_header">
                    <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
                    </div>
                    <div class="content">
                    <h1>Your payment was successful !</h1>
                    <h5 class="m-3">Thank You. We enjoy working with you. Please don't hesitate to turn to us for any information. </h5>
                    <h5 class="m-3 alert alert-warning" v-if="errorMessage!=''">Warning: {{errorMessage}}</h5>
                    <router-link class="a" to="events">OK</router-link>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import util from '@/util'
import config from "@/config";
export default {
    data () {
        return {
            errorMessage : "",
        }
    },
    watch: {
    },
    computed:{
        getSeasonId(){
            return this.$store.getters.getSessionId
        }
    },
    mounted(){
        this.finishSubsciption()
    },
    methods:{
        finishSubsciption(){
            var url;
            if(sessionStorage.getItem('eventId')!=null){
                 url=`${config.API_URL}api/payment/finish-payment-event?sessionId=${sessionStorage.getItem('sessionId')}&eventId=${sessionStorage.getItem('eventId')}`
            }else{
                 url=`${config.API_URL}api/payment/finish-subscription-activation?sessionId=${sessionStorage.getItem('sessionId')}`
            }
            fetch(url,{
                method:'POST',
                mode:'cors',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${util.getSessionData().access_token}`,           
                },
            }).then(response=>{
                const status = response.status;

                this.errorMessage = "";
                sessionStorage.removeItem('eventId');
                sessionStorage.removeItem('sessionId');
                this.$store.dispatch('getEventList');
                if (status == 200) {
                    return response.json()
                }
                if (status == 403 || status == 404 || status == 500) {    
                    // read 403 response
                    // read 404 response
                    // read 500 response
                    response.json().then(res => {
                        if(res.startsWith("MUSIC API")){
                            this.errorMessage = res;
                        }
                    });
                    return;
                }
            }).catch(error => {
                error.json().then(res => {
                    if(res.startsWith("MUSIC API")){
                        this.errorMessage = res;
                    }
                });
            })   
        }
    }
}
</script>

<style scoped>

    body
    {
        background:#f2f2f2;
    }

    .payment
	{
		border:1px solid #f2f2f2;
		height:auto;
        border-radius:20px;
        background:#fff;
	}
   .payment_header
   {
	background:rgb(35, 173, 17);
	padding:20px;
    border-radius:20px 20px 0px 0px; 
   }
   
   .check
   {
	margin:0px auto;
	width:50px;
	height:50px;
	border-radius:100%;
	background:#fff;
	text-align:center;
   }
   
   .check i
   {
	vertical-align:middle;
	line-height:50px;
	font-size:30px;
   }

    .content 
    {
        text-align:center;
    }

    .content  h1
    {
        font-size:25px;
        padding-top:25px;
    }

    .a
    {
        width:200px;
        height:35px;
        color:#fff;
        border-radius:30px;
        padding:5px 10px;
        background:rgb(35, 173, 17);
        transition:all ease-in-out 0.3s;
    }

    .content a:hover
    {
        text-decoration:none;
        background:#000;
    }
</style>