<template>
  <div>
    <MainNav></MainNav>
    <div class="wrapper">
      <ul class="nav nav-tabs">
          <li class="nav-item" @click="activeTab = 1">
              <a class="nav-link" :class="{'active': activeTab === 1}" href="#">Profile Information</a>
          </li>
          <li class="nav-item" @click="activeTab = 2" v-if="user.userTypeId === 3 && !hasActivatePaymentPlan">
              <a class="nav-link" :class="{'active': activeTab === 2}"  href="#">Payment Plans</a>
          </li>
          <li class="nav-item" @click="activeTab = 2" v-if="user.userTypeId === 4">
              <a class="nav-link" :class="{'active': activeTab === 2}"  href="#">Payment Plans</a>
          </li>
          <li class="nav-item" @click="activeTab = 3" v-if="user.userTypeId !== 3">
              <a class="nav-link" :class="{'active': activeTab === 3}"  href="#">Invoices</a>
          </li>
          <li class="nav-item" @click="activeTab = 4" v-if="user.userTypeId !== 3">
              <a class="nav-link" :class="{'active': activeTab === 4}"  href="#">Payment Cards</a>
          </li>
      </ul>
      <div v-if="activeTab === 1"> <ProfileInfoTab /></div>
      <div v-if="activeTab === 2"> <PaymentPlansInfo/>  </div>
      <div v-if="activeTab === 3"> <CustomerInvoiceList /> </div>
      <div v-if="activeTab === 4"> <PaymentMethodList /> </div>
    </div>
  </div>

</template>

<script>
import MainNav from '@/components/common/MainNav'
import ProfileInfoTab from '@/components/customers/settings/ProfileInfoTab';
import CustomerInvoiceList from '@/components/customers/settings/CustomerInvoiceList';
import PaymentMethodList from '@/components/paymentMethods/PaymentMethodsList';
import PaymentPlansInfo from '@/components/customers/settings/PaymentPlansInfo';
import util from '@/util'
import api from '@/api'
export default {
   data () {
    return {
      activeTab: 1,
      customer: {}
    }
  },
   components: {
    MainNav,
    ProfileInfoTab,
    CustomerInvoiceList,
    PaymentMethodList,
    PaymentPlansInfo
  },
  created() {
    this.$store.dispatch('getCurrentCustomer');
    this.getCurrentCustomer();
  },
  computed: {
      user () {
        return util.getSessionInfo()
    },
    currentCustomer() {
      return this.$store.getters.getCurrentCustomer
    },
    hasActivatePaymentPlan(){
      if(this.customer.startDatePaymentPlan === null){
        return true
      }else {
        return false   
      }
      
     //return false
    },
    hasCustomerPaymentPlan() {
      return true
    }
  },
  methods: {
    getCurrentCustomer(){
          api.customers.getCurrentCustomer().then(response=>{
          this.customer = response.data;
        })
      },
  }
}
</script>

<style scoped>

.command-column {
  width: 360px;
}
.command {
  margin-left: 10px;
  margin-right: 10px;
}
.data-table {
  margin-top: 30px;
}
.wrapper {
  width: 70%;
  margin: auto;
  margin-top: 20px;
  min-width: 1024px;
}

.add-command {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.upload-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  cursor: pointer;
}
label.upload-button {
  margin-bottom: 0;
}
.upload-button:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.uploader-example {
  display: inline;
}
</style>