<template>
  <form ref="form" class="form">
    <div class="form-label-group">
      <input
        :class="{ 'is-invalid': $v.customerName.$error }"
        v-model="customerName"
        type="text"
        id="customerName"
        class="form-control"
        :placeholder="'Company *' | translate"
        required />
      <label for="companyName">{{ "Company *" | translate }}</label>
    </div>
    <div class="form-label-group">
      <input
        :class="{ 'is-invalid': $v.fullName.$error }"
        v-model="fullName"
        type="text"
        id="fullName"
        class="form-control"
        :placeholder="'Name *' | translate"
        required
      />
      <label for="fullName">{{ "Full Name *" | translate }}</label>
    </div>
    <div class="form-label-group">
      <input
        :class="{ 'is-invalid': $v.email.$error }"
        v-model="email"
        type="text"
        id="infoEmail"
        class="form-control"
        :placeholder="'Email *' | translate"
        required
      />
      <label for="infoEmail">{{ "Email *" | translate }}</label>
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
			{{this.errorMessage}}
    </div>
    <div class="form-label-group">
      <select
        v-model="paymentPlanId"
        class="form-control"
        :class="{ 'is-invalid': $v.paymentPlanId.$error }"
        id="paymentPlanId"
        :placeholder="'Payment plan *' | translate"
        required
      >
        <option
          v-for="(d, index) in paymentPlans"
          :key="index"
          :value="d.paymentPlanId"
        >
          {{ d.name }}
        </option>
      </select>
      <label for="paymentPlan">{{ "Payment Plan *" }}</label>
    </div>
		<div class="form-label-group">
      <select
        v-model="language"
        id="languageId"
        class="form-control"
        :placeholder="'Language *' | translate"
        :class="{ 'is-invalid': $v.language.$error }"
        required
      >
        <option
          v-for="(language, index) in languages"
          :key="index"
          :value="language.key"
        >
          {{ language.value }}
        </option>
      </select>
      <label for="Language">{{ "Language *" }}</label>
    </div>
    <div class="buttons-row">
      <button
        type="button"
        @click="modalRef.close()"
        class="command btn btn-default"
      >
        Cancel
      </button>
      <button type="button" @click="saveItem()" :disabled='isLoading' class="command btn btn-primary">
        <i class="fa fa-save"></i> Save
      </button>
    </div>
  </form>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import api from "@/api";
import util from '@/util'
export default {
  //props: ['item', 'modalRef', 'isUserAdmin'],
  props: {
    modalRef: {
      type: Object,
    },
  },
  data() {
    return {
      paymentPlans: [],
      language: "",
      email: "",
      fullName : "",
      languages: util.getLanguages(),
      error: false,
      errorMessage: "",
      customerName: "",
      paymentPlanId: "",
      isLoading: false,
    };
  },
  validations: {
      fullName: {
        required,
      },
      email: {
        email,
        required,
      },
      customerName: {
        required,
      },
      paymentPlanId: {
        required
      },
      language: {
        required
      }
  },
  mounted() {
    let filter = {
      page: 1,
      pageSize: 1000,
      isHidden: false,
      isDeleted: false,
    };
    api.paymentPlan.getAllPlans({ params: filter }).then((result) => {
      this.paymentPlans = result.data.data;
    });
  },
  methods: {
    loadList () {
      this.$store.dispatch('getUserList').then(() => {
        this.error = false
        this.customerName = "",
        this.email = "",
        this.fullName = "",
        this.paymentPlanId = "",
        this.language = ""
        this.errorMessage = ''
        this.modalRef.close()
      })
      this.$store.dispatch('getCustomerList')
    },
    saveItem() {
      this.isLoading = true
      this.error = false
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isLoading = false
        return;
      }
      const item = {
        customerName : this.customerName,
        email : this.email,
        fullName : this.fullName,
        language : this.language,
        paymentPlanId : this.paymentPlanId
      }
      api.registration.registerCustomerAndUser(item).then(() => {
        this.isLoading = false
        this.loadList();
      }).catch( error => {
        this.isLoading = false
        this.error = true;
        this.errorMessage = error.data.Message;
      });
    },
  },
};
</script>
<style scoped>
.form-label-group {
  margin-top: 20px;
}
.date-picker {
  width: 100%;
}
</style>
