<template>
  <div :class="{'gray-out': isUploading}">
    <div :class="{chooser: chooser}">
      <div v-if="filter === 'all' && !filesExists" class="alert alert-primary" role="alert">
        Please upload photos/videos.
      </div>
      <div v-if="filter === 'images' && noImagesExists" class="alert alert-primary" role="alert">
        There are no images to show.
      </div>
      <div v-if="filter === 'videos' && noVideosExists" class="alert alert-primary" role="alert">
        There are no videos to show.
      </div>
      <div v-if="!chooser && filter === 'music' && noMusicExists" class="alert alert-primary" role="alert">
        There is no music to show.
      </div>
      <div v-if="filter === 'created' && noCreatedExists" class="alert alert-primary" role="alert">
        There are no created files to show.
      </div>
      <button v-if="getSelectedFolderId !== -1" @click="backToRoot" type="button" style="font-size: 18px;" class="btn btn-light folder-button"><i class="fa fa-arrow-left"></i> Back</button>
      <span
      v-show="getSelectedFolderId === -1"
      v-for="(folderItem, $indexFolder) in getFolders"
      @click="selectFolder(folderItem.folderId)"
      class="content-item"
      :key="'folderIndex:' + $indexFolder"
      >
        <template v-if="getSelectedFolderId === -1" >
          <!--<i v-if="!deadlineExpired && !chooser" @click.stop="deleteItem(item)" class="btn btn-light fa fa-trash command-icon delete-icon"></i> -->
          <div class="rounded thumb-icon">
            <i class="fa fa-folder folder-icon fa-5x"></i>
          </div>
          <span  class="file-name">{{folderItem.folderName}}</span>
        </template>
      </span>

      <span 
        v-show="!(user.userTypeId === 1 && item.userContentCreationTypeId === 3) && !(nopreview && !item.userContentDataLink) && item.userContentTypeId !== 4 
                && (filter === 'all' || (item.userContentTypeId === 2 && filter === 'images' || item.userContentCreationTypeId === 3 && filter === 'created' 
                || item.userContentTypeId === 1 && filter === 'videos' || item.userContentTypeId === 3 && filter === 'music')) 
                && ((getSelectedFolderId !== -1 && getSelectedFolderId === item.folderId) || (getSelectedFolderId === -1 && item.folderId === null))"
        @click="toggle(item, $event)"
        :class="[{selected: selectedUserContent.indexOf(item) !== -1}, item.userContentTypeName]"
        class="content-item"
        v-for="(item, $index) in userContentList"
        :key="$index"
        draggable="true"
        @dragstart="dragstart($event, item)"
        >
        <template v-if="item.userContentTypeId === 1 && (filter === 'all' || filter === 'videos' || (item.userContentCreationTypeId === 3 && filter === 'created')) 
        && ( (getSelectedFolderId !== -1 && getSelectedFolderId === item.folderId) || (getSelectedFolderId === -1 && item.folderId === null) )">
          <i v-if="!chooser && item.previewDataLink || item.userContentDataLink" @click.stop="playVideo(item)" class="btn btn-light fa fa-play command-icon play-icon"></i>
          <i @click.stop="editVideo(item)" class="btn btn-light fa fa-tv command-icon edit-icon"></i>
          <i v-if="!deadlineExpired && !chooser && item.videoPreviewStatus !== 'InProcess' && item.videoPreviewStatus !== 'InQueue' && item.videoStatus !== 'InProcess' && item.videoStatus !== 'InQueue'" @click.stop="deleteItem(item)" class="btn btn-light fa fa-trash command-icon delete-icon"></i>
          <a v-if="!chooser && item.userContentDataLink" class="btn btn-light fa fa-download command-icon download-icon" :href="API_URL + 'api/get-user-content-data-download/' + item.userContentId"><i class=""></i></a>
          <!-- <a v-if="!chooser && !item.userContentDataLink && item.previewDataLink" class="btn btn-light fa fa-download command-icon download-icon" :href="API_URL + 'api/get-user-content-preview-data/' + item.userContentId"><i class=""></i></a> -->
          <div v-if="!item.thumbnailDataLink" class="rounded thumb-icon">
            <i class="fa fa-play video-icon fa-4x"></i>
          </div>
          <span class="file-name" :class="{'rendered-video-name': item.thumbnailDataLink}">{{item.fileName}}</span>
          <img v-if="item.thumbnailDataLink" style="max-height: 200px; margin: 0px" :src="S3_URL + item.thumbnailDataLink" class="rounded">
          <template v-if="item.userContentCreationTypeId === 3">
              <span v-if="(item.videoStatus === 'InProcess' || item.videoStatus === 'InQueue')" class="video-badge badge badge-light">Completing Video
              <span v-if="item.videoPreviewStatus === 'Failed' || item.videoStatus === 'Failed'" class="video-badge badge badge-danger">Failed</span>
                <div class="video-progress-spinner spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </span>
              <span v-if="item.userContentDataLink && item.videoStatus === 'Generated'" class="video-badge badge badge-success">Complete</span>
              <span v-if="item.videoStatus === 'Failed'" class="video-badge badge badge-danger">Failed</span>
              <template v-if="(item.videoStatus !== 'InProcess' && item.videoStatus !== 'InQueue' && item.videoStatus !== 'Generated' && item.videoStatus !== 'Failed')">
                <span v-if="(item.videoPreviewStatus === 'InProcess' || item.videoPreviewStatus === 'InQueue')" class="video-badge badge badge-light">Generating Preview
                  <div class="video-progress-spinner spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </span>
                <span v-if="item.previewDataLink && item.videoPreviewStatus === 'Generated'" class="video-badge badge badge-warning">Preview Ready</span>
                <span v-if="item.videoPreviewStatus === 'Failed'" class="video-badge badge badge-danger">Preview failed</span>
              </template>
          </template>
          <video :src="S3_URL + item.userContentDataLink" v-if="item.userContentCreationTypeId !== 3" style="display:none" :id="'v-' + item.userContentDataLink"></video>
        </template>
        <template v-if="(item.userContentTypeId === 2 || item.userContentTypeId === 4) && (filter === 'all' || filter === 'images' || (item.userContentCreationTypeId === 3 && filter === 'created'))
        && ( (getSelectedFolderId !== -1 && getSelectedFolderId === item.folderId) || (getSelectedFolderId === -1 && item.folderId === null) )">
          <i v-if="[1].indexOf(user.userTypeId) === -1 && !deadlineExpired && !chooser" @click.stop="editPhoto(userImages.indexOf(item))" class="btn btn-light fa fa-pencil command-icon edit-icon"></i>
          <i v-if="!deadlineExpired && !chooser" @click.stop="deleteItem(item)" class="btn btn-light fa fa-trash command-icon delete-icon"></i>
          <a onclick="function(e){e.stopPropagation();}" v-if="!chooser" class="btn btn-light fa fa-download command-icon download-icon" :href="API_URL + 'api/get-user-content-data-download/' + item.userContentId"><i class=""></i></a>
          <img style="max-height: 200px; margin: 5px" :src="S3_URL + item.thumbnailDataLink" class="rounded">
          <span  class="file-name">{{item.fileName}}</span>
        </template>
        <template v-if="!chooser && item.userContentTypeId === 3 && (filter === 'all' || filter === 'music')
        && ( (getSelectedFolderId !== -1 && getSelectedFolderId === item.folderId) || (getSelectedFolderId === -1 && item.folderId === null) )">
          <i v-if="!deadlineExpired && !chooser" @click.stop="deleteItem(item)" class="btn btn-light fa fa-trash command-icon delete-icon"></i>
          <a v-if="!chooser && !item.downloadForbidden" class="btn btn-light fa fa-download command-icon download-icon" :href="API_URL + 'api/get-user-content-data-download/' + item.userContentId"><i class=""></i></a>
          <i v-if="!chooser" @click.stop="playMusic(item)" class="btn btn-light fa fa-play command-icon play-icon"></i>
          <div class="rounded thumb-icon">
            <i class="fa fa-music audio-icon fa-4x"></i>
          </div>
          <span  class="file-name">{{item.fileName}}</span>
        </template>
      </span>
      <uploader
        v-if="uploadUpdateKey && !deadlineExpired"
        ref="uploader"
        :disabled="isUploading"
        :options="getOptions"
        :key="getOptions.target"
        class="uploader-example"
        @file-added="onFileAdded"
        @upload-start="onUploadStart"
        @file-error="onFileError"
        @complete="onComplete"
      >
        <uploader-unsupport></uploader-unsupport>
        <uploader-btn :attrs="attrs" :class="{disabled: isUploading}" :disabled="isUploading" class="btn btn-light upload-button" style="margin-top: 9px;">
          <i style="font-size: 22px; margin-top: 10px;" class="fa fa-upload"></i>
          <span>Upload</span>
        </uploader-btn>
        <div class="spinner-border upload" v-if="isUploading" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </uploader>
      <button v-if="getSelectedFolderId === -1" @click="openAddFolder" type="button" class="btn btn-light folder-button"><i class="fa fa-folder"></i> Create Folder</button>
    </div>
    <PhotoEditor ref="photoEditor" :images="userImages"></PhotoEditor>
    <sweet-modal ref="confirmDelete" icon="warning">
      You are about to delete this file. Are you sure?
      <div>
        <button class="btn btn-light modal-button" @click="$refs.confirmDelete.close()" type="button">Cancel</button>
        <button class="btn btn-danger modal-button" @click="confirmDelete()" type="button">Delete</button>
      </div>
    </sweet-modal>
    <sweet-modal ref="deleteError" icon="info">
      Content can't be deleted since it is a part of a video.
      <div>
        <button class="btn btn-light modal-button" @click="$refs.deleteError.close()" type="button">Ok</button>
      </div>
    </sweet-modal>
    <sweet-modal ref="playVideoModal" class="play-video-modal" title="Video" @close="closeVideoPlayer()">
      <h5>Preview</h5>
      <template v-if="itemToPlay.userContentCreationTypeId === 3">
        <video style="height: 480px; width: 720px;" ref="videoPlayer" :src="S3_URL + itemToPlay.previewDataLink" controls></video>
        <br />
        Share this preview using the link below:
        <br/>
        {{shareContentLink + '/' + itemToPlay.videoId}}
        <br />
        <a @click="$refs.videoPlayer.pause()" v-if="itemToPlay.userContentDataLink" target="_blank" :href="S3_URL + itemToPlay.userContentDataLink" class="btn btn-default">Open Completed Video</a>
      </template>
      <video v-if="itemToPlay.userContentCreationTypeId !== 3" style="height: 480px; width: 720px;" ref="videoPlayer" :src="S3_URL + itemToPlay.userContentDataLink" controls></video>
      <!-- <br />
      <br />
      <a v-if="itemToPlay.userContentDataLink" :download="itemToPlay.name + 'mp4'" :href="S3_URL + itemToPlay.userContentDataLink" class="btn btn-default">Download Completed Video</a> -->
    </sweet-modal>
    <sweet-modal ref="playMusicModal" class="play-music-modal" title="Music Preview" @close="closeMusicPlayer()">
      <audio ref="musicPlayer" style="width: 100%;" :src="S3_URL + musicItemToPlay" controls controlsList="nodownload"></audio>
    </sweet-modal>
    <sweet-modal ref="renameVideo" title="Rename Video" >
      <div class="form-label-group" style="">
        <input id="videoFileName" v-model="selectedVideo.fileName" class="form-control" :placeholder="'Video Name' | translate" />
        <label for="videoFileName">{{'Video Name' | translate}}</label>
      </div>
      <div style="text-align:center;">
        <button class="btn btn-primary modal-button" @click="renameVideo()" type="button">Save</button>
      </div>
    </sweet-modal>
    <sweet-modal ref="uploadError" icon="error">
      The file "{{errorFileName}}" seems to be corrupted and can't be uploaded. Please check the file format and try again.
      <div>
        <button class="btn btn-light modal-button" @click="$refs.uploadError.close()" type="button">Ok</button>
      </div>
    </sweet-modal>
    <sweet-modal ref="uploadErrorModal" icon="error">
      <div>
            <h2 class="modal-title">Upload Canceled</h2>
        </div>
        <div class="modal-body">
            <h3>{{errorUploadMessage}}</h3>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-lg" data-dismiss="modal" @click="closeUploadErrorModal">OK</button>
        </div>    
    </sweet-modal>
    <sweet-modal :blocking="true" title="Create new folder" ref="AddFolderModal" v-on:close="onAddFolderDialogClosed">
      <AddFolderForm @closeAddFolderForm="closeAddFolderForm" @addFolder="addFolder"/>
    </sweet-modal>
    <video ref="videoContentList" id="videoHidden" style="visibility: hidden"/>
  </div>
</template>
<script>
import moment from 'moment'
import api from '@/api'
import config from '@/config'
import util from '@/util'
import PhotoEditor from '@/components/common/PhotoEditor'
import AddFolderForm from '@/components/folder/AddFolderForm'

// import VideoCreator from '@/components/video/VideoCreator'
export default {
  props: {
    chooser: Boolean,
    single: Boolean,
    additionalFilter: String,
    modalRef: Object,
    openVideoCreator: Function,
    change: Function,
    readonly: Boolean,
    nopreview: Boolean
  },
  data () {
    return {
      errorUploadMessage: "",
      uploadFlag:false,
      uploadUpdateKey: true,
      itemToDelete: -1,
      itemToPlay: '',
      musicItemToPlay: '',
      selectedVideo: {},
      attrs: {
        accept: this.chooser ? '.jpg,.png,.jpeg,.jpe,.gif,.mov' : '.jpg,.png,.jpeg,.jpe,.mp4,.gif,.mov,.mp3'
      },
      errorMessage: '',
      uploading: false,
      errorFileName: ''
    }
  },
  components: {
    PhotoEditor,
    AddFolderForm
  },
  computed: {
    getOptions() {
     return {
        target: `${config.API_URL}api/add-user-content${util.getUserContextUrlParam()}${this.$store.getters.getOnlySelectedFolderId}`,
        testChunks: false,
        chunkSize: 1000000000,
        permanentErrors : [400, 404, 415, 500, 501],
        allowDuplicateUploads: true,
        headers: {
          Authorization: `Bearer ${util.getSessionData().access_token}`
        },
        successStatuses: [200,201, 202, 204]
      }
    },
    uploadUpdate () {
      return this.uploadUpdateKey
    },
    isUploading () {
      return this.uploading
    },
    filter () {
      return this.$store.getters.contentFilter
    },
    API_URL () {
      return config.API_URL
    },
    S3_URL () {
      return config.S3_URL
    },
    userImages () {
      return this.$store.getters.userContentList(this.additionalFilter, this.chooser).filter((item)=> {
        return item.userContentTypeId === 2
      })
    },
    userContentList () {
      return this.$store.getters.userContentList(this.additionalFilter, this.chooser)
    },
    getFolders () {
      return this.$store.getters.folderList
    },
    getSelectedFolderId () {
      return this.$store.getters.getSelectedFolderId
    },
    selectedUserContent () {
      return this.$store.getters.selectedUserContent
    },
    filesExists () {
      return this.$store.getters.userContentList(this.additionalFilter, this.chooser).length > 0
    },
    noImagesExists () {
      return this.filter === 'images' && this.$store.getters.userContentList(this.additionalFilter, this.chooser).filter((item) => { 
          return item.userContentTypeId === 2 
          }).length === 0
    },
    noVideosExists () {
      return this.filter === 'videos' && this.$store.getters.userContentList(this.additionalFilter, this.chooser).filter((item) => { 
          return item.userContentTypeId === 1 
          }).length === 0
    },
    noMusicExists () {
      return this.filter === 'music' && this.$store.getters.userContentList(this.additionalFilter, this.chooser).filter((item) => { 
          return item.userContentTypeId === 3
          }).length === 0
    },
    noCreatedExists () {
      return this.$store.getters.userContentList(this.additionalFilter, this.chooser).filter((item) => { 
          return item.userContentCreationTypeId === 3 
          }).length === 0
    },
    user () {
      return util.getSessionInfo()
    },
    shareContentLink() {        
      return document.location.href.split('#')[0] + '#/view/' + this.user.shareContentLink; 
    },
    deadlineExpired () {
      return [1,2].indexOf(this.user.userTypeId) !== -1 && this.user.eventInfo && this.user.eventInfo.eventDate && moment(this.user.eventInfo.eventDate) < moment(new Date())
    },
  },
  async mounted () {
    await this.$store.dispatch('getUserEvent', util.getUserContext())
    await this.$store.commit('resetSelectedFolderId')
    await this.$store.dispatch('getFolderList', this.$store.getters.eventInfo.eventId)
  },
  methods: {
    onFileAdded(file) {
      var url = URL.createObjectURL(file.file);
      document.getElementById('videoHidden').setAttribute('src',url);
      var myVideo = document.getElementById("videoHidden");
      myVideo.load();
    },
    onUploadStart() { 
      if(this.uploadFlag===false){
        this.$refs.uploader.uploader.pause();
      }
      this.uploading=true;
      var myVideo = document.getElementById("videoHidden");
      myVideo.load();

      myVideo.onloadedmetadata=()=>{
        if(myVideo.duration>=1200){
          this.$refs.uploader.uploader.cancel();
          this.uploading=false;
          this.uploadFlag=false;
          this.errorUploadMessage='This video is too long, please upload smaller video!';
          this.openUploadErrorModal();
          return
        }
      }
      if(this.uploadFlag===false){
        this.uploadFlag=true;
        this.$refs.uploader.uploader.resume()
      }
    },
    onFileError(_rootFile, _file, message) {
      this.uploading=false;
      this.errorFileName = _rootFile && _file.name
      this.$refs.uploadError.open()

      let messageObj = JSON.parse(message);
      this.errorUploadMessage=messageObj.Message;
      this.$refs.uploader.uploader.cancel();
      this.uploadFlag=false;
      this.openUploadErrorModal();
    },
    onComplete() {
      this.uploading = false
      this.$store.dispatch('getUserContentList')
      this.$store.commit('selectNoneUserContentItems')
    },
    openUploadErrorModal(){
      this.$refs.uploadErrorModal.open();
    },
    closeUploadErrorModal(){
      this.errorUploadMessage='';
      this.$refs.uploadErrorModal.close();
    },
    renameVideo () {
      api.video.rename(this.selectedVideo)
      this.$refs.renameVideo.close()
    },
    openRenameDialog (item) {
      this.selectedVideo = item
      this.$refs.renameVideo.open()
    },
    dragstart (ev, item) {
      this.$store.commit('setDragInProgress', true)
      ev.dataTransfer.setData("text/plain", JSON.stringify(item));
    },
    playVideo (item) {
      this.$refs.playVideoModal.open()
      this.itemToPlay = item
    },
    playMusic (item) {
      this.$refs.playMusicModal.open()
      this.musicItemToPlay = item.userContentDataLink
    },
    closeVideoPlayer () {
      this.$refs.videoPlayer.pause()
    },
    closeMusicPlayer () {
      this.$refs.musicPlayer.pause()
    },
    editPhoto (index) {
      this.$refs.photoEditor.open(index)
    },
    editVideo (item) {
      this.openVideoCreator(item)
    },
    deleteItem (item) {
      if (!item.deletable) {
        this.$refs.deleteError.open()
      } else {
        this.itemToDelete = item.userContentId
        this.$refs.confirmDelete.open()
      }
    },
    confirmDelete () {
      this.$refs.confirmDelete.close()
      api.userContent.deleteUserContent(this.itemToDelete).then((result) => {
        // eslint-disable-next-line
        if(result.data) {
          this.errorMessage = result.data
          this.$refs.deleteError.open()
        } else {
          this.$store.dispatch('getUserContentList')
        }
      })
    },
    toggle (item, event) {
      if (event.target.tagName === 'A') { // in case of download action prevent item click
        return
      }
      // if (!this.chooser && !this.readonly) {
      //   let imageIndex = 0
      //   switch (item.userContentTypeId) {
      //     case 1:
      //       if (item.userContentDataLink) {
      //         this.playVideo(item)
      //       } else {
      //         this.editVideo(item)
      //       }
      //       return
      //     case 2: 
      //       imageIndex = this.userImages.indexOf(item)
      //       this.$refs.photoEditor.open(imageIndex)
      //       return
      //     case 3: 
      //       this.playMusic(item)
      //       return
      //   }
      // }
      // if (this.single) {
      //   if (this.change) {
      //     this.change(item.userContentId)
      //   }
      //   this.modalRef.close()
      //   return
      // }
      // if (this.chooser) {
        // let videoElement = document.getElementById(item.userContentDataLink)
        // let duration = videoElement && videoElement.duration
        // item.duration = duration || 0

        if (this.single) {
          if (this.change) {
            this.change(item.userContentId)
          }
          this.modalRef.close()
          return
        }
        let len = this.selectedUserContent.length

        if (event.shiftKey && len) {
          let lastEl = this.selectedUserContent[len - 1]
          let indexFrom = this.userContentList.indexOf(lastEl)
          let itemIndex = this.userContentList.indexOf(item)
          for (let i = indexFrom + 1; i <= itemIndex; i++) {
            if (this.selectedUserContent.indexOf(this.userContentList[i]) === -1) {
              this.$store.commit('toggleUserContent', this.userContentList[i], event.shiftKey)
            }
          }
        } else {
          this.$store.commit('toggleUserContent', item, false)
        }
      // }
    },
    openAddFolder(){
        this.$refs.AddFolderModal.open();
    },
    closeAddFolderForm(){
       this.$refs.AddFolderModal.close();
    },
    addFolder(formData){
        this.isLoading=true;
        formData.eventId = this.$store.getters.eventInfo.eventId
        api.folder.postFolder(formData).then(()=>{
        this.isLoading=false;
        this.$refs.AddFolderModal.close()

    })
    },
    selectFolder(folderId)
    {
      this.$store.commit('setSelectedFolderId', folderId)
    },
    onAddFolderDialogClosed()
    {
      this.$store.dispatch('getFolderList', this.$store.getters.eventInfo.eventId)
    },
    backToRoot(){
        this.$store.commit('resetSelectedFolderId')
    },
  }
}
</script>
<style lang="css" scoped>

.content-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 2px;
  border: 2px solid transparent;
  margin: 5px;
  cursor: pointer;
}
.content-item.video .thumb-icon,
.content-item.audio .thumb-icon{
  border: 1px solid lightgray;
  width: 210px;
  height: 147px;
  padding: 2px;
  text-align: center;
  background-color: #fff;
}
.content-item.video .thumb-icon .video-icon,
.content-item.audio .thumb-icon .audio-icon {
  margin-top: 34px;
}
.content-item.selected {
  border: 2px solid #007bff;
  border-radius: 3px;
}
.content-item:hover .command-icon{
  display: block;
}
#hidden{
  display: none;
}
.delete-icon {
   top: 44px;
}
.download-icon {
  top: 78px;
}
.play-icon {
  top: 112px;
}
.play-video-modal {
  text-align: center;
}
.file-name {
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  height: 35px;
  white-space: nowrap;
  font-size: 10px;
  margin-top: -5px;
  margin-left: 5px;
  max-width: 210px;
}
.video-file-name a{
  display: none;
}
.content-item:hover .video-file-name a{
  max-height: 16px;
  display: inline-block;
  padding-top: 2px;
  margin-bottom: 5px;
}
/* .content-item:hover .video-file-name {
  // margin-top: -35px;
} */
.video-file-name {
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  height: 35px;
  white-space: nowrap;
  font-size: 10px;
  margin-top: -25px;
  margin-left: 10px;
}
.upload-button {
  border-radius: 50%;
  font-size: 16px;
  width: 74px;
  height: 74px;
}
.folder-button {
  border-radius: 50%;
  font-size: 12px;
  width: 74px;
  height: 74px;
  top: 30x;
  background: none;
  color: #666666;
}
.uploader-example {
  display: inline-block;
  height: 100px;
  width: 100px;
  padding: 10px;
  margin-top: 28px;
  margin-left: 32px;
}
.gray-out {
  opacity: .5;
}
.video-badge {
  position: absolute;
  left: 12px;
  top: 12px;
}
.video-progress-spinner {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}
.rendered-video-name {
  position: absolute;
  top: 148px;
}
.play-video-modal >>>.sweet-modal {
  max-width: 790px;
}
</style>