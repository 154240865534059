<template>
  <div class="row">
    <div class="content col-9">
      <div class="image" :class="textSlide.background">
        <img v-if="previewData" :src="previewData" />
      </div>
    </div>
    <div class="form col-3">
      <button @click="saveText()" class="btn btn-primary save-button" type="button"><i class="fa fa-save"></i> {{'Save' | translate}}</button>
      <form>
        <div class="form-label-group" style="margin-top: 48px;">
          <textarea maxlength="1000" @change="change" v-model="textSlide.text" class="form-control" :placeholder="'Text' | translate" autofocus></textarea>
        </div>

        <div class="form-label-group">
          <input @change="change" v-model="textSlide.fontSize" type="number" min="10" max="180" id="fontSize" class="form-control" :placeholder="'Text Size' | translate" required>
          <label for="fontSize">{{'Text Size' | translate}}</label>
          <v-swatches :swatch-size="26" shapes="squares" :border-radius="0" :show-border="false" :spacing-size="0" colors="text-advanced" :swatches="colors" row-length="10" @input="change()" v-model="textSlide.color" popover-to="left"></v-swatches>
           <!-- <v-swatches
              v-model="color"
              :swatches="colors"
              colors="text-advanced"
              row-length="10"
              popover-to="left"
            ></v-swatches> -->
        </div>
        <div class="form-label-group">
          <select @change="change" v-model="textSlide.fontType" id="fontType" class="form-control" :placeholder="'Font' | translate" required>
            <option v-for="font in fonts" :key="font" :value="font">{{font}}</option>
            <!-- <option value="Arial">Arial</option>
            <option value="Avenir">Avenir</option>
            <option value="Courier New">Courier New</option>
            <option value="Lane - Narrow">Lane - Narrow</option>
            <option value="Modern Sans">Modern Sans</option>
            <option value="Tahoma">Tahoma</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Vivaldi">Vivaldi</option> -->
          </select>
          <label for="fontType">{{'Font' | translate}}</label>
        </div>
        <div class="form-label-group">
          <select @change="change" v-model="textSlide.background" id="background" class="form-control" :placeholder="'Background' | translate" required>
            <option value="themeImage">Theme Image</option>
            <option value="black">Black</option>
            <option value="white">White</option>
          </select>
          <label for="background">{{'Backgrund' | translate}}</label>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import api from '@/api'
import util from '@/util'
import VSwatches from 'vue-swatches'

export default {
  props: {
    videoSlideId: Number,
    modalRef: Object
  },
  components: {
    VSwatches
  },
  data () {
    return {
      textSlide: this.getDefaultSlide(),
      previewData: null,
      colors: util.getColors(),
      fonts: util.getFonts()
    }
  },
  computed: {
    video () {
      return this.$store.getters.selectedVideo
    }
  },
  methods: {
    reset () {
      this.textSlide.text = ''
      this.previewData = null
      this.change()
    },
    getDefaultSlide () {
      return {
        text: '',
        fontSize: 80,
        fontType: 'Ahellya',
        background: 'themeImage',
        themeId: -1,
        color: "#FFFFFF"
      }
    },
    saveText () {
      this.modalRef.close()
      api.video.saveTextSlide({
          videoId: this.video.videoId,
          ...this.textSlide
        }).then(() => {
        this.$store.dispatch('getUserContentList')
        this.$store.dispatch('getVideo', this.video.videoId)
      })
    },
    change () {
      this.textSlide.themeId = this.$store.getters.selectedVideo.themeId
      api.video.getTextSlidePreview(this.textSlide).then(result => {
        this.previewData = result.data
      })
    }
  }
}
</script>
<style lang="css" scoped>
.form {
  float: right;
}
.content {
  height: 520px;
  text-align: center;
}
.content .image {
  display: inline-block;
  width: 772px;
  height: 436px;
  border: 1px solid gray;
  padding: 1px;
}
.content .image img {
  width: 768px;
  height: 432px;
}
.content .image.black {
  background: black;
}
.content .image.white {
  background: white;
}
.save-button {
  margin-bottom: 12px;
  float: right;
}
.form-label-group >>>.vue-swatches__trigger  {
  height: 38px !important;
  width: 38px !important;
  margin-left: 12px;
  border: 1px solid lightgray;
}
</style>