import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import vuexI18n from 'vuex-i18n'
import en from '../assets/translations/en'
import nl from '../assets/translations/nl'
import fr from '../assets/translations/fr'
import de from '../assets/translations/de'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    error: {
      show: false,
      message: ''
    },
    infoMsg: {
      show: false,
      message: ''
    },
    externalRecordUrl:'',
    isLoading:false,
    isUrlLoading:false,
    externalMusic:[],
    userContentList: [],
    selectedUserContent: [],
    selectedTheme: {},
    selectedVideo: {
      videoSlides: []
    },
    currentUser: {},
    exportStatus: {},
    themesList: [],
    folderList: [],
    stockMusic: [],
    themeStockSlides: [],
    themeSlides: [],
    contentFilter: 'all',
    coverPhotoContentId: -1,
    dragInProgress: false,
    eventList: [],
    userList: [],
    deviceList: [],
    customerList: [],
    customerWithDefaultPaymentMethod: {},
    customer : {},
    paymentMethodsList: [],
    paymentPlansForCustomers: [],
    couponList : [],
    eventInfo: {},
    videoStates: {},
    paymentPlans:[],
    paymentPlan:[],
    invoiceList: [],
    invoiceLoading:false,
    selectedFolderId: -1
  },
  getters,
  mutations,
  actions
})

Vue.use(vuexI18n.plugin, store)

Vue.i18n.add('en', en)
Vue.i18n.add('nl', nl)
Vue.i18n.add('fr', fr)
Vue.i18n.add('de', de)
// eslint-disable-next-line
Vue.i18n.set(localStorage.lang || 'en')
