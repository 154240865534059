<template>
  <form ref="form" class="form" @submit.prevent="submitAddFolder">
    <div class="form-label-group">
      <input :class="{ 'is-invalid': $v.newFolder.folderName.$error }" type="text" class="form-control" placeholder="Enter Folder Name" v-model.trim="newFolder.folderName" >
      <label for="folderName">{{'Folder Name *' | translate}}</label>
    </div>
     <div class="buttons-row">
      <button type="button" @click="closeAddFolderForm" class="command btn btn-default">Cancel</button>
      <button type="submit" class="command btn btn-primary"><i class="fa fa-save"></i> Save</button>
    </div>
  </form>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
    
    computed:{
        rules(){
            return {folderName:{required}}
        },
    },
    data(){
        return{
            newFolder:{
                folderName:"",
                parentFolderId: 0,
                eventId:0
            },
        }
    },
    validations() {
        return{
            newFolder:this.rules
        }
    },
    methods:{
        closeAddFolderForm(){
            this.$emit('closeAddFolderForm');
            this.setToStartValues();
        },
        submitAddFolder(){ 
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }       
            var formData={
                folderName:this.newFolder.folderName,
                parentFolderId:this.newFolder.parentFolderId,
                eventId:this.newFolder.eventId
            }
            this.$emit('addFolder',formData);
            this.setToStartValues();
        },
        setToStartValues(){
                this.folderName="",
                this.parentFolderId=0,
                this.eventId=0
        }
    }
}
</script>
<style scoped>
.form-label-group {
  margin-top: 20px;
  /* text-align: left; */
}
.sub-form-group {
  position: relative;
  /* text-align: left; */
  /* padding-left: 10px; */
  /* margin-top: 20px; */
  width: 33.33%; 
}
.sub-form-group label {
  position: absolute;
  top: 0;
  left: 11px;
}
.sub-form-group select {
  height: 46px;
}
.sub-form-group input {
  height: 46px;
}
.date-picker {
  width: 50%;
}
.picker-label {
  font-size: 12px;
  color: #777;
}
</style>
