<template>
  <ul class="nav nav-pills filter-buttons">
    <li class="nav-item">
      <a class="nav-link" @click.prevent="setFilter('all')" :class="{active: filter === 'all'}" href="#">All</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" @click.prevent="setFilter('images')" :class="{active: filter === 'images'}" href="#">Images</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" @click.prevent="setFilter('videos')" :class="{active: filter === 'videos'}" href="#">Videos</a>
    </li>
    <li v-if="!chooser" class="nav-item">
      <a class="nav-link" @click.prevent="setFilter('music')" :class="{active: filter === 'music'}" href="#">Music</a>
    </li>
    <li class="nav-item" v-if="user.userTypeId !== 1">
      <a class="nav-link" @click.prevent="setFilter('created')" :class="{active: filter === 'created'}" href="#">Created</a>
    </li>
  </ul>
</template>
<script>
import util from '@/util'
export default {
  props: {
    chooser: Boolean
  },
  computed: {
    user () {
      return util.getSessionInfo()
    },
    filter () {
      return this.$store.getters.contentFilter
    }
  },
  methods: {
    setFilter (filter) {
      this.$store.commit('setContentFilter', filter)
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-buttons {
  
}
</style>