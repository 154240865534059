<template>
  <div @dragend="cancelDrag()" id="app" :class="[$route.name,device]">
    <router-view></router-view>
    <div v-if="notificationMessage" class="alert alert-success notification-message" role="alert">
      {{notificationMessage}} <i class="fa fa-close clickable" @click="notificationMessage = ''"></i>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import api from '@/api'
import util from '@/util'
import "vue-swatches/dist/vue-swatches.css"
import { EventBus } from '@/event'
export default {
  name: 'app',
  data () {
    return {
      device: '',
      notificationMessage: ''
    }
  },
  created () {
    localStorage.lang = localStorage.lang || 'en'
    Vue.i18n.set(localStorage.lang)
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.device = 'mobile'
    } else {
      this.device = 'desktop'
    }
    if (util.getSessionInfo().userTypeId !== 1 && util.getSessionInfo().userTypeId !== 2 && sessionStorage.sessionData != undefined) {
        this.$store.dispatch('getEventList', util.getSessionInfo().userId)
        this.$store.dispatch('getUserEvent', util.getUserContext())
    }

    EventBus.$on('notification', (payLoad) => {
      if(sessionStorage.sessionData != undefined) {
        this.notificationMessage = payLoad.message
        setTimeout(() => {
        this.notificationMessage = ''
        }, 10000)
      }
      })
    window.notificationInterval = setInterval(() => {
        if(sessionStorage.sessionData != undefined) {
          api.users.getAllNotifications().then(result => {
            if (result.body.length) {
              this.$store.dispatch('getUserContentList')
              api.users.setNotificationRead(result.body[0].notificationLogId)
              if(result.body[0].type=="TOL" || result.body[0].type=="REGULAR")
              {
                EventBus.$emit('notification', {message: 'Your video is ready.', entityId: result.body[0].entityId})
              }
              else
              {
                EventBus.$emit('notification', {message: 'Video generation has failed', entityId: result.body[0].entityId})
              }
            }
          })
        }
    }, 10000)
      window.notificationInterval = setInterval(() => {
         if(sessionStorage.sessionData != undefined) {
          api.users.getAllNotifications().then(result => {
            if (result.body.length) {
              this.$store.dispatch('getUserContentList')
              api.users.setNotificationRead(result.body[0].notificationLogId)
              if(result.body[0].type=="TOL" || result.body[0].type=="REGULAR")
              {
                EventBus.$emit('notification', {message: 'Your video is ready.', entityId: result.body[0].entityId})
              }
              else
              {
                EventBus.$emit('notification', {message: 'Video generation has failed', entityId: result.body[0].entityId})
              }
            }
          })
        }
      }, 10000)
      window.contentInterval = setInterval(() => {
         if(sessionStorage.sessionData != undefined) {
            this.$store.dispatch('getUserContentList')
         }
      }, 60000)
    //}
   //generate video
    EventBus.$on('generateVideo', (payload) => {
      if(sessionStorage.sessionData != undefined) {
      this.$store.commit('setVideoState', { id: payload.id, video: {previewInProgress: true} })
      api.video.generatePreview({id: payload.id, seconds: payload.seconds || 0}).then((response) => {
        let interval = setInterval(() => {
          // self.$store.dispatch('updateExportStatus', response.data.id)
          api.video.getVideoExport(response.data.id).then(result => {
            this.$store.commit('setVideoState', { id: payload.id, video: { ...result.data, previewInProgress: true } })
            // context.commit('setExportStatus', result.data)
          })
          let exportInfo = this.$store.getters.getVideoStates[payload.id] || {}
          if (exportInfo.progress === 100) {
            clearInterval(interval)
            api.video.saveVideoExport({
                exportId: exportInfo.id, 
                videoId: payload.id,
                projectUrl: exportInfo.project
              }).then((res) => {
              exportInfo.previewDataLink = res.data
              exportInfo.previewInProgress = false
              this.$store.commit('setVideoState', { id: payload.id, video: {...exportInfo, previewInProgress: false} })
              this.$store.dispatch('getUserContentList')
              EventBus.$emit('notification', {message: 'Your video is ready.'})
            })
          }
        }, 5000)
      })
      }
    })
    //generateTOLVideo 
    EventBus.$on('generateTOLVideo', (payload) => {
      if(sessionStorage.sessionData != undefined) {
      this.$store.commit('setVideoState', { id: payload.id, video: {previewInProgress: true, previewProgressValue: 0} })
      api.video.generateVideo(payload.id, true).then(() => {
        // this.previewInProgress = true
        // this.previewProgressValue = 0
        let interval = setInterval(() => {
          api.video.getTolLink(payload.id, true).then((res) => {
            let exportInfo = this.$store.getters.getVideoStates[payload.id]
            exportInfo.previewProgressValue += 0.6
            if (exportInfo.previewProgressValue > 100) {
              exportInfo.previewProgressValue = 100
            }
            this.$store.commit('setVideoState', { id: payload.id, video: {...exportInfo, previewInProgress: true, previewProgressValue: exportInfo.previewProgressValue} })
            if (res.data) {
              clearInterval(interval)
              exportInfo.previewDataLink = res.data.previewDataLink
              exportInfo.previewInProgress = false
              this.$store.commit('setVideoState', { id: payload.id, video: exportInfo })
              this.$store.dispatch('getVideo', payload.id)
              this.$store.dispatch('getUserContentList')
              EventBus.$emit('notification', {message: 'Your video is ready.'})
            }
          })
          
        }, 5000)
      })
      }
    })
    if(sessionStorage.sessionData != undefined) {
    api.video.getUserVideos(util.getSessionInfo().userId).then(result => {
      result.data.map(video => {
        if (video.typeId === 1 || video.typeId === 3) {// Regular/Simple White
          if (video.exportId && ((video.previewStatus && video.previewStatus !== 'Generated'))) {
            this.$store.commit('setVideoState', { id: video.videoId, video: {previewInProgress: true} })
            let interval = setInterval(() => {
              // self.$store.dispatch('updateExportStatus', response.data.id)
              api.video.getVideoExport(video.exportId).then(result => {
                this.$store.commit('setVideoState', { id: video.videoId, video: { ...result.data, previewInProgress: true } })
                // context.commit('setExportStatus', result.data)
              })
              let exportInfo = this.$store.getters.getVideoStates[video.videoId] || {}
              if (exportInfo.progress === 100) {
                clearInterval(interval)
                api.video.saveVideoExport({
                    exportId: exportInfo.id, 
                    videoId: video.videoId,
                    projectUrl: exportInfo.project
                  }).then((res) => {
                  exportInfo.previewDataLink = res.data
                  exportInfo.previewInProgress = false
                  this.$store.commit('setVideoState', { id: video.videoId, video: {...exportInfo, previewInProgress: false} })
                  this.$store.dispatch('getUserContentList')
                  EventBus.$emit('notification', {message: 'Your video is ready.'})
                })
              }
            }, 5000)
          } else {
            this.$store.commit('setVideoState', { id: video.videoId, video: {previewDataLink: video.previewDataLink} })
          }
        } else { // TOL
          if ((video.previewStatus && video.previewStatus !== 'Generated')) {
            this.$store.commit('setVideoState', { id: video.videoId, video: {previewInProgress: true, previewProgressValue: 0} })
            let interval = setInterval(() => {
              api.video.getTolLink(video.videoId, true).then((res) => {
                let exportInfo = this.$store.getters.getVideoStates[video.videoId]
                exportInfo.previewProgressValue += 0.6
                if (exportInfo.previewProgressValue > 80) {
                  exportInfo.previewProgressValue += 0.2
                }
                if (exportInfo.previewProgressValue > 90) {
                  exportInfo.previewProgressValue += 0.06
                }
                if (exportInfo.previewProgressValue > 98) {
                  exportInfo.previewProgressValue = 99
                }
                this.$store.commit('setVideoState', { id: video.videoId, video: {...exportInfo, previewInProgress: true, previewProgressValue: exportInfo.previewProgressValue} })
                if (res.data) {
                  clearInterval(interval)
                  exportInfo.previewDataLink = res.data.previewDataLink
                  exportInfo.previewInProgress = false
                  this.$store.commit('setVideoState', { id: video.videoId, video: exportInfo })
                  this.$store.dispatch('getVideo', video.videoId)
                  this.$store.dispatch('getUserContentList')
                  EventBus.$emit('notification', {message: 'Your video is ready.'})
                }
              })
              
            }, 5000)
          }
        }
      })
    })
    }
  },
  methods: {
    cancelDrag () {
      this.$store.commit('setDragInProgress', false)
    }
  }
}
</script>

<style lang="scss">
  @import './style/main';
  @import './style/forms';
  @import './style/fixes';
  .mobile .upload-command-buttons,
  .mobile .command-buttons-container,
  .mobile .wrapper .content-item .play-icon,
  .mobile .wrapper .content-item .edit-icon {
    display: none !important;
  }
  .mobile .wrapper {
    text-align: center;
  }
  .mobile .wrapper .filter-buttons-container {
    float: initial;
  }
  .show-on-mobile {
    display: none;
  }
  .mobile .show-on-mobile {
    display: block;
  }
  .notification-message {
    position: fixed;
    bottom: 16px;
    right: 24px;
    z-index: 11000;
  }
  .notification-message i {
    margin-left: 16px;
  }
</style>
