<template>
  <sweet-modal :title="images[current] && images[current].fileName" ref="modal" width="100%" height="700px">
    <!-- <button class="btn btn-default " type="button" > -->
      <i class="fa fa-2x fa-chevron-left prev-button" @click="prev()"></i>
      <!-- </button> -->
    <!-- <button class="btn btn-default " type="button" > -->
      <i class="next-button fa fa-2x fa-chevron-right" @click="next()"></i>
    <!-- </button> -->
    <span v-if="savedMsg" class="saved-msg">Photo saved.</span>
    <button class="btn btn-primary save-button" type="button" @click="save()"><i class="fa fa-save"></i> Save</button>
    <tui-image-editor ref="tuiImageEditor" :include-ui="useDefaultUI" :options="options"></tui-image-editor>
  </sweet-modal>
</template>

<script>
import { ImageEditor } from '@toast-ui/vue-image-editor'
import api from '@/api'
import config from '@/config'
export default {
  components: {
    'tui-image-editor': ImageEditor
  },
  name: 'PhotoEditor',
  props: {
    images: Array
  },
  data() {
    return {
      savedMsg: false,
      useDefaultUI: true,
      options: { // for tui-image-editor component's "options" prop
        cssMaxWidth: 700,
        cssMaxHeight: 600,
        includeUI: {
          menuBarPosition: 'left',
          menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'text', 'mask', 'filter']
        }
      },
      current: 0,
    }
  },
  methods: {
    getUrl() {
      return `${config.API_URL}api/get-user-content-data/${this.images[this.current].userContentId}`
    },
    open (index) {
      this.$refs.modal.open()
      this.current = index
      this.$refs.tuiImageEditor.invoke('loadImageFromURL', `${this.getUrl()}?${Date.now()}`, 'Image').then((sizeValue) => {
      this.$refs.tuiImageEditor.editorInstance.ui.activeMenuEvent()
      this.$refs.tuiImageEditor.editorInstance.ui.resizeEditor({imageSize: sizeValue})
    })
    },
    save () {
      this.images[this.current].base64UserContentData = this.$refs.tuiImageEditor.invoke('toDataURL', { format: this.images[this.current].mimeType.replace('image/',''), quality: 0.9 /*0.8*/ })
      api.userContent.updateUserContent(this.images[this.current].userContentId, this.images[this.current]).then(() => {
        // this.$refs.modal.close()
        this.savedMsg = true
        setTimeout(() => {
          this.savedMsg = false
        }, 4000)
        this.$store.dispatch('getUserContentList')
      })
    },
    next () {
      this.savedMsg = false
      if(this.current + 1 >= this.images.length){
        this.current = 0
      } else {
        this.current++
      }
      this.$refs.tuiImageEditor.invoke('loadImageFromURL', `${this.getUrl()}?${Date.now()}`, 'Image').then((sizeValue) => {
        this.$refs.tuiImageEditor.editorInstance.ui.activeMenuEvent()
        this.$refs.tuiImageEditor.editorInstance.ui.resizeEditor({imageSize: sizeValue})
      })
    },
    prev () {
      this.savedMsg = false
      if(this.current - 1 < 0){
        this.current = this.images.length -1
      } else {
        this.current--
      }
      this.$refs.tuiImageEditor.invoke('loadImageFromURL', `${this.getUrl()}?${Date.now()}`, 'Image').then((sizeValue) => {
        this.$refs.tuiImageEditor.editorInstance.ui.activeMenuEvent()
        this.$refs.tuiImageEditor.editorInstance.ui.resizeEditor({imageSize: sizeValue})
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

>>>.tui-image-editor-header {
  display: none;
}
>>>.tui-image-editor-container{
  min-height: 610px;
}

.sweet-modal-overlay {
  height: calc(100vh - 56px);
  margin-top: 56px;
}
>>>.sweet-modal{
  height: 100%;
}
>>>.sweet-modal .sweet-content-content{
  height: 700px;
}
.prev-button, .next-button {
  position: absolute;
  top: 50%;
  color: #AAA;
  z-index: 100;
  cursor: pointer;
}
.prev-button{
  left: 0px;
}
.next-button{
  right: 0px;
}
.save-button{
  position: absolute;
  z-index: 10;
  right: 38px;
  margin-top: 10px;
}
.saved-msg {
  position: absolute;
  z-index: 10;
  right: 38px;
  top: 140px;
  margin-top: 10px;
  color: #ffffff
}
</style>
