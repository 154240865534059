<template>
  <form ref="form" class="form">
    <div class="form-label-group" v-if="paymentPlan.typeOfPlan != 4">
        <input disabled="true" type="text" class="form-control" id="type" placeholder="Type of Plan" :value="typeOfPlan">
      <label for="Subscription">{{'Subscription' | translate}}</label>
    </div>
    <div class="form-label-group" v-if="paymentPlan.typeOfPlan != 4">
        <input disabled="true" type="text" class="form-control" id="price" placeholder="Enter Conpany Name" :value="paymentPlan.price" >
        <label for="Price">{{'Price (€) *' | translate}}</label>
    </div>
    <div class="form-label-group">
      <input disabled type="text" class="form-control" placeholder="Enter Payment Plan Name" v-model.trim="paymentPlan.name" >
      <label for="paymentPlanName">{{'Payment Plan Name *' | translate}}</label>
    </div>
    <div class="form-label-group">
      <input disabled="true" type="text" class="form-control" id="type" placeholder="Type of Plan" :value="typeOfPlan">
      <label for="planType">{{'Plan Type' | translate}}</label>
    </div>
    <div class="form-label-group" v-if="paymentPlan.typeOfPlan != 4">
        <input disabled="true" type="text" class="form-control" id="location" placeholder="Number of Funerals" :value="paymentPlan.numberOfEvents">
      <label for="numOfEvents">{{'Number of Funerals *' | translate}}</label>
    </div>
  <div class="form-label-group">
       <div class="sub-form-group" style="padding-right:5px" v-if="paymentPlan.typeOfPlan != 4">
        <input disabled="true" type="text" class="form-control" id="trial" placeholder="Trial Period" :value="trialPeriod">
        <label for="FreeTrial">{{'Free Trial' | translate}}</label>
      </div>
      <div class="sub-form-group" style="padding-right:5px" v-if="paymentPlan.typeOfPlan != 4">
           <input disabled="true" type="text" class="form-control" id="trial" placeholder="Trial Period" :value="trialPeriod">
          <label for="Trial Period">{{'Trial Period *' | translate}}</label>
      </div>
      <div class="sub-form-group">
           <input disabled="true" type="text" class="form-control" id="music" placeholder="Enter Conpany Name" :value="externalMusic">
          <label for="Music Library">{{'Music Library' | translate}}</label>
      </div>
    </div>
    <div class="buttons-row">
      <button type="button" @click="closePlanInfoModal" class="command btn btn-default">Close</button>
    </div>
  </form>
</template>
<script>


export default {
    
      computed:{
        paymentPlan(){
            return this.$store.getters.getSinglePaymentPlan
        },
        typeOfPlan(){
           switch(this.paymentPlan.typeOfPlan){
               case 1: 
               return "Monthly"
               case 2: 
               return "Yearly"
               case 3:
               return "One Time"
               case 4:
               return "Free"
               default:
               return "Undefined"
           }
        },
        externalMusic(){
            return this.paymentPlan.withAddOn? "Yes": "No"
        },
        trialPeriod(){
            if(this.paymentPlan.trialPeriodProvided){
                return this.paymentPlan.numberOfTialPeriodDays+" Days";
            }
            return "None"
        },
    }, 
    methods: {
      closePlanInfoModal() {
        this.$emit('closePlanInfoModal');
      }
    }
}
</script>
<style scoped>
.form-label-group {
  margin-top: 20px;
  /* text-align: left; */
}
.sub-form-group {
  position: relative;
  /* text-align: left; */
  /* padding-left: 10px; */
  /* margin-top: 20px; */
  width: 33.33%; 
}
.sub-form-group label {
  position: absolute;
  top: 0;
  left: 11px;
}
.sub-form-group select {
  height: 46px;
}
.sub-form-group input {
  height: 46px;
}
.date-picker {
  width: 50%;
}
.picker-label {
  font-size: 12px;
  color: #777;
}
</style>

