<template>
  <form ref="form" class="form">
    <div v-if="user.userTypeId === 5" class="form-label-group">
      <select :class="{'is-invalid': $v.item.userTypeId.$error}" v-model="item.userTypeId" id="userType" class="form-control" :placeholder="'User Type' | translate" required>
        <option value="4">Company</option>
        <option value="3">Manager</option>
      </select>
      <label for="userType">{{'User Type' | translate}}</label>
    </div>
    <div v-if="user.userTypeId === 5 && item.userTypeId === '3'" class="form-label-group">
      <select v-model="item.parentUserId" id="parentUserId" class="form-control" :placeholder="'Associated User' | translate">
        <option v-for="d in distributors" :key="d.userId" :value="d.userId">{{d.fullName}}</option>
      </select>
      <label for="parentUserId">{{'Associated User' | translate}}</label>
    </div>
    <div class="form-label-group">
      <input :class="{'is-invalid': $v.item.fullName.$error}" v-model="item.fullName" type="text" id="fullName" class="form-control" :placeholder="'Name *' | translate" required>
      <label for="fullName">{{'Name *' | translate}}</label>
    </div>
  <div v-if="user.userTypeId === 5" class="form-label-group" >
      <select v-model="item.customerId" :class="{'is-invalid': $v.item.customerId.$error}" class="form-control" id="customerId" :placeholder="'Company' | translate" required>
            <option v-for="d in customersList" :key="d.customerId" :value="d.customerId">{{d.customerName}}</option>
      </select>
      <label for="companyName">{{'Company *' | translate}}</label>
    </div>
    <div v-if="user.userTypeId === 4" class="form-label-group">
      <input v-model="customerName" type="text" id="customerId" class="form-control" :placeholder="'Company *' | translate" disabled>
      <label for="companyName">{{'Company *' | translate}}</label>
    </div>
    <div class="form-label-group">
      <input :class="{'is-invalid': $v.item.email.$error}" v-model="item.email" type="text" id="infoEmail" class="form-control" :placeholder="'Email *' | translate">
      <label for="infoEmail">{{'Email *' | translate}}</label>
    </div>
      <div v-if="error" class="alert alert-danger" role="alert">
      {{errorMessage}}
    </div>
    <div class="buttons-row">
      <button type="button" @click="modalRef.close()" class="command btn btn-default">Cancel</button>
      <button type="button" @click="saveItem()" :disabled='isLoading' class="command btn btn-primary"><i class="fa fa-save"></i> Save</button>
    </div>
  </form>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'
import api from '@/api'
import util from '@/util'
export default {
  //props: ['item', 'modalRef', 'isUserAdmin'],
props: {
    item: {
        type: Object
    },
    modalRef: {
      type: Object
    },
  },
  computed: {
    user () {
      return util.getSessionInfo()
    },
    customersList() {
      return this.$store.getters.getCustomersList
    },
  },
  data () {
    return {
      distributors: [],
      customerId : null,
      customers: [],
      error: false,
      errorMessage: '',
      customerName: '',
      filter : {
        page: 1,
        pageSize: 1000
      },
      isLoading: false
    }
  },
  validations: {
    item: {
      userTypeId: {
        required
      },
      customerId: {
        required
      },
      fullName: {
        required
      },
      email: {
        email,
        required
      }
    }
  },
  watch: {
    item: {
      deep: true,
      handler: function() {
        if(this.item.Customer == undefined) {
            this.customerName = this.item.customerName;
        }else {
            this.customerName = this.item.Customer.customerName ? this.item.Customer.customerName : '';
            if(this.user.userTypeId !== 5) {
              this.item.customerId = this.item.Customer.customerId
            }
         
          }
      }
    }
  },
  mounted () {
    api.users.getDistributorList().then(result => {
      this.distributors = result.data
    })
  },
  methods: {
    loadList () {
      this.$store.dispatch('getUserList').then(() => {
        this.modalRef.close()
      })
    },
    saveItem () {
      this.isLoading = true
      if(this.user.userTypeId === 4) {
        this.item.parentUserId = this.user.userId
        this.item.userTypeId = 3
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.isLoading = false
        return
      }
      this.item.userTypeId = this.item.userTypeId || 3 // default to Manager
      if (this.item.userId) {
        api.users.updateUser(this.item).then(() => {
          this.loadList()
        })
      } else {
        if(this.user.userTypeId === 4) {
            this.item.customerId = this.item.Customer.customerId
        }
        api.users.addUser(this.item).then((result) => {
          if(result.data) {
          this.errorMessage = result.data
          this.error = true
        } else {
          this.errorMessage = ''
          this.error = false
          this.loadList()          
        }})
      }
      this.isLoading = false
    }
  }
}
</script>
<style scoped>
.form-label-group {
  margin-top: 20px;
}
.date-picker {
  width: 100%;
}
</style>
