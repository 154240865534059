import Vue from 'vue'
import config from '@/config'
import util from '@/util'
const stripe = window.Stripe(config.stripeApiKey)
export default {
  secondsToString (input) {
    let t = Vue.i18n.translate
    let numberEnding = (number) => {
      return (number > 1) ? t('s') : ''
    }
    let years = Math.floor(input / 31536000)
    if (years) {
      return years + t(' year') + numberEnding(years)
    }
    let days = Math.floor((input %= 31536000) / 86400)
    if (days) {
      return days + t(' day') + numberEnding(days)
    }
    let hours = Math.floor((input %= 86400) / 3600)
    if (hours) {
      return hours + t(' hour') + numberEnding(hours)
    }
    var minutes = Math.floor((input %= 3600) / 60)
    if (minutes) {
      return minutes + t(' minute') + numberEnding(minutes)
    }
    var seconds = input % 60
    if (seconds && seconds > 180) {
      return seconds + t(' second') + numberEnding(seconds)
    }
    return t('online')
  },
  isMobile () {
    return navigator.userAgent.toLowerCase().indexOf('mobile') !== -1
  },
  getSessionData () {
    // eslint-disable-next-line
    return JSON.parse(sessionStorage.sessionData || '{}')
  },
  setSessionData (data) {
    // eslint-disable-next-line
    sessionStorage.sessionData = JSON.stringify(data)
  },
  getSessionInfo () {
    // eslint-disable-next-line
    return JSON.parse(sessionStorage.sessionInfo || '{}')
  },
  setSessionInfo (data) {
    // eslint-disable-next-line
    sessionStorage.sessionInfo = JSON.stringify(data)
  },
  setUserContext (data) {
    // eslint-disable-next-line
    sessionStorage.userContext = data
  },
  getUserContext () {
    // eslint-disable-next-line
    return sessionStorage.userContext || 0
  },
  getUserContextUrlParam () {
    // eslint-disable-next-line
    let context = sessionStorage.userContext || 0
    return context ? ('/' + context) : ''
  },
  boolsToYesNo (obj) {
    for (var key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        this.boolsToYesNo(obj[key])
      } else {
        if (typeof obj[key] === 'boolean') {
          obj[key] = obj[key] ? 'Yes' : 'No'
        }
      }
    }
  },
  getColors () {
    return [
      ['#000000', '#1A1A1A', '#333333', '#4D4D4D', '#666666', '#BFBFBF', '#D9D9D9', '#E6E6E6', '#F2F2F2', '#FFFFFF'],
      ['#E3D1CE', '#E7D9CB', '#EAE4C7', '#DEEAC8', '#CBE6D1', '#D0E0E2', '#CED6E4', '#D1CDE4', '#DFCBE6', '#E6CBDB'],
      ['#F9C0B9', '#F9DAB8', '#FAEFB8', '#E3FAB8', '#C1F1CB', '#C2EAEF', '#BDD1F5', '#C9C2F0', '#E5BCF6', '#F6BCDF'],
      ['#DA948B', '#D7B48E', '#E0D185', '#BFDB8A', '#96CFA2', '#9AC5CB', '#96ABCF', '#A299CC', '#BF95D0', '#D293B9'],
      ['#9D6962', '#9B8064', '#B2A462', '#8CA857', '#619E6E', '#65949A', '#65789A', '#706996', '#896A95', '#9D6285'],
      ['#7D3E36', '#785A3A', '#86772D', '#698F24', '#318242', '#337780', '#324F80', '#443A79', '#663C76', '#7A3860'],
      ['#3C2C2A', '#3C332A', '#413C25', '#394521', '#224429', '#263D40', '#27303F', '#2D2B3B', '#37293D', '#3C2A35'],
      ['#2D0B06', '#2C1A07', '#2F2804', '#203003', '#082B10', '#0A2629', '#0A1529', '#110E25', '#1F0C27', '#260D1C']
    ]
  },
  getFonts () {
    return [
      'Ahellya',
      'The Bugatten',
      'Avenir Next Ultra Light',
      'Avenir Book',
      'Chapaza',
      'Gotham',
      'Kiona',
      'Lane - Narrow',
      'Modern Sans',
      'Saudagar'
    ]
  },
  getMinutes () {
    let result = []
    for (let i = 0; i < 60; i = i + 5) {
      result.push(i)
    }
    return result
  },
  getHours () {
    let result = []
    for (let i = 0; i < 25; i++) {
      result.push(i)
    }
    return result
  },
  getVideoExtensions () {
    return ['.mkv', '	.flv', '.vob', '.ogv', '.ogg', '.drc', '.mng', '.avi',
    '.mts', '.mov', '.wmv', '.yuv', '.rm', '.asf', '.amv', '.mp4', '.m4p', '.m4v', '.mpg', '.mp2',
    '.mpe', '.mpv', '.m2v', '.svi', '.3gp', '.3g2', '.mxf', '.roq', '.nsv', '.f4v', '.f4p', '.f4a', '.f4b']
  },
  getImageExtensions () {
    return []
  },
  getAudioExtensions () {

  }, 
	getLanguages() {
		return [
			{key: 'en', value: 'English'}, 
			{key: 'de', value: 'Deutsch'},
			{key: 'fr', value: 'Français'},
			{key: 'nl', value: 'Dutch' }
		];
	},
  getRedirection () {
    if(sessionStorage.getItem('subscribe') === 'errorPage'){
        return true
    }else{
         return false
    }
  },
  getRedirectionToEvents() {
    if(sessionStorage.getItem('events') === 'true'){
      return true
    }else{
       return false
    }
  },
  getSessionId() {
    if(sessionStorage.getItem('subscribe') === 'errorPage'){
      return true
    }else{
       return false
    }
  },
  subscribe(){
    let formData={
      urlSuccess:`${window.location.protocol}//${window.location.host}/#/successPage?sessionId={CHECKOUT_SESSION_ID}`,
      urlCancel: `${window.location.protocol}//${window.location.host}/#/errorPage`
    }
    fetch(`${config.API_URL}api/payment/start-subscription-creation`,{
      method:'POST',
      mode:'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${util.getSessionData().access_token}`,           
      },
      body:JSON.stringify(formData)
    }).then(response=>{
        return response.json()
    }).then(session=>{
        sessionStorage.setItem('sessionId', session.id);
        return stripe.redirectToCheckout({ sessionId: session.id });
    }).then(result=>{
        if(result.error){
          alert(result.error.message)
        }
      })
    },
}
