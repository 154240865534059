<template>
  <div>
    <MainNav></MainNav>
    <div class="wrapper">
      <button v-if="user.userTypeId === 5" @click="openDialog({})" type="button" class="add-command command btn btn-success"><i class="fa fa-plus"></i> Add Device</button>
      <table id="dtBasicExample" class="data-table table table-bordered" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th class="th-sm">Device Name</th>
            <th class="th-sm">Address</th>
            <th>Username</th>
            <th>Associated Company</th>
            <th v-if="user.userTypeId === 5"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, $index) in devices" >
          <tr :key="$index+1">
            <td>{{item.name}}</td>
            <td>{{item.address}}</td>
            <td>{{item.username}}</td>
            <td>{{item.fullName}}</td>
            <td v-if="user.userTypeId === 5" class="command-column">
              <button @click="openDialog(item)" type="button" class="command btn btn-primary btn-sm">Details</button>
              <uploader @complete="onConfigComplete()" :ref="'uploader' + item.deviceId" :disabled="isUploading" :options="item.options" class="uploader-example">
                <uploader-unsupport></uploader-unsupport>
                <uploader-btn  :class="{disabled: isUploading}" :disabled="isUploading" class="command btn btn-primary upload-button">
                  <!-- <button type="button" class="command btn btn-primary">Upload Config</button> -->
                  Upload Config
                </uploader-btn>
              </uploader>
              <button type="button" @click="deleteDevice(item)" class="command btn btn-sm btn-danger">Delete</button>
            </td>
          </tr>
          </template>
        </tbody>
      </table>
    </div>
    <sweet-modal :blocking="true" ref="deviceModal" title="Device Details">
      <Device :modal-ref="$refs.deviceModal" :device="selectedDevice"></Device>
    </sweet-modal>
    <sweet-modal ref="uploadInfo" icon="info">
      Config uploaded.
      <div>
        <button class="btn btn-light modal-button" @click="$refs.uploadInfo.close()" type="button">Ok</button>
      </div>
    </sweet-modal>
    <sweet-modal ref="confirmDelete" icon="warning">
      You are about to delete this device and all its details. Are you sure?
      <div>
        <button class="btn btn-light modal-button" @click="$refs.confirmDelete.close()" type="button">Cancel</button>
        <button class="btn btn-danger modal-button" @click="confirmDelete()" type="button">Delete</button>
      </div>
    </sweet-modal>
  </div>
</template>
<script>
import api from '@/api'
import MainNav from '@/components/common/MainNav'
import Device from '@/components/Device'
import config from '@/config'
import util from '@/util'
import { EventBus } from '@/event'
export default {
  // props: ['modalRef'],
  data () {
    return {
      // devices: [],
      selectedDevice: {            
        name: '',
        address: '',
        username: '',
        password: '',
        deviceId: -1
      },
      currentDeviceId: null
    }
  },
  components: {
    MainNav,
    Device
  },
  computed: {
    isUploading () {
      return this.uploading
    },
    user () {
      return util.getSessionInfo()
    },
    devices () {
      return this.$store.getters.deviceList.map(item => {
        item.options = {
          target: `${config.API_URL}api/upload-device-config/${item.deviceId}`,
          testChunks: false,
          chunkSize: 100000000,
          headers: {
            Authorization: `Bearer ${util.getSessionData().access_token}`
          }
        }
        // this.$refs['uploader' + item.deviceId].uploader.on('uploadStart', () => {
        //   // eslint-disable-next-line
        //   this.uploading = true
        // })
        // this.$refs['uploader' + item.deviceId].uploader.on('complete', () => {
        //   // eslint-disable-next-line
        //   this.uploading = false
        //   // eslint-disable-next-line
        //   this.$refs.uploadInfo.open()
        // })
        return item
      })
    }
  },
  methods: {
    getCurrentDeviceId () {
      return this.currentDeviceId
    },
    openDialog (device) {
      this.selectedDevice = device
      this.$refs.deviceModal.open()
    },
    deleteDevice(device){
      this.deviceId = device.deviceId
      this.$refs.confirmDelete.open()
    },
    confirmDelete () {
      this.$refs.confirmDelete.close()
      api.device.delete(this.deviceId).then((result) => {
        if(result.data) {
          //  this.errorMessage = result.data
          //  this.$refs.deleteError.open()
        } else {
          this.$store.dispatch('getDeviceList')
        }
      })
    },
    onConfigComplete () {
      EventBus.$emit('notification', {message: 'Config uploaded.'})
    }
  },
  created () {
    this.$store.dispatch('getDeviceList')
  }
}
</script>
<style scoped>
.command-column {
  width: 360px;
}
.command {
  margin-left: 10px;
  margin-right: 10px;
}
.data-table {
  margin-top: 30px;
}
.wrapper {
  width: 70%;
  margin: auto;
  min-width: 1024px;
}

.add-command {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.upload-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  cursor: pointer;
}
label.upload-button {
  margin-bottom: 0;
}
.upload-button:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.uploader-example {
  display: inline;
}
.btn-sm {
  width: 95px;
}
</style>