<template>
  <div class="tab-pane" id="cards">
    <div>
      <button type="button" @click="openPaymentMethodDialog()" class="add-command command btn btn-success"><i class="fa fa-plus"></i> Add Payment Card</button>
      <table class="data-table table table-bordered sortable" >
        <thead>
          <tr>
            <th>Card brand</th>
            <th>Last4</th>
            <th>Expires </th>
            <th>Name on card </th>		
            <th>Email </th>		
            <th>Postal code </th>				
            <th> Make default </th>
            <th> Delete card </th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(paymentMethod, $index) in paymentMethods" :key="$index">
              <td>{{paymentMethod.card.brand}}</td>
              <td>{{paymentMethod.card.last4}}</td>
              <td> {{paymentMethod.card.exp_month}}/{{paymentMethod.card.exp_year}} </td>
              <td v-if="paymentMethod.billing_details.name != null">{{paymentMethod.billing_details.name}} </td>
              <td v-else>
                Name on card is empty
              </td>
              <td v-if="paymentMethod.billing_details.email != null"> {{paymentMethod.billing_details.email}} </td>
              <td v-else>
                Email is empty
              </td>
              <td v-if="paymentMethod.billing_details.address.postal_code != null || paymentMethod.billing_details.country != null"> {{paymentMethod.billing_details.address.postal_code}} {{paymentMethod.billing_details.address.country}} </td>
              <td v-else>
                Address is empty
              </td>
              <td v-if="customerWithDefaultPaymentMethod.customerDefaultPaymentMethodId != null && customerWithDefaultPaymentMethod.customerDefaultPaymentMethodId == paymentMethod.id"> 
                This is a default card
              </td>
              <td v-if="customerWithDefaultPaymentMethod.customerDefaultPaymentMethodId == null || customerWithDefaultPaymentMethod.customerDefaultPaymentMethodId != paymentMethod.id "> 
                <button type="button" class="command btn btn-primary btn-sm" @click="makeDefault(paymentMethod.id)"> Make default </button>
              </td>
              <td v-if="customerWithDefaultPaymentMethod.customerDefaultPaymentMethodId == null || customerWithDefaultPaymentMethod.customerDefaultPaymentMethodId != paymentMethod.id "> 
                <button  class="command btn btn-primary btn-sm btn-danger" @click="deletePaymentMethod(paymentMethod.id)"> Delete </button>
              </td>
              <td v-else>
                You can't delete default card
              </td>
          </tr> 
        </tbody>
      </table>
    </div>
    <div class="option-window">
      <sweet-modal :blocking="true" ref="confirmDeleteModal" icon="warning">
        You are about to delete this payment card and all its details. Are you sure?
        <div>
          <button class="btn btn-light modal-button" @click="$refs.confirmDeleteModal.close()" type="button">Cancel</button>
          <button class="btn btn-danger modal-button" @click="confirmDelete()" type="button">Delete</button>
          </div>
      </sweet-modal>
      <sweet-modal :blocking="true" ref="confirmPaymentMethodModal" icon="warning">
        You are about to change default payment card. Are you sure?
        <div>
          <button class="btn btn-light modal-button" @click="$refs.confirmPaymentMethodModal.close()" type="button">Cancel</button>
          <button class="btn btn-danger modal-button" @click="confirmPaymentMethod()" type="button">Confirm</button>
        </div>
      </sweet-modal>
    </div>
    <div class="form-window-sm" style="text-align:left">
		<sweet-modal :blocking="true" ref="paymentMethodModal" title="Add new payment card">
			<AddCardForm @closeAddCardDialog = "closeAddCardDialog"/> 
    </sweet-modal>
    </div>
  </div>
</template>

<script>
import AddCardForm from '@/components/paymentMethods/AddCardForm'
import util from '@/util'
export default {
	data () {
    return {
      isSettings: true,	
      stripe : {},
      card: {},
      selectedPaymentMethod : {},
      paymentMethodId: 0,
      confirmPaymentMethodId: 0,
      confirmCardSetup : null,
      showPaymentPlan: false
    }
  },
  methods: {
		openPaymentMethodDialog() {
      this.$refs.paymentMethodModal.open()
    },
		closeAddCardDialog() {
			this.$refs.paymentMethodModal.close()
		},
    deletePaymentMethod(paymentMethodId) {
      this.paymentMethodId = paymentMethodId
      this.$refs.confirmDeleteModal.open()
    },
    confirmDelete () {
      this.$refs.confirmDeleteModal.close()
      this.$store.dispatch('deletePaymentMethod', this.paymentMethodId);
    },
    makeDefault(paymentMethodId) {
      this.confirmPaymentMethodId = paymentMethodId;
      this.$refs.confirmPaymentMethodModal.open()
    },
    confirmPaymentMethod() {
      this.$refs.confirmPaymentMethodModal.close()
      const postSendData = {
        stripeCustomerId: this.customerWithDefaultPaymentMethod.customer.id,
        stripePaymentMethodId: this.confirmPaymentMethodId,
      }
      this.$store.dispatch('makeDefault', postSendData);
    },
    isEmptyObj(object) {
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
    },
  },
  components: {
		AddCardForm
  },
  computed: {
    customerWithDefaultPaymentMethod () {
      return this.$store.getters.getCustomerWithDefaultPaymentMethod;
    },
    paymentMethods () {
      return this.$store.getters.getPaymentMethodsList
    },
    user() {
      return util.getSessionInfo()
    }
  },
  mounted() {
    if(!this.isEmptyObj(this.user)) {
      this.$store.dispatch('getCurrentUser', util.getSessionInfo())
      this.$store.dispatch('getCustomerWithDefaultPaymentMethod'),
      this.$store.dispatch('getPaymentMethodsList')
    }
  },
}
</script>
<style scoped>

 .sweet-modal{
  height: 56px;
}  
.card {
	color: "#32325d";
	-webkit-font-smoothing: "antialiased";
  font-family: 'Arial, sans-serif';
  font-size: "16px";
}

form {
  width: 30vw;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}
.command-column {
  width: 340px;
}
.command {
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
}
.data-table {
  margin-top: 30px;
}
.wrapper {
  width: 70%;
  margin: auto;
  min-width: 1024px;
}

.add-command {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.btn-sm {
  width: 95px;
}

.option-window >>>.sweet-modal{
  height: 50vh !important;
}
</style>