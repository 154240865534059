<template>
	<div>
  <form ref="form" class="form-signup" @submit.prevent="checkForm" validate>
    <img alt="EFuneral" src="../assets/logo.png">
    <div class="form-label-group">
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': $v.customerName.$error }"
        id="customerName"
				v-model="customerName"
        :placeholder="'Company *' | translate"
        required
        />
      <label for="customerName">{{ "Company *" | translate }}</label>
    </div>
    <div class="form-label-group">
      <input
        type="text"
        id="fullName"
        class="form-control"
        :class="{ 'is-invalid': $v.fullName.$error }"
        v-model="fullName"
        :placeholder="'Name *' | translate"
        required
      />
      <label for="fullName">{{ "Full Name *" | translate }}</label>
    </div>
    <div class="form-label-group">
      <input
        type="email" 
        v-model="email"
        class="form-control"
        :class="{ 'is-invalid': $v.email.$error }"
        id="infoEmail"
        :placeholder="'Email *' | translate"
        required
      />
      <label for="infoEmail">{{ "Email *" | translate }}</label>
    </div>
    <div class="form-label-group" v-if="this.showSelect">
      <select 
        class="form-control"
        id="paymentPlanId"
        :class="{ 'is-invalid': $v.paymentPlanId.$error }"
        :placeholder="'Payment plan *' | translate"
        required
        v-model="paymentPlanId">
        <option
          v-for="p in paymentPlans"
          :key="p.paymentPlanId"
          :value="p.paymentPlanId"
        >
          {{ p.name }}
        </option>
      </select>
      <label for="paymentPlan">{{ "Payment Plan *" }}</label>
    </div>
		<div class="form-label-group">
      <select
        v-model="language"
        id="languageId"
        class="form-control"
        :placeholder="'Language *' | translate"
        required
      >
        <option
          v-for="language in languages"
          :key="language.key"
          :value="language.key"
        >
          {{ language.value }}
        </option>
      </select>
      <label for="Language">{{ "Language" }}</label>
    </div>
    <div class="buttons-row">
      <button type="submit" class="command btn btn-primary">
        Register
      </button>
    </div>
     <div v-if="error" class="alert alert-danger" role="alert">
			{{this.errorMessage}}
      </div>
  </form>
		<sweet-modal ref="confirmRegistration" icon="success">
      An email has been sent. Please check your inbox.
      <div>
        <button class="btn btn-light modal-button"  @click="$refs.confirmRegistration.close()" type="button">Cancel</button>
        <button class="btn btn-success modal-button" @click="goToLogin()" type="button">Go to login</button>
    </div>
    </sweet-modal>
    <sweet-modal ref="wrongUrlPaymenPlan" icon="error" :blocking="true" :hide-close-button="true">
      Chosen payment plan is not available! <br>
    <div>
        <button class="btn btn-success modal-button" @click="goToRegistration()" type="button">Ok</button>
    </div>
    </sweet-modal>
		</div>
</template>
<script>
 import { required } from "vuelidate/lib/validators";
import api from "@/api";
import util from '@/util'
export default {
	data() {
    return {
      showSelect: false,
      paymentPlans: [],
      customerName : "",
      email : "",
      fullName : "",
      language: "",
      error: false,
      paymentPlanId : "",
			errorMessage : "",
      languages: util.getLanguages(),
      filter : {
      page: 1,
      pageSize: 1000,
      isHidden: false,
      isDeleted: false,
      paymentPlanIdURL: null,
      }
    };
  },
  created() {
     this.routeChanged();
  },
	mounted() {
    api.paymentPlan.getAllPlans({ params: this.filter }).then((result) => {
      this.paymentPlans = result.data.data;
    });
  },
   validations: {
    fullName: {
      required
    },
    customerName: {
      required
    },
    email: {
      required
    },
    paymentPlanId: {
      required
    }, 
		language: {
			required
		}
  },
  watch: {
  '$route' () {
    this.routeChanged();
  }
},
	methods: {
    routeChanged() {
      this.paymentPlanIdURL = this.$route.params.paymentPlanId;
      if(this.paymentPlanIdURL == null || this.paymentPlanIdURL == undefined) {
          this.showSelect = true;
          this.paymentPlanId = null;    
      }else {
          this.getPaymentPlanById()
      }
    },

    getPaymentPlanById() {
      api.paymentPlan.getSinglePlan(this.paymentPlanIdURL).then(response => {
        if(!response.body.isDeleted && !response.body.isHidden){
          this.paymentPlanId =  response.data.paymentPlanId
          this.showSelect = false

        }else{
          this.paymentPlanId = null
          this.showSelect = true
          this.$refs.wrongUrlPaymenPlan.open()
        }
      }).catch(() => {
          this.paymentPlanId = null
          this.showSelect = true
          this.$refs.wrongUrlPaymenPlan.open()
      })
    },

    checkForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }       
      const item = {
        customerName : this.customerName,
        email : this.email,
        fullName : this.fullName,
        language : this.language,
        paymentPlanId : this.paymentPlanId
      }
      api.registration.registerCustomerAndUser(item).then(() => {
        this.$refs.confirmRegistration.open()
      }).catch( error => {
				this.error = true
				this.errorMessage = error.data.Message;
			})
    },
    goToLogin() {
      this.$router.push("/login");
    },
    goToRegistration(){
       this.$refs.wrongUrlPaymenPlan.close()
      this.$router.push("/registration");
      
    }
  },
	
}
</script>
<style scoped>


.form-signup {
  width: 200%;
  max-width: 360px;
  padding: 55px;
  margin: auto;
}
.form-signup {
  text-align: center;
}

.form-signup .form-control:focus {
  z-index: 2;
}
.form-label-group {
  text-align: left;
}
.command-column {
  width: 360px;
}
.command {
  margin-left: 10px;
  margin-right: 10px;
}

.wrapper {
  width: 70%;
  margin: auto;
  min-width: 1024px;
}

</style>