import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueResource from 'vue-resource'
import util from './util'
import { store } from './store/store'
import SweetModal from 'sweet-modal-vue/src/plugin.js'
import config from './config'
import uploader from 'vue-simple-uploader'
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Vuelidate from 'vuelidate'
import VueCroppie from 'vue-croppie'
import 'croppie/croppie.css' // import the croppie css manually
import moment from 'moment'
import VCalendar from 'v-calendar'

Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})
Vue.filter('formatDateTime', (value) => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY hh:mm')
  }
})
Vue.filter('formatSsToHhmmss', (value) => {
  if (value){
    var hours   = Math.floor(value / 3600);
    var minutes = Math.floor((value - (hours * 3600)) / 60);
    var seconds = value - (hours * 3600) - (minutes * 60);

    // round seconds
    seconds = Math.round(seconds * 100) / 100

    var //result =(hours < 10 ? "0" + hours : hours);
        result = /*+= ":" +*/ (minutes < 10 ? "0" + minutes : minutes);
        result += ":" + (seconds  < 10 ? "0" + seconds : seconds);
    return result;
  }
})
Vue.use(VCalendar)
Vue.use(VueCroppie)
Vue.use(Vuelidate)
Vue.use(VueFormWizard)
Vue.use(uploader)
Vue.use(SweetModal)
Vue.use(VueResource)

Vue.config.productionTip = false

Vue.http.interceptors.push((request, next) => {
  request.headers.set('Authorization', `Bearer ${util.getSessionData().access_token}`)
  // eslint-disable-next-line
  // console.log(config)
  request.url = config.API_URL + request.url
  next((response) => {
    if (window.location.hash !== '#/registration/:paymentPlanId?' && response.status === 401) {                      
      if (document.location.href.indexOf('presentation') !== -1) {
        localStorage.returnUrl = document.location.href
        document.location.href = '/#/login'
      }
      if (document.location.href.indexOf('view') === -1 && document.location.href.indexOf('share') === -1 && document.location.href.indexOf('invitation') === -1 && document.location.href.indexOf('reset-password') === -1) {
        document.location.href = '/#/login'
      }
    }
  })
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
