<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-label-group">
                        <select id="userId" v-model="filter.userId" class="form-control" placeholder="User">
                            <option key="null" value="null">--- all ---</option> 
                            <option v-for="user in users" :key="user.userId" :value="user.userId">{{user.fullName}}</option>
                        </select>
                        <label for="userId">{{'User' | translate}}</label>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-label-group">
                        <select id="company" v-model="filter.customerId" class="form-control" placeholder="Company">
                            <option key="null" value="null">--- all ---</option> 
													<option v-for="customer in customers" :key="customer.customerId" :value="customer.customerId">{{customer.customerName}}</option>
                        </select>
                        <label for="company">{{'Company' | translate}}</label>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-label-group">
                        <v-date-picker v-model="filter.dateFrom"
                                       :popover="{ placement: 'top', visibility: 'click' }"
                                       class="date-picker"
                                       :placeholder="'Date From' | translate" />
                        <label for="DateFrom">{{'Date From' | translate}}</label>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-label-group">
                        <v-date-picker v-model="filter.dateTo"
                                       :popover="{ placement: 'top', visibility: 'click' }"
                                       class="date-picker"
                                       :placeholder="'Date To' | translate" />
                        <label for="DateTo">{{'Date To' | translate}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-9"></div> 
                <div class="col-sm-3 buttons">
                    <button @click="clearFilter()" type="button" class="command btn btn-default">Clear</button>
                    <button @click="getReportData()" type="button" class="command btn btn-primary">Load</button>
                </div>
            </div>
        </div>
        <table id="dtBasicExample" class="data-table table table-bordered sortable" cellspacing="0" width="100%">
            <thead>
                <tr>
                    <th @click="sort('fullName')" class="th-sm">User
                        <i v-if="sortField === 'fullName'" class="fa" :class="'fa-sort-' + order"></i>
                    </th>
                    <th @click="sort('customerId')" class="th-sm">Company
                        <i v-if="sortField === 'customerId'" class="fa" :class="'fa-sort-' + order"></i>
                    </th>
                    <th @click="sort('sessionStartTimestamp')" class="th-sm">Session Start
                        <i v-if="sortField === 'sessionStartTimestamp'" class="fa" :class="'fa-sort-' + order"></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(item, $index) in list">
                    <tr :key="$index+1">
                        <td>{{item.fullName}}</td>
                        <td>{{item.customerName}}</td>
                        <td>{{item.sessionStartTimestamp | formatDateTime}}</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>
<script>
    import api from '@/api'
    export default {
        // props: ['modalRef'],
        data() {
            return {
                filter: this.getDefaultFilter(),
                users: [],
                //companies: [],
				customers: [],
                list: [],
                order: '',
                sortField: ''
            }
        },
        components: {},
        computed: {
            rptUsrSessionSort () {
                return localStorage.rptUsrSessionSort
            },
            rptGUsrSessionOrder () {
                return localStorage.rptGUsrSessionOrder
            }
        },
        methods: {
            sort (field) {
                localStorage.rptUsrSessionSort = field
                localStorage.rptGUsrSessionOrder = localStorage.rptGUsrSessionOrder === 'desc' ? 'asc' : 'desc'
                this.order = localStorage.rptGUsrSessionOrder
                this.sortField = localStorage.rptUsrSessionSort
                this.getReportData()
            },
            clearFilter() {
                this.filter = this.getDefaultFilter()
            },
            getDefaultFilter() {
                return {
                    userId: null,
                    customerId: null,
                    dateFrom: null,
                    dateTo: null,
                    order: 'asc',
                    sort: 'sessionStartTimestamp',
                    page: 1,
                    pageSize: 10000
                }
            },
            getReportData() {
                this.filter.order = localStorage.rptGUsrSessionOrder || 'desc'
                this.filter.sort = localStorage.rptUsrSessionSort || 'sessionStartTimestamp'
                if (this.filter.userId === "null"){
                    this.filter.userId = null
                }
                if (this.filter.customerId === "null"){
                    this.filter.customerId = null
                }
                api.reports.getUserSessionDetailedList(this.filter).then(result => {
                    let data = result.data.data.map(item => {
                    return item
                    })
                    this.list = data
                })
            }
        },
        mounted() {
            api.users.getUserList().then(result => {
                this.users = result.data;
                
            })
			let filter ={
				page:1,
				pageSize: 1000
			}
			api.customers.getCustomerList({params: filter}).then(result =>{
				this.customers = result.data.data
			})
        }
    }
</script>
<style scoped>
.data-table {
    margin-top: 30px;
}
.wrapper {
    width: 70%;
    margin: auto;
    min-width: 1024px;
}
.buttons {
    text-align: right;
}
.buttons button {
    margin-top: 20px;
    margin-left: 20px;
}
.container {
    margin-top: 16px;
}
.sortable th {
cursor: pointer;
}
</style>