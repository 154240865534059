/**/
const isProduction = process.env.NODE_ENV === 'production'

let baseURL = ''
if (window) {
  baseURL = window.location.protocol + '//app.e-funeralservice.com/api/'
}

const API_URL = isProduction ? baseURL : 'http://localhost:55522/'

const S3_URL = isProduction ? 'https://e-funeral.s3.eu-central-1.amazonaws.com/p/' : 'https://e-funeral.s3.eu-central-1.amazonaws.com/d/'

const stripeApiKey = "pk_live_51KmIz7ANMf0XLAjOWOckdlbJokDXdlRpt0ezkhv21aYgfz2rGE2NRLYxoP28ef0SZt6KB7IMjGl1tCbeRkR8En6O00TwQUqs20";

export default {
  API_URL,
  S3_URL,
  stripeApiKey
}


/* DEV 
const isProduction = process.env.NODE_ENV === 'production'

let baseURL = ''
if (window) {
  baseURL = window.location.protocol + '//stgapp.e-funeralservice.com/api/'
}

const API_URL = isProduction ? baseURL : 'http://localhost:55522/'

const S3_URL = 'https://e-funeral.s3.eu-central-1.amazonaws.com/d/'

const stripeApiKey = "pk_test_51IdZyIEMQBSiy98aWStpusqkNbgQVaKQeDOmDymaXm2uGchhFRJstqxhGxcyf9CfWRJICG67gZwSg1Zw28s47HOf00zkKqlcUV";

export default {
  API_URL,
  S3_URL,
  stripeApiKey
}
*/